import Layout from 'components/Layout/Layout';
import { KYCScreen } from './KYCScreen';

export const KYCForm = (): JSX.Element => {
  return (
    <Layout>
      <KYCScreen />
    </Layout>
  );
};
