import { BigNumber, ethers } from 'ethers';

/**
 * Calculates the average price of the user's index token for a specific bundle
 *
 * @param {number} totalInvestment - Total EurE invested in the bundle
 * @param {number} totalIndexTokenBalance - Index token balance of the bundle
 * @returns {BigNumber} - The average price of the user's index token for the bundle
 */

export const calculateBundleAvgIndexTokenPrice = (
  totalInvestment: number,
  totalIndexTokenBalance: number,
): BigNumber => {
  if (totalInvestment === 0 || totalIndexTokenBalance === 0) {
    return ethers.constants.Zero;
  }
  const parseTotalInvestment = ethers.utils.parseUnits(totalInvestment.toString(), 18);
  const parseTotalIndexTokenBalance = ethers.utils.parseUnits(
    totalIndexTokenBalance.toString(),
    18,
  );
  const precisionTotalInvestment = parseTotalInvestment.mul(BigNumber.from(10).pow(18));
  return precisionTotalInvestment.div(parseTotalIndexTokenBalance);
};
