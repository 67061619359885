import { Navigate, useLocation } from 'react-router-dom';
import { useWeb3Auth } from 'services/web3auth';
import Layout from './Layout/Layout';
import { Loading } from './Layout/Loading';
import useBodyClass, { removeBodyClass } from 'hooks/useBodyClass';

import './RequireAuth.scss';

function RequireAuth({ children }: { children: JSX.Element }): JSX.Element {
  const { user, isLoading } = useWeb3Auth();
  const location = useLocation();

  if (isLoading) {
    useBodyClass(['auth-loading']);
    return (
      <Layout>
        <Loading />
      </Layout>
    );
  } else {
    if (user === undefined || user === null) {
      // Redirect the user to the home page.
      return <Navigate to="/" state={{ from: location }} replace />;
    }
    removeBodyClass('auth-loading');
    return children;
  }
}

export default RequireAuth;
