import React, { useState } from 'react';

export const HelpCenterIFrame = (): JSX.Element => {
  const [display, setDisplay] = useState(false);

  return (
    <iframe
      // src={`${process.env.REACT_APP_website_domain ?? 'localhost:8000'}/help/`}
      // src={`http://localhost:8000/help/`}
      // src={`https://fenus-wbsite.netlify.app/help/`}
      src={'https://test.fenus.io/help/'}
      width="100%"
      height="100%"
      id={display ? 'helpcenter-iframe-open' : 'helpcenter-iframe'}
      style={{
        position: 'fixed',
        zIndex: '999',
        background: 'white',
        height: '200vh',
        display: display ? 'block' : 'none',
        transition: 'all 1s',
      }}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        setDisplay((prev: boolean) => !prev);
      }}
    ></iframe>
  );
};
