/* eslint-disable @typescript-eslint/restrict-template-expressions */
import useBodyClass from 'hooks/useBodyClass';

import './Portfolio.scss';
import GuidedTourHeader from 'components/GuidedTourHeader';

export const GuidedTourSupport = (): JSX.Element => {
  useBodyClass(['dashboard-body']);
  return (
    <div>
      <GuidedTourHeader />
      <iframe
        id="help-iframe"
        src={`${process.env.REACT_APP_website_domain}/help`}
        width="100%"
        style={{ height: '100vh', marginTop: 120 }}
      />
    </div>
  );
};
