/* eslint-disable */

import { ChainId, Token } from '@uniswap/sdk-core';
import i18n from 'i18next';

interface ICoin {
  icon: string;
  type: string;
  name: string;
  symbol: string;
  description: string;
  performance: {
    value: number;
    priceChange: number;
    yearlyReturn: number;
    provider: string;
  };
  marketData: {
    cmc_rank: number;
    market_cap: number;
    market_cap_dominance: number;
    price: number;
  };
  address: string;
  decimals: number;
  uniswap: any;
}

export interface ICoinsArray {
  [coin: string]: ICoin;
}

function getCoins(): ICoinsArray {
  let coins: any = {
    BTC: {
      icon: 'btc.svg',
      type: 'Volatile',
      name: 'Bitcoin',
      symbol: 'BTC',
      description: `${i18n.t('dashboard:btc.content1')}  \n  \n ${i18n.t(
        'dashboard:btc.content2',
      )} \n  \n ${i18n.t('dashboard:btc.content3')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        rank: 0,
        marketCap: 0,
        dominance: 0,
        price: 0,
      },
      address: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
      decimals: 8,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc04b0d3107736c32e19f1c62b2af67be61d63a05',
        8,
        'WBTC',
        'WBTC',
      ),
    },
    ETH: {
      icon: 'eth.svg',
      type: 'Volatile',
      name: 'Ethereum',
      symbol: 'ETH',
      description: `${i18n.t('dashboard:eth.content1')}  \n  \n ${i18n.t(
        'dashboard:eth.content2',
      )} \n  \n ${i18n.t('dashboard:eth.content3')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        rank: 0,
        marketCap: 0,
        dominance: 0,
        price: 0,
      },
      address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
        18,
        'WETH',
        'Wrapped Ether',
      ),
    },
    LINK: {
      icon: 'link.png',
      type: 'Volatile',
      name: 'Chainlink',
      symbol: 'LINK',
      description: `${i18n.t('dashboard:link.content1')}  \n  \n ${i18n.t(
        'dashboard:link.content2',
      )} \n  \n ${i18n.t('dashboard:link.content3')} \n  \n ${i18n.t('dashboard:link.content4')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        rank: 0,
        marketCap: 0,
        dominance: 0,
        price: 0,
      },
      address: '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    LDO: {
      icon: 'ldo.png',
      type: 'Volatile',
      name: 'Lido DAO Token',
      symbol: 'LDO',
      description: `${i18n.t('dashboard:link.content1')}  \n  \n ${i18n.t(
        'dashboard:link.content2',
      )} \n  \n ${i18n.t('dashboard:link.content3')} \n  \n ${i18n.t('dashboard:link.content4')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        rank: 0,
        marketCap: 0,
        dominance: 0,
        price: 0,
      },
      address: '0xC3C7d422809852031b44ab29EEC9F1EfF2A58756',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    RNDR: {
      icon: 'rndr.png',
      type: 'Volatile',
      name: 'Render Token',
      symbol: 'RNDR',
      description: `${i18n.t('dashboard:rndr.content1')}  \n  \n ${i18n.t(
        'dashboard:rndr.content2',
      )} \n  \n ${i18n.t('dashboard:rndr.content3')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        rank: 59,
        marketCap: 707113848,
        dominance: 0.06,
        price: 1.91,
      },
      address: '0x61299774020dA444Af134c82fa83E3810b309991',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    AAVE: {
      icon: 'aave.png',
      type: 'Volatile',
      name: 'Aave',
      symbol: 'AAVE',
      description: `${i18n.t('dashboard:aave.content1')}  \n  \n ${i18n.t(
        'dashboard:aave.content2',
      )} \n  \n ${i18n.t('dashboard:aave.content3')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        rank: 58,
        marketCap: 713041263,
        dominance: 0.06,
        price: 8.91,
      },
      address: '0xD6DF932A45C0f255f85145f286eA0b292B21C90B',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    GRT: {
      icon: 'grt.png',
      type: 'Volatile',
      name: 'The Graph',
      symbol: 'GRT',
      description: `${i18n.t('dashboard:grt.content1')}  \n  \n ${i18n.t(
        'dashboard:grt.content2',
      )} \n  \n ${i18n.t('dashboard:grt.content3')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        rank: 58,
        marketCap: 713041263,
        dominance: 0.06,
        price: 8.91,
      },
      address: '0x5fe2B58c013d7601147DcdD68C143A77499f5531',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    SNX: {
      icon: 'snx.png',
      type: 'Volatile',
      name: 'Synthetix Network Token',
      symbol: 'SNX',
      description: `${i18n.t('dashboard:snx.content2')}  \n  \n ${i18n.t(
        'dashboard:snx.content3',
      )}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        rank: 58,
        marketCap: 713041263,
        dominance: 0.06,
        price: 8.91,
      },
      address: '0x50B728D8D964fd00C2d0AAD81718b71311feF68a',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    MANA: {
      icon: 'mana.png',
      type: 'Volatile',
      name: 'Decentraland',
      symbol: 'MANA',
      description: ` ${i18n.t('dashboard:mana.content1')}\n \n ${i18n.t(
        'dashboard:mana.content2',
      )}\n \n ${i18n.t('dashboard:mana.content3')} `,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        rank: 0,
        marketCap: 0,
        dominance: 0,
        price: 0,
      },
      address: '0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    SAND: {
      icon: 'sand.png',
      type: 'Volatile',
      name: 'The Sandbox',
      symbol: 'SAND',
      description: `${i18n.t('dashboard:sand.content1')}\n \n ${i18n.t(
        'dashboard:sand.content2',
      )}\n \n ${i18n.t('dashboard:sand.content3')} `,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        rank: 0,
        marketCap: 0,
        dominance: 0,
        price: 0,
      },
      address: '0xbbba073c31bf03b8acf7c28ef0738decf3695683',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    WOO: {
      icon: 'woo.png',
      type: 'Volatile',
      name: 'WOO',
      symbol: 'WOO',
      description: `${i18n.t('dashboard:woo.content1')}  \n  \n ${i18n.t(
        'dashboard:woo.content2',
      )} \n  \n ${i18n.t('dashboard:woo.content3')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        rank: 0,
        marketCap: 0,
        dominance: 0,
        price: 0,
      },
      address: '0x1B815d120B3eF02039Ee11dC2d33DE7aA4a8C603',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    SOL: {
      icon: 'sol.png',
      type: 'Volatile',
      name: 'Solana',
      symbol: 'SOL',
      description: `${i18n.t('dashboard:sol.content1')}  \n  \n ${i18n.t(
        'dashboard:sol.content2',
      )} \n  \n ${i18n.t('dashboard:sol.content3')} \n  \n ${i18n.t('dashboard:sol.content4')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        rank: 0,
        marketCap: 0,
        dominance: 0,
        price: 0,
      },
      address: '0xd93f7E271cB87c23AaA73edC008A79646d1F9912',
      decimals: 9,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    AVAX: {
      icon: 'avax.png',
      type: 'Volatile',
      name: 'Avalanche',
      symbol: 'AVAX',
      description: `${i18n.t('dashboard:avax.content1')}  \n  \n ${i18n.t(
        'dashboard:avax.content2',
      )} \n  \n ${i18n.t('dashboard:avax.content3')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        rank: 0,
        marketCap: 0,
        dominance: 0,
        price: 0,
      },
      address: '0x2C89bbc92BD86F8075d1DEcc58C7F4E0107f286b',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    MKR: {
      icon: 'mkr.png',
      type: 'Volatile',
      name: 'Maker DAO',
      symbol: 'MKR',
      description: `${i18n.t('dashboard:mkr.content1')}  \n  \n ${i18n.t(
        'dashboard:mkr.content2',
      )} \n  \n ${i18n.t('dashboard:mkr.content3')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        rank: 0,
        marketCap: 0,
        dominance: 0,
        price: 0,
      },
      address: '0x6f7C932e7684666C9fd1d44527765433e01fF61d',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    MATIC: {
      icon: 'matic.png',
      type: 'Volatile',
      name: 'Polygon',
      symbol: 'MATIC',
      description: `${i18n.t('dashboard:matic.content1')}  \n  \n ${i18n.t(
        'dashboard:matic.content2',
      )} \n  \n ${i18n.t('dashboard:matic.content3')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        rank: 0,
        marketCap: 0,
        dominance: 0,
        price: 0,
      },
      address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    UNI: {
      icon: 'uni.png',
      type: 'Volatile',
      name: 'Uniswap',
      symbol: 'UNI',
      description: `${i18n.t('dashboard:uni.content1')}  \n  \n ${i18n.t(
        'dashboard:uni.content2',
      )} \n  \n ${i18n.t('dashboard:uni.content3')} \n  \n ${i18n.t('dashboard:uni.content4')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        rank: 0,
        marketCap: 0,
        dominance: 0,
        price: 0,
      },
      address: '0xb33EaAd8d922B1083446DC23f610c2567fB5180f',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
  };
  return coins;
}

export default getCoins;
