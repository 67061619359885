import React, { useContext, useEffect } from 'react';
import { DashboardContext, type IDashboardContext } from './Dashboard/DashboardContext';
import ShareFeedbackForm from 'components/Dashboard/ShareFeedbackForm';
import './ShareFeedbackPopup.scss';

export function ShareFeedbackPopup(): JSX.Element {
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);

  useEffect(() => {
    if (dashboardContext.showShareFeedbackPopup) {
      document.body.style.overflow = 'hidden';
    }
  }, [dashboardContext.showShareFeedbackPopup]);

  return (
    <div className="share-feedback-modal-backdrop">
      <ShareFeedbackForm />
    </div>
  );
}
