import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import toastWarningIcon from 'assets/images/toast-warning-icon.svg';
import { DashboardContext, type IDashboardContext } from '../components/Dashboard/DashboardContext';
import { ethers, Contract } from 'ethers';
import { POLYGON_ADDRESSES } from '../blockchain/addresses/Polygon';
import { ExchangeIssuanceZeroExABI } from '../blockchain/abis/ExchangeIssuanceZeroExABI';
import { getIssuanceSwapQuote } from '../utils/getIssuanceSwapQuote';
import { UserServices } from '../services/UserServices';
import { type CartItem, type Web3AuthProviders } from 'types';
// import { setAllowance } from './useSetAllowance';
import { coinsConfigs } from '../blockchain/tokensConfig';
import { useGetUserIndexTokenBalance } from './useGetUserIndexTokenBalance';
import { BASE_COIN } from '../blockchain/constants';

export const useMutationIssueSetToken = (web3AuthProviders: Web3AuthProviders) => {
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const [checkoutStatus, setCheckoutStatus] = useState('');
  const [txStatus, setTxStatus] = useState<string | null>(null);

  const issueSetToken = async (bundle: CartItem | undefined, indexTokenPrice: number | null) => {
    if (!web3AuthProviders.wb3athProvider) {
      toast.warn('Web3 authentication provider is not initialized', {
        icon: () => <img src={toastWarningIcon} alt="Warning" />,
        autoClose: 15000,
      });
      return { success: false, error: 'Web3 authentication provider is not initialized' };
    }

    if (bundle?.investmentValue && bundle?.investmentValue > dashboardContext.available) {
      toast.warn('Insufficient balance', {
        icon: () => <img src={toastWarningIcon} alt="Warning" />,
        autoClose: 15000,
      });
      return { success: false, error: 'Insufficient balance' };
    }

    if (!web3AuthProviders) {
      console.error('Blockchain provider not available.');
      return { success: false, error: 'Blockchain provider not available' };
    }

    if (!bundle) {
      console.error('No bundle selected.');
      return { success: false, error: 'No bundle selected' };
    }

    const ethersProvider = new ethers.providers.Web3Provider(web3AuthProviders.wb3athProvider);
    const address = await web3AuthProviders?.provider?.getAddress();
    if (!address) {
      console.error('Address is undefined');
      return { success: false, error: 'Address is undefined' };
    }
    const signer = ethersProvider.getSigner(address);
    if (!signer) {
      console.error('No signer provided');
      setCheckoutStatus('Signer not available');
      return { success: false, error: 'Signer not available' };
    }

    // const allowanceSet = await setAllowance(signer, bundle.investmentValue, 'eure');
    // if (!allowanceSet.approved) {
    //   toast.warn('Unable to set allowance', { autoClose: 15000 });
    //   return { success: false, error: 'Unable to set allowance' };
    // }

    try {
      if (!indexTokenPrice) {
        console.error('Failed to fetch index token price');
        setCheckoutStatus('Failed to fetch index token price');
        return { success: false, error: 'Failed to fetch index token price' };
      }

      const investmentValue = ethers.BigNumber.from(bundle.investmentValue).sub(
        ethers.constants.One,
      );
      const parsedInvestmentAmount = ethers.utils.parseUnits(investmentValue.toString(), 18);
      const parsedIndexTokenPrice = ethers.utils.parseUnits(indexTokenPrice.toString(), 18);
      const parsedIndexTokenAmount = parsedInvestmentAmount
        .mul(ethers.constants.WeiPerEther)
        .div(parsedIndexTokenPrice);

      const ExchangeIssuanceZeroX = new Contract(
        POLYGON_ADDRESSES.ExchangeIssuanceZeroEx,
        ExchangeIssuanceZeroExABI,
        signer,
      );

      const { components, positions } = await ExchangeIssuanceZeroX.getRequiredIssuanceComponents(
        POLYGON_ADDRESSES.BasicIssuanceModule,
        false,
        coinsConfigs[bundle.bundle.name.toLowerCase()].address,
        parsedIndexTokenAmount,
      );

      const { quotes } = await getIssuanceSwapQuote(components, positions, true);

      const currentAvailableBalBN = dashboardContext.availableBN;

      const transactionResponse = await ExchangeIssuanceZeroX.issueExactSetFromToken(
        coinsConfigs[bundle.bundle.name.toLowerCase()].address,
        POLYGON_ADDRESSES.EurE,
        parsedIndexTokenAmount,
        parsedInvestmentAmount,
        quotes,
        POLYGON_ADDRESSES.BasicIssuanceModule,
        false,
        { gasLimit: 10000000 },
      );
      console.log('transaction response', transactionResponse);

      const receipt = await transactionResponse.wait();
      setTxStatus(receipt.status === 1 ? 'Successful' : 'Failed');
      setCheckoutStatus(receipt.status === 1 ? 'Successful' : 'Failed');

      if (receipt.status === 1) {
        toast.info(`Checkout processed successfully for ${bundle.bundle.name}`, {
          autoClose: 20000,
        });

        const newAvailableBN = await useGetUserIndexTokenBalance(
          BASE_COIN,
          dashboardContext.account,
          signer,
        );
        const netInvestedValue = currentAvailableBalBN - Number(newAvailableBN?.toString());

        await UserServices.addUserTransactionSummary(
          dashboardContext.account,
          bundle.bundle,
          netInvestedValue,
          bundle.bundle.coins,
          Number(parsedIndexTokenAmount.toString()),
        );
        const savedPortfolio = await UserServices.getPortfolio(dashboardContext.account);
        if (savedPortfolio !== null) {
          dashboardContext.setPortfolio(savedPortfolio);
        }
        return { success: true };
      } else {
        return { success: false, error: 'Transaction failed' };
      }
    } catch (error: any) {
      console.error('Transaction error:', error);
      setCheckoutStatus('Failed');
      toast.error(`Checkout failed for ${bundle.bundle.name}`, { autoClose: 15000 });
      return { success: false, error: error.message };
    }
  };

  return { issueSetToken, checkoutStatus, txStatus };
};
