import { type SafeEventEmitterProvider } from '@web3auth/base';
import ethProvider from '../services/ethProvider_v5';
import { type ContractInterface, type ethers } from 'ethers';
import { type TransactionResponse } from './transaction';

export interface WalletProvider {
  getAccounts: () => Promise<any>;
  getSigner: () => Promise<any>;
  getAddress: () => Promise<string>;
  getBalance: () => Promise<any>;
  signMessage: (message: string) => Promise<string | null>;
  getContract: (addressOrName: string, contractInterface: ContractInterface) => ethers.Contract;
  call: (
    transaction: ethers.utils.Deferrable<ethers.providers.TransactionRequest>,
    blockTag?: ethers.providers.BlockTag | Promise<ethers.providers.BlockTag> | undefined,
  ) => Promise<string>;

  signTransaction: (transaction: ethers.providers.TransactionRequest) => Promise<string>;
  signAndSendTransaction: (
    transaction: ethers.providers.TransactionRequest,
  ) => Promise<TransactionResponse>;
}

export const getWalletProvider = (
  chain: string,
  provider: SafeEventEmitterProvider,
): WalletProvider => {
  return ethProvider(provider);
};
