/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import walletIcon from 'assets/images/wallet-icon.svg';
import barIcon from 'assets/images/desktop/bar.svg';
import withdrawIcon from 'assets/images/withdraw-icon.svg';
import fundIcon from 'assets/images/fund-icon.svg';
import { Col, Row } from 'react-bootstrap';
import { type IDashboardContext, DashboardContext } from 'components/Dashboard/DashboardContext';
import './WalletOverview.scss';
import { useWeb3Auth } from 'services/web3auth';
import { useMonerium } from 'context/moneriumContext';
import { UserServices } from 'services/UserServices';
import { formatTokenBalance } from 'utils/formatTokenBalance';
import { useGetUserIndexTokenBalance } from 'hooks/useGetUserIndexTokenBalance';
import { ethers } from 'ethers';
import { BASE_COIN } from '../../blockchain/constants';
// import { getPricesForAllCoins } from 'services/swapApi';

export const WalletOverview = (): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const { provider, signMessage, wb3athProvider } = useWeb3Auth();
  // eslint-disable-next-line
  const [signature, setSignature] = useState<any>(null);
  const { moneriumAuthorized } = useMonerium();

  const [balance, setBalance] = useState(0);
  const [tokenPrices, setTokenPrices] = useState<any>(null);

  useEffect(() => {
    // eslint-disable-next-line
    if (tokenPrices === null && window.innerWidth > 675) {
      getPricesAndBalances()
        .then(() => {})
        .catch((e) => {
          console.log(e);
        });
    }
  }, [dashboardContext.portfolio.investments]);

  const getPricesAndBalances: () => Promise<void> = async () => {
    const newInvestments = dashboardContext.portfolio.investments;
    let totalCurrentValue = 0;
    // eslint-disable-next-line array-callback-return
    // const prices = await getPricesForAllCoins();
    // setTokenPrices({ ...prices });
    const investedCoins = await UserServices.getInvestedCoins(dashboardContext.account);
    const investedBundles = await UserServices.getPortfolio(dashboardContext.account);

    dashboardContext.portfolio.investments.map(async (investment: any, i: number) => {
      const invCoins = investedBundles.investments[i];
      newInvestments[i].currentInvestmentValue = invCoins.currentInvestmentValue;
      totalCurrentValue += invCoins.currentInvestmentValue;
    });
    dashboardContext.setPortfolio({
      ...dashboardContext.portfolio,
      investments: newInvestments,
      totalCurrentValue,
    });
  };

  useEffect(() => {
    if (dashboardContext.account !== '') {
      const ethersProvider = new ethers.providers.Web3Provider(wb3athProvider);
      const signer = ethersProvider.getSigner(dashboardContext.account);

      const fetchBal = useGetUserIndexTokenBalance(BASE_COIN, dashboardContext.account, signer);
      fetchBal.then((res) => {
        setBalance(formatTokenBalance(Number(res?.toString())) ?? 0);
        dashboardContext.setAvailable(formatTokenBalance(Number(res?.toString())) ?? 0);
        dashboardContext.setAvailableBN(Number(res?.toString()) ?? '0');
      });
    }
  }, [dashboardContext.balance, dashboardContext.portfolio]);

  useEffect(() => {
    const getttingSignature: any = async () => {
      if (provider === null) return;

      const signature = await signMessage('I hereby declare that I am the address owner.');
      setSignature(signature);
    };
    getttingSignature();
  }, [provider]);

  return (
    <div id="desktop-wallet-overview" className="desktop wallet-overview">
      <div className="wallet-details">
        <div className="d-flex justify-content-between align-items-end total-wallet-div">
          <div className="wallet-total-title">{t('sidebar.total')}</div>
          <div className="wallet-icon">
            <img src={walletIcon} alt="Wallet" height={18} width={15} />
          </div>
        </div>
        <div className="wallet-total">
          {dashboardContext.currencyInfo.symbol}
          {(
            (balance + dashboardContext.portfolio.totalCurrentValue) *
            dashboardContext.currencyInfo.rate
          ).toFixed(2)}
        </div>
        <div className="d-flex align-items-center avail-invest ">
          <div>
            <div className="label mb-2">{t('sidebar.available')}</div>
            <div className="total">
              {dashboardContext.currencyInfo.symbol}
              {(balance * dashboardContext.currencyInfo.rate).toFixed(2)}
            </div>
          </div>
          <div className="mx-3">
            <img src={barIcon} alt="" />
          </div>
          <div>
            <div className="label mb-2  ">{t('sidebar.value')}</div>
            <div className="total">
              {dashboardContext.currencyInfo.symbol}
              {(
                dashboardContext.portfolio.totalCurrentValue * dashboardContext.currencyInfo.rate
              ).toFixed(2)}
            </div>
          </div>
        </div>
        <Row>
          <Col className="d-flex">
            <div
              role="button"
              className="withdraw-account"
              onClick={async () => {
                if (!dashboardContext.showGuidedTour) {
                  if (moneriumAuthorized === true) {
                    dashboardContext.setShowRetrieveFromWalletPopup(true);
                  } else {
                    dashboardContext.setShowMoneriumNotAuthedPopup(true);
                  }
                }
              }}
            >
              <img src={withdrawIcon} alt="Withdraw from account" height={14} width={14} />
            </div>
            <div
              className="fund-account text-center"
              onClick={() => {
                if (!dashboardContext.showGuidedTour && signature !== null) {
                  if (moneriumAuthorized === true) {
                    dashboardContext.setShowFundWalletPopup(true);
                  } else {
                    dashboardContext.setShowMoneriumNotAuthedPopup(true);
                  }
                } else {
                  console.log('signature == null');
                }
              }}
            >
              <img src={fundIcon} alt="Fund Wallet" height={14} width={14} />
              {t('sidebar.fund')}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
