import React, { useContext, useEffect, useRef, useState } from 'react';
import tick from '../assets/images/green-tick.png';
import warn from '../assets/images/toast-warning-icon.svg';

import './SuccessTransaction.scss';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DashboardContext, type IDashboardContext } from './Dashboard/DashboardContext';

interface IProps {
  close: () => void;
  withdraw: boolean;
  items: any[];
  failedTxs: any;
}

export const SuccessTransaction: React.FC<IProps> = ({ close, withdraw, items, failedTxs }) => {
  const navigate = useNavigate();
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const [failure, setFailure] = useState(false);
  const failedDetails = useRef(null);

  useEffect(() => {
    const allFailed: boolean[] = [];
    items.forEach((item: any) => {
      // eslint-disable-next-line
      if (failedTxs !== undefined) allFailed.push(failedTxs[`${item.bundle?.name}`]?.all);
    });
    // eslint-disable-next-line
    setFailure(allFailed.every((value) => value === true));
  }, []);

  const handleGetQuotes: () => any = async () => {};

  return (
    <div
      className="st-blur-cont"
      onClick={() => {
        toast.dismiss();
        close();
      }}
    >
      <div className="st-inner" style={failure ? { width: '455px', height: '348.41px' } : {}}>
        <div className="st-top">
          <img src={failure ? warn : tick} alt="" />
          <h2>{failure ? 'Failed' : 'Success'}</h2>
          {failure ? (
            <p>Oops, something went wrong during investment. Please try again.</p>
          ) : (
            <p>
              You have successfully {!withdraw ? 'invested' : 'withdrawn'}. <br /> Details of the{' '}
              {!withdraw ? 'investment' : 'withdrawal'} are included below.
            </p>
          )}
        </div>
        <hr />
        {!failure && (
          <div className="st-bottom">
            <h3>Transaction Details</h3>
            {items.map((item: any, i: number) => {
              return (
                <>
                  <div className="inline" key={i}>
                    <p>{!withdraw ? item?.bundle?.name : item?.investment?.bundle?.name}</p>
                    <p>
                      <span>
                        {dashboardContext.currencyInfo.symbol}
                        {!withdraw
                          ? +item?.investmentValue?.toFixed(2)
                          : item?.withdrawAmount.toFixed(2)}
                      </span>
                    </p>
                  </div>
                  {
                    // eslint-disable-next-line
                    failedTxs[`${item?.bundle?.name}`]?.length > 0 && (
                      <>
                        <h3 style={{ marginTop: '1rem' }} ref={failedDetails}>
                          Failed Transactions:
                        </h3>
                        {
                          // eslint-disable-next-line
                          failedTxs[`${item?.bundle?.name}`]?.map((coin: any, i: number) => (
                            <p key={i}>{coin.coin}</p>
                          ))
                        }
                      </>
                    )
                  }
                </>
              );
            })}
          </div>
        )}
        <div className="btns" style={failure ? { justifyContent: 'center' } : {}}>
          <div
            className="btn-grey"
            onClick={() => {
              toast.dismiss();
              dashboardContext.setShowSuccessTransaction(false);
              navigate('/dashboard');
              close();
            }}
          >
            Back to Dashboard
          </div>
          {failure || failedDetails.current !== null ? (
            <div
              className="btn-green"
              style={{ width: 'auto' }}
              onClick={() => {
                toast.dismiss();
                handleGetQuotes();
                dashboardContext.setShowSuccessTransaction(false);
                navigate('/portfolio');
                close();
              }}
            >
              Retry
            </div>
          ) : (
            <div
              className="btn-green"
              onClick={() => {
                toast.dismiss();
                dashboardContext.setShowSuccessTransaction(false);
                navigate('/portfolio');
                close();
              }}
            >
              View my portfolio
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
