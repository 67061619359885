import { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './GuidedTour.scss';
import Layout from 'components/Layout/Layout';
import { TourSelectCard } from 'components/TourSelectCard';

import useBodyClass from 'hooks/useBodyClass';
// import helpIcon from 'assets/images/help-icon.svg';
import { type IDashboardContext, DashboardContext } from 'components/Dashboard/DashboardContext';
import { ShepherdTourContext } from 'react-shepherd';
import { appTourSteps, finalTourSteps, supportTourSteps } from 'config/TourSteps';
import { appMobileTourSteps, supportMobileTourSteps } from 'config/MobileTourSteps';
import { ContactButtons } from 'components/ContactButtons';

export const GuidedTour = (): JSX.Element => {
  const { t } = useTranslation('guidedtour');
  // const { isMobile } = useParams();
  const [selectedAppTour, setSelectedAppTour] = useState(false);
  const [selectedSupportTour, setSelectedSupportTour] = useState(false);
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const navigate = useNavigate();
  const tour = useContext(ShepherdTourContext);

  const [small, setSmall] = useState(false);
  useEffect(() => {
    window.innerWidth < 767.98 ? setSmall(true) : setSmall(false);
  }, [window.innerWidth]);

  const handleNextStep = (): void => {
    if ((selectedAppTour || selectedSupportTour) && tour != null) {
      const steps = [...tour.steps];
      for (let stepsLoop = 0; stepsLoop < steps.length; stepsLoop++) {
        tour.removeStep(steps[stepsLoop].id);
      }

      let combinedSteps: any[] = [];

      if (window.innerWidth < 767.98) {
        // if (isMobile === 'true') {
        if (selectedAppTour) combinedSteps = [...combinedSteps, ...appMobileTourSteps()];
        if (selectedSupportTour) combinedSteps = [...combinedSteps, ...supportMobileTourSteps()];
      } else {
        if (selectedAppTour) combinedSteps = [...combinedSteps, ...appTourSteps()];
        if (selectedSupportTour) combinedSteps = [...combinedSteps, ...supportTourSteps()];
      }
      combinedSteps = [...combinedSteps, ...finalTourSteps()];
      dashboardContext.setTourSteps(combinedSteps);
      navigate('/dashboard');
      dashboardContext.setShowGuidedTour(true);
    }
  };

  useBodyClass(['start-body']);
  return (
    <Layout>
      <Container className="guided-tour ">
        <Row>
          <Col sm={12} md={6}>
            <div className="d-none d-md-block">
              <h1>
                {t('select.header1')} {t('select.header2')}
                <br />
                {t('select.header3')} {t('select.header4')}
              </h1>

              <p className="tagline">{t('welcome.header')}</p>
              <p className="tagline">{t('welcome.content')}</p>
            </div>
            <div className="d-block d-md-none">
              <h1>
                {t('select.header1')} {t('select.header3')}
                <br /> {t('select.header4')}
              </h1>
              <p className="tagline">{t('select.content1')}</p>
            </div>
            <p className="tagline">{t('select.content2')}</p>
            {/* <div className="help-center d-none d-md-flex  align-items-center ">
              <div className="logo  d-flex align-items-center justify-content-center">
                <img src={helpIcon} alt="Help Center" />
              </div>
              <div>Help Center</div>
            </div> */}
            {!small && (
              <div style={{ marginTop: '32px' }}>
                <ContactButtons small={false} />
              </div>
            )}
          </Col>

          <Col sm={12} md={6}>
            <div className="tour-options">
              <TourSelectCard
                header={t('select.tour1')}
                description={t('select.tourContent1')}
                selectedTour={selectedAppTour}
                setSelectedTour={setSelectedAppTour}
                time={`2 ${t('select.minutes')}`}
              />
              <TourSelectCard
                header={t('select.tour2')}
                description={t('select.tourContent2')}
                selectedTour={selectedSupportTour}
                setSelectedTour={setSelectedSupportTour}
                time={`1 ${t('select.minute')}`}
              />

              <div className="d-flex buttons">
                <button
                  className="btn btn-secondary float-end"
                  onClick={() => {
                    navigate('/dashboard');
                  }}
                >
                  Skip
                </button>
                <button
                  className="flex-grow-1 btn btn-primary btn-confirm w-100"
                  disabled={!selectedAppTour && !selectedSupportTour}
                  onClick={handleNextStep}
                >
                  Start
                </button>
              </div>
              {/* {small && (
                <div style={{ marginTop: '25px' }}>
                  <ContactButtons small={true} />
                </div>
              )} */}
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
