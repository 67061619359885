import { getNotionalAmounts } from '../utils/getNotionalAmounts';
import { useCallback, useMemo, useState } from 'react';
import getCoins from '../config/coinsConfig';
import { getCoinPrice } from '../utils/getCoinPrice';

export const useIndexTokenPrice = () => {
  const [error, setError] = useState<string | null>(null);
  const coins = useMemo(() => getCoins(), []);
  const coinDetails = useMemo(() => {
    return Object.values(coins).map((coin) => ({
      tokenAddress: coin.address,
      decimals: coin.decimals,
    }));
  }, [coins]);

  // TODO: currently getting index token price as float, need to get it as BigNumber

  const getIndexTokenPrice = useCallback(
    async (bundleName: string): Promise<any> => {
      setError(null);
      try {
        const prices = await getCoinPrice(coinDetails);
        const notionalAmounts = await getNotionalAmounts(bundleName.toLowerCase());
        const totalIndexPrice = notionalAmounts.reduce((acc, notional) => {
          const coinData = prices.find(
            (coin) => coin.tokenAddress.toUpperCase() === notional.address.toUpperCase(),
          );
          return acc + (coinData ? coinData.usdPrice * notional.amount : 0);
        }, 0);
        return totalIndexPrice;
      } catch (ex) {
        setError('Failed to fetch index token price');
        console.error(ex);
        return null;
      }
    },
    [coinDetails],
  );
  return { error, getIndexTokenPrice };
};
