/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useContext, useEffect, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DashboardContext, type IDashboardContext } from '../DashboardContext';
import copyIcon from 'assets/images/copy-icon-dark.svg';
import editIcon from 'assets/images/edit-icon-dark.svg';

import './DashboardProfilePopup.scss';
import { truncateAddress } from 'utils/truncateAddress';
import { useAuth } from 'hooks/useAuth';
import { UserServices } from 'services/UserServices';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

export const DashboardProfilePopup = (): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const { isSignedIn } = useAuth();
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const [showCopiedWallet, setShowCopiedWallet] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState('');

  useEffect(() => {
    const getProfile = async (): Promise<void> => {
      const profile = await UserServices.getSelectedProfile();

      switch (profile) {
        case 1:
          setSelectedProfile(t('account.careful') ?? 'Careful');
          break;
        case 2:
          setSelectedProfile(t('account.balanced') ?? 'Balanced');
          break;
        case 3:
          setSelectedProfile(t('account.adventurous') ?? 'Adventurous');
          break;
        default:
          setSelectedProfile('');
      }
    };
    if (dashboardContext.showProfilePopup) getProfile().catch(console.error);
  }, [isSignedIn, dashboardContext.showProfilePopup]);

  const handleClose = (): void => {
    dashboardContext.setShowProfilePopup(false);
  };

  return (
    <div className="profile">
      <Modal
        id="profile-popup"
        show={dashboardContext.showProfilePopup}
        onHide={handleClose}
        animation={false}
        className={`profile-popup-modal d-none d-md-block ${
          dashboardContext.showGuidedTour ? 'dashboard-layout-tour' : ''
        }`}
        backdropClassName="profile-popup-backdrop"
      >
        <Modal.Header>
          <Modal.Title>{t('account.useraccount')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="content-item"
            onClick={() => {
              if (!dashboardContext.startedGuidedTour) {
                navigator.clipboard.writeText(dashboardContext.account).catch(() => {});
                setShowCopiedWallet(true);
                setTimeout(() => {
                  setShowCopiedWallet(false);
                }, 3000);
              }
            }}
          >
            <OverlayTrigger
              trigger="click"
              overlay={<Tooltip id="test">{t('account.copied')}</Tooltip>}
              placement="top"
              show={showCopiedWallet}
            >
              <div role="button">
                <div className="title">{t('account.walet')}</div>
                <div className="d-flex">
                  <p className="flex-grow-1">{truncateAddress(dashboardContext.account)}</p>
                  <img src={copyIcon} alt="Copy" height={13} width={13} />
                </div>
              </div>
            </OverlayTrigger>
          </div>
          <div className="content-item">
            <div
              role="button"
              onClick={() => {
                if (!dashboardContext.startedGuidedTour) {
                  dashboardContext.setShowProfilePopup(false);
                  dashboardContext.setShowSelectProfilePopup(true);
                }
              }}
            >
              <div className="title">{t('account.profile')}</div>
              <div className="d-flex">
                <p className="flex-grow-1">{selectedProfile}</p>
                <img src={editIcon} alt="Copy" height={13} width={13} />
              </div>
            </div>
          </div>
          <div className="content-item">
            <div
              role="button"
              onClick={async () => {
                if (!dashboardContext.startedGuidedTour) {
                  dashboardContext.setShowProfilePopup(false);
                  dashboardContext.setShowSelectLanguage(true);
                }
              }}
            >
              <div className="title">{t('account.language')}</div>
              <div className="d-flex">
                <p className="flex-grow-1">
                  {i18n.language === 'en' ? t('account.en') : t('account.nl')}
                </p>
                <img src={editIcon} alt="Copy" height={13} width={13} />
              </div>
            </div>
          </div>
          <div className="content-item">
            <div
              role="button"
              onClick={async () => {
                if (!dashboardContext.startedGuidedTour) {
                  dashboardContext.setShowProfilePopup(false);
                  dashboardContext.setShowCurrencySwitchPopup(true);
                }
              }}
            >
              <div className="title">Currency</div>
              <div className="d-flex">
                <p className="flex-grow-1">
                  {/* US Dollar ($) */}
                  {/* Euro (€) */}
                  {dashboardContext.currencyInfo.rate === 1 ? 'Euro ' : 'US Dollar '}
                  {'(' + dashboardContext.currencyInfo.symbol + ')'}
                  {/* {i18n.language === 'en' ? t('account.en') : t('account.nl')} */}
                </p>
                <img src={editIcon} alt="Copy" height={13} width={13} />
              </div>
            </div>
          </div>
          <div className="content-item">
            <div
              role="button"
              onClick={() => document.getElementById('open_preferences_center')?.click()}
              style={{ cursor: 'pointer' }}
            >
              <a href="#" id="open_preferences_center"></a>
              <div className="title">{t('account.cookies')}</div>
              <div className="d-flex">
                <p className="flex-grow-1"></p>
                <img src={editIcon} alt="Copy" height={13} width={13} />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
