import { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { DashboardContext, type IDashboardContext } from './Dashboard/DashboardContext';
import i18n from 'i18next';

import './SelectProfilePopup.scss';
import { useTranslation } from 'react-i18next';

export const LanguageSwitcher = (): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);

  const handleClose = (): void => {
    dashboardContext.setShowSelectLanguage(false);
  };

  return (
    <>
      <Modal
        onHide={handleClose}
        show={dashboardContext.showSelectLanguage}
        animation={false}
        className="select-profile-modal"
        centered
      >
        <Modal.Header>
          <Modal.Title style={{ marginBottom: '20px' }}>{t('account.language')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="profile-btn-group" style={{ marginBottom: '0' }}>
            <Button
              className={`${i18n.language === 'en' ? 'profile-selected' : ''}`}
              style={i18n.language === 'en' ? { backgroundColor: '#71FECF' } : {}}
              onClick={async () => {
                await i18n.changeLanguage('en');
                handleClose();
              }}
            >
              {t('account.en')}
            </Button>
            <Button
              className={`${i18n.language === 'nl' ? 'profile-selected' : ''}`}
              style={i18n.language === 'nl' ? { backgroundColor: '#71FECF' } : {}}
              onClick={async () => {
                await i18n.changeLanguage('nl');
                handleClose();
              }}
            >
              {t('account.nl')}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
