import DashboardLayout from 'components/Dashboard/Layout/DashboardLayout';
import { PortfolioOverview } from 'components/Dashboard/PortfolioOverview';
import { PortfolioBundlePerformance } from 'components/Portfolio/PortfolioBundlePerformance';
import { PortfolioInvestments } from 'components/Portfolio/PortfolioInvestments';
import { type IDashboardContext, DashboardContext } from 'components/Dashboard/DashboardContext';
import useBodyClass from 'hooks/useBodyClass';
import { useContext, useEffect, useState } from 'react';
import { type Investment, type PortfolioPerformance } from 'types';
import { useTranslation } from 'react-i18next';
import './Portfolio.scss';
import { PortfolioInvestmentsMobile } from 'components/Portfolio/PortfolioInvestmentsMobile';

export const Portfolio = () => {
  const { t } = useTranslation('dashboard');
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const [activeInvestment, setActiveInvestment] = useState<Investment | null>(null);
  const [failedTx, setFailedTx] = useState<any>(null);
  const [currentInvestments, setCurrentInvestments] = useState(0);

  useEffect(() => {
    if (activeInvestment !== null) {
      const ai = dashboardContext.portfolio.investments.find(
        (investment: Investment) => investment.bundle.name === activeInvestment.bundle.name,
      );
      if (ai && +ai.currentInvestmentValue.toFixed(1) > 0) {
        setActiveInvestment(ai);
      } else if (
        dashboardContext.portfolio.investments.length > 0 &&
        dashboardContext.portfolio.investments[0] !== undefined
      ) {
        setActiveInvestment(dashboardContext.portfolio.investments[0]);
      }
    }
  }, [dashboardContext.portfolio.investments, activeInvestment]);

  useEffect(() => {
    setCurrentInvestments(
      dashboardContext.portfolio.investments.filter(
        (investment: Investment) => Math.round(investment.currentInvestmentValue * 10) / 10 !== 0,
      ).length,
    );
  }, [dashboardContext.portfolio.numberOfInvestments]);

  useBodyClass(['dashboard-body']);
  return (
    <DashboardLayout headerText={t('sidebar.portfolio')}>
      <PortfolioOverview showHeader={false} />
      <div className="d-none d-md-block">
        <div className="d-flex portfolio-details align-items-start">
          <PortfolioInvestments
            setActiveInvestment={(
              investment: Investment | null,
              performance: PortfolioPerformance | null,
            ) => {
              setActiveInvestment(investment);
            }}
            setFailedTx={setFailedTx}
          />
          {currentInvestments !== 0 &&
            failedTx === null &&
            activeInvestment !== null &&
            +activeInvestment?.currentInvestmentValue.toFixed(1) !== 0 && (
              <PortfolioBundlePerformance activeInvestment={activeInvestment} />
            )}
        </div>
      </div>
      <div className="d-md-none d-block">
        <PortfolioInvestmentsMobile />
      </div>
    </DashboardLayout>
  );
};
