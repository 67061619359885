import React from 'react';
import fenusLogo from 'assets/images/fenus_logo.png';
import { Link } from 'react-router-dom';

export const Header = (): JSX.Element => {
  return (
    <header>
      <Link to="/">
        <img className="header_logo" src={fenusLogo} alt="Fenus Logo" style={{ height: '40px' }} />
      </Link>
    </header>
  );
};
