import React from 'react';

import backIcon from 'assets/images/bundle-back-icon.svg';
import nextIcon from 'assets/images/next-icon.svg';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';

function Arrow({
  children,
  disabled,
  onClick,
}: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
}): JSX.Element {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={{
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        opacity: disabled ? '0.2' : '1',
        userSelect: 'none',
        fontSize: '16px',
        backgroundColor: 'transparent',
        border: 'none',
        color: '#222222',
      }}
    >
      {children}
    </button>
  );
}

export function LeftArrow(): JSX.Element {
  const { isFirstItemVisible, scrollPrev, visibleElements, initComplete } =
    React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible),
  );
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length > 0) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollPrev()}>
      <img src={backIcon} alt="left" height={18} />
    </Arrow>
  );
}

export function RightArrow(): JSX.Element {
  const { isLastItemVisible, scrollNext, visibleElements } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(visibleElements.length === 0 && isLastItemVisible);
  React.useEffect(() => {
    if (visibleElements.length > 0) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollNext()}>
      <img src={nextIcon} alt="Right" height={18} />
    </Arrow>
  );
}

// export function BackArrw()?
