import React from 'react';
import { Accordion } from 'react-bootstrap';
import { ContactButtons } from './ContactButtons';

import './InvestorFAQ.scss';
import { useTranslation } from 'react-i18next';

export const InvestorFAQ = (): JSX.Element => {
  const { t } = useTranslation('dashboard');
  return (
    <div className="investor-faq">
      <Accordion defaultActiveKey="0" flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t('investorfaq.ques1')}</Accordion.Header>
          <Accordion.Body>{t('investorfaq.ans1')}</Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{t('investorfaq.ques2')}</Accordion.Header>
          <Accordion.Body>{t('investorfaq.ans2')}</Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="mt-4">
        <ContactButtons small={window.innerWidth <= 576} />
      </div>
    </div>
  );
};
