import React from 'react';
import { ContactButton } from './ContactButton';
import helpCenterIcon from 'assets/images/help-center-icon.svg';
import callIcon from 'assets/images/call-icon.svg';
import { useTranslation } from 'react-i18next';

import './ContactButtons.scss';

interface IProps {
  small: boolean;
}

export const ContactButtons: React.FC<IProps> = ({ small }: IProps) => {
  const { t } = useTranslation('home');
  return (
    <div className={small ? 'cb-cont cb-cont-small' : 'cb-cont'}>
      <ContactButton
        icon={helpCenterIcon}
        text={t('contactbtns.btn1')}
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        link={`${process.env.REACT_APP_website_domain}/help/`}
        small={small}
      />
      <ContactButton
        icon={callIcon}
        text={t('contactbtns.btn2')}
        link="https://calendly.com/amjad-fourani"
        small={small}
      />
    </div>
  );
};
