import React from 'react';
import { useNavigate } from 'react-router-dom';
import image from 'assets/images/no-investment.svg';
// /images/bundles/growth_bundle.svg growth_bundle.svg

export const NoInvestments = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <div className="no-inv-inner">
      <div className="no-inv-text">
        <img src={image} alt="" />
        <p>It seems like you haven’t invested yet!</p>
      </div>
      <div
        className="start-now"
        onClick={() => {
          navigate('/dashboard');
        }}
      >
        Start Now
      </div>
    </div>
  );
};
