import './Terms.scss';
import { useTranslation } from 'react-i18next';
import startViewImg from 'assets/images/start-view-image.svg';

const textStyle: React.CSSProperties = {
  fontFamily: 'Work Sans',
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '22px',
  textAlign: 'left',
  padding: '17px 22px',
};

interface Props {
  setShowTerms: any;
}

const terms =
  'By using Fenus, you agree to assume full responsibility for any risks associated with your use, including market volatility, smart contract vulnerabilities, and loss of funds. All transactions on our platform are final and irreversible, and we are not responsible for any errors or mistakes. Prohibited activities include illegal actions such as money laundering or terrorist financing. Our platform is provided "as is" without any warranties, and we are not liable for any damages arising from your use. We reserve the right to update these terms without prior notice, and these terms shall be governed by the laws of the applicable jurisdiction.';

export const Terms = (props: Props): JSX.Element => {
  const { t } = useTranslation('home');
  return (
    <div className="terms">
      <img src={startViewImg} alt="Get started" />
      <h1>{t('terms.header')}</h1>
      <textarea
        style={textStyle}
        className="form-control"
        rows={7}
        readOnly
        value={t('terms.content') ?? terms}
      ></textarea>

      <button
        type="submit"
        className="btn btn-primary w-100"
        onClick={() => props.setShowTerms(false)}
      >
        {t('terms.btn')}
      </button>
    </div>
  );
};
