import { useState } from 'react';
// import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Navbar from 'react-bootstrap/Navbar';

import './FAQ.scss';

interface Props {
  children?: JSX.Element | JSX.Element[];
}

const FAQ: React.FC<Props> = (props: Props) => {
  const [show, setShow] = useState(false);
  const handleClose = (): void => {
    setShow(false);
  };
  const handleShow = (): void => {
    setShow(true);
  };

  return (
    <>
      <Navbar fixed="bottom" className="faq-nav d-md-none">
        <button className="w-100 faq-button" onClick={handleShow}>
          FAQ
        </button>
      </Navbar>
      <Offcanvas class="h-auto" show={show} onHide={handleClose} placement="bottom">
        <Offcanvas.Header>
          <Offcanvas.Title>FAQ</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>{props.children}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

FAQ.propTypes = {};

export default FAQ;
