import { useState, useEffect } from 'react';
import { auth } from 'config/firebaseConfig';
import { type User, onAuthStateChanged, type Auth } from 'firebase/auth';

interface IAuth {
  isSignedIn: boolean;
  pending: boolean;
  user: User | null;
}

interface IAuthReturn extends IAuth {
  auth: Auth;
}

export function useAuth(): IAuthReturn {
  const [authState, setAuthState] = useState<IAuth>({
    isSignedIn: false,
    pending: true,
    user: null,
  });

  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(auth, (user) => {
      setAuthState({ user, pending: false, isSignedIn: !(user == null) });
    });
    return () => {
      unregisterAuthObserver();
    };
  }, []);

  return { auth, ...authState };
}
