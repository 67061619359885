import { ethers } from 'ethers';

import { type CoinDetails } from 'types';

export const MAX_AMOUNT_TO_APPROVE = ethers.constants.MaxInt256;

export const USDC: CoinDetails = {
  tokenAddress: `0x3c499c542cEF5E3811e1192ce70d8cC03d5c3359`,
  decimals: 6,
};

export const ONE = '1';

export const BASE_COIN = 'eure';
