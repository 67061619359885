import { type BigNumber, ethers } from 'ethers';
import { ONE } from 'blockchain/constants';

/**
 * Function to calculate the token balance value in USD.
 *
 * @param {BigNumber} tokenBalance - The balance of the token.
 * @param {number} indexTokenPrice - The price of the index token.
 * @returns {BigNumber} - The token balance value in USD.
 */

export const calculateIndexTokenBalanceValue = (
  tokenBalance: BigNumber,
  indexTokenPrice: number,
): BigNumber => {
  return tokenBalance
    .mul(ethers.utils.parseUnits(indexTokenPrice.toString(), 18))
    .div(ethers.utils.parseUnits(ONE, 18));
};
