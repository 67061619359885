import { Button, Navbar } from 'react-bootstrap';
import './GuidedTourHeader.scss';
import logo from 'assets/images/fenus_logo.svg';
import { useTranslation } from 'react-i18next';

const GuidedTourHeader: React.FC = () => {
  const { t } = useTranslation('guidedtour');
  return (
    <>
      <Navbar
        className="guided-tour-header"
        style={{ paddingRight: '1.5rem', paddingLeft: '1.5rem' }}
      >
        <div className="flex-grow-1">
          <img className="header-logo" src={logo} alt="Fenus Logo" />
        </div>
        <div className="d-none d-md-block label active">{t('header.tour')}</div>
        <div className="d-none d-md-block label active">|</div>
        <div className="d-none d-md-block label active">{t('header.app')}</div>
        <div className="d-none d-md-block label active"> · </div>
        <div className="d-none d-md-block label">{t('header.support')}</div>
        <Button
          onClick={() => {
            const element = document.getElementsByClassName(
              'shepherd-cancel-icon',
            )[0] as HTMLElement;
            element.click();
            const d = document.getElementById('helpcenter-iframe') as HTMLElement;
            // if (d !== null)
            d.style.display = 'none';
          }}
        >
          {t('header.exit')}
        </Button>
      </Navbar>
    </>
  );
};

GuidedTourHeader.propTypes = {};

export default GuidedTourHeader;
