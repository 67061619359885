import { ZeroXPriceRequestUrl } from '../constants/Urls';
import { stringify } from 'qs';
import { ONE, USDC } from '../blockchain/constants';
import { ethers } from 'ethers';
import axios from 'axios';
import { type ZeroExPriceResponse, type CoinDetails } from 'types';

export const getCoinPrice = async (coinDetails: CoinDetails[]) => {
  try {
    return await Promise.all(
      coinDetails.map(async (coinDetail, index) => {
        const params = {
          buyToken: coinDetail.tokenAddress,
          sellToken: USDC.tokenAddress,
          buyAmount: ethers.utils.parseUnits(ONE, coinDetail.decimals).toString(),
        };
        const zeroExQuery = ZeroXPriceRequestUrl + stringify(params);
        const response = await axios.get<ZeroExPriceResponse>(zeroExQuery, {
          headers: { '0x-api-key': process.env.REACT_APP_zero_x_API_KEY },
        });
        const price = response.data.price;
        return {
          tokenAddress: coinDetail.tokenAddress,
          usdPrice: parseFloat(price),
        };
      }),
    );
  } catch (error) {
    console.error('Failed to fetch coin prices', error);
    return [];
  }
};
