/* eslint-disable */
import { type IPortfolio } from 'components/Dashboard/DashboardContext';
import { auth, firestore } from 'config/firebaseConfig';
import { type UserCredential } from 'firebase/auth';
import {
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  collection,
  increment,
  onSnapshot,
  type DocumentSnapshot,
  type DocumentReference,
  arrayUnion,
} from 'firebase/firestore';
import type { BundleData, Coin, CartItem, Investment, TransactionResponse } from 'types';
import { BigNumber } from 'ethers';
import { formatTokenBalance } from 'utils/formatTokenBalance';

export const UserServices = {
  addUserDoc: async function (loginRes: UserCredential, providerName: string, address: string) {
    const user = loginRes.user;
    const docRef = doc(firestore, 'users', user.uid);

    await getDoc(docRef)
      .then(async (doc) => {
        if (doc.exists()) {
          console.log('User data found');
          const userData = doc.data();
          if (!userData.walletAddress) {
            await updateDoc(docRef, {
              walletAddress: address,
            });
          }
          await updateDoc(docRef, {
            currency: 2,
          });
          console.log('currency updated');
        } else {
          await setDoc(docRef, {
            uid: user.uid,
            name: user.displayName,
            authProvider: providerName,
            email: user.email,
            currency: 2,
            walletAddress: address,
          });
        }
      })
      .catch((error) => {
        console.log('Error getting document:', error);
      });
  },
  getIfKYCDone: async function () {
    if (auth.currentUser?.uid == null) {
      return null;
    }
    const docRef = doc(firestore, 'users', auth.currentUser?.uid);
    const status = await getDoc(docRef).then(async (doc) => {
      if (doc.exists()) {
        const userData = doc.data();
        if (userData.kycStatus !== "approved") {
          return false;
        } else {
          return true
        }
      }}) 
      return status
  },

  getWelcomeMailSent: async function () {
    if (auth.currentUser?.uid == null) {
      return null;
    }
    const docRef = doc(firestore, 'users', auth.currentUser?.uid);
    const returnUrl = await getDoc(docRef).then(async (doc) => {
      if (doc.exists()) {
        const userData = doc.data();
        if (userData.welcomeMailSent !== true) {
          return false;
        } else {
          return true
        }
      }}) 
      return returnUrl
  },

  updateWelcomeMailStatus: async function (val: boolean) {
    if (auth.currentUser !== null) {
      const docRef = doc(firestore, 'users', auth.currentUser.uid);

      // const dateNow = new Date();
      // const emailSentTimestamp = dateNow.setHours(0, 0, 0, 0);

      await updateDoc(docRef, {
        welcomeMailSent: val,
        // emailSentTimestamp,
      }).then(() => {
        console.log('Updated welcome mail status');
      });
    }
  },

  getUserLoginRedirect: async function (): Promise<string | null> {
    if (auth.currentUser?.uid == null) {
      return null;
    }
    const docRef = doc(firestore, 'users', auth.currentUser?.uid);

    const returnUrl = await getDoc(docRef).then(async (doc): Promise<string> => {
      if (doc.exists()) {
        const userData = doc.data();

        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (userData.kycStatus !== "approved") {
          return '/kyc';
        }
        if (!userData.investorProfile) {
          const isKycApproved = await UserServices.getIfKYCDone();
          const isWelcomMailAlreadySent = await UserServices.getWelcomeMailSent();
          if (isKycApproved && !isWelcomMailAlreadySent) {
            const bodyData = {
              email: auth?.currentUser?.email,
              name: auth?.currentUser?.providerData[0]?.displayName,
            };
            fetch('/.netlify/functions/welcome-email', {
              method: 'post',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(bodyData),
            })
              .then(async (data) => {
                if (data.ok) {
                  UserServices.updateWelcomeMailStatus(true);
                } else {
                  throw new Error('not ok ');
                }
              })
              .catch((error: any) => {
                alert(error);
              });
          }
          return '/select-profile';
        }
        return '/dashboard';
        // return '/select-profile';
      } else {
        return '/';
      }
    });

    return returnUrl;
  },

  getUserMoneriumId: async function (): Promise<string | null> {
    if (auth.currentUser?.uid == null) {
      return null;
    } else {
      const docRef = doc(firestore, 'users', auth.currentUser.uid);
      try {
        const doc = await getDoc(docRef);
        if (doc.exists()) {
          return doc.data()?.moneriumId;
        } else {
          return null;
        }
      } catch (error) {
        console.error('Error getting moneriumId:', error);
        throw error;
      }
    }
  },

  setUserMoneriumId: async function (mId: any): Promise<any> {
    if (auth.currentUser?.uid == null) {
      return null;
    } else {
      const docRef = doc(firestore, 'users', auth.currentUser.uid);
      try {
        const doc = await getDoc(docRef);
        if (doc.exists()) {
          await updateDoc(docRef, {
            moneriumId: mId,
          }).then(() => {
            console.log('moneriumId updated.');
          });
        } else {
          return null;
        }
      } catch (error) {
        console.error('Error setting moneriumId:', error);
        throw error;
      }
    }
  },

  updateSelectedProfile: async function (investorProfile: number) {
    if (auth.currentUser !== null) {
      const docRef = doc(firestore, 'users', auth.currentUser.uid);

      const dateNow = new Date();
      const emailSentTimestamp = dateNow.setHours(0, 0, 0, 0);

      await updateDoc(docRef, {
        investorProfile,
        emailSentTimestamp,
      }).then(() => {
        console.log('Updated Investor Profile');
      });
    }
  },

  updateKycStatus: async function (kycStatus: "approved"|"pending"|"rejected") {
    if (auth.currentUser !== null) {
      const docRef = doc(firestore, 'users', auth.currentUser.uid);

      // const dateNow = new Date();
      // const emailSentTimestamp = dateNow.setHours(0, 0, 0, 0);

      await updateDoc(docRef, {
        kycStatus,
        // emailSentTimestamp,
      }).then(() => {
        console.log('Updated KYC detail');
      });
    }
  },

  getSelectedProfile: async function (): Promise<number> {
    if (auth.currentUser !== null) {
      const docRef = doc(firestore, 'users', auth.currentUser.uid);

      const selectedProfile = await getDoc(docRef).then(async (doc): Promise<number> => {
        if (doc.exists()) {
          const userData = doc.data();
          return userData.investorProfile;
        } else {
          return 0;
        }
      });
      return selectedProfile;
    }
    return 0;
  },
  // .....................................................................
  getSelectedCurrency: async function (): Promise<number> {
    if (auth.currentUser !== null) {
      const docRef = doc(firestore, 'users', auth.currentUser.uid);

      const selectedCurrency = await getDoc(docRef).then(async (doc): Promise<number> => {
        if (doc.exists()) {
          const userData = doc.data();
          return userData.currency;
        } else {
          return 0;
        }
      });
      return selectedCurrency;
    }
    return 0;
  },

  updateCurrency: async function (currency: number) {
    if (auth.currentUser !== null) {
      const docRef = doc(firestore, 'users', auth.currentUser.uid);

      await updateDoc(docRef, {
        currency,
      }).then(() => {
        console.log('Updated currency');
      });
      // maybe update balance as well
    }
  },
  // .....................................................................

  updateWatchWelcomeVideo: async function () {
    if (auth.currentUser !== null) {
      const docRef = doc(firestore, 'users', auth.currentUser.uid);

      await updateDoc(docRef, {
        watchedWelcome: true,
      }).then(() => {
        console.log('Updated Watched Welcome video');
      });
    }
  },
  deleteUserData: async function () {
    if (auth.currentUser !== null) {
      const docRef = doc(firestore, 'users', auth.currentUser.uid);

      await deleteDoc(docRef).then(() => {
        console.log('Deleted User details');
      });
    }
  },

  addUserTransaction: async function (
    walletAddress: string,
    bundle: BundleData,
    coin: Coin,
    transactionResponse: TransactionResponse,
    coinInvestment: number,
    failed: boolean,
  ) {
    if (auth.currentUser?.uid == null) {
      return null;
    }
    const portfolioRef = doc(firestore, 'portfolio', walletAddress);

    await getDoc(portfolioRef).then(async (docSnapShot) => {
      transactionResponse.lastUpdated = Date.now();

      if (!docSnapShot.exists()) {
        await setDoc(portfolioRef, {
          walletAddress,
        });
      }
      const bundlesCollectionRef = collection(portfolioRef, 'bundles');
      const bundleRef = doc(bundlesCollectionRef, bundle.name);
      await getDoc(bundleRef).then(async (bundleSnapShot) => {
        if (!bundleSnapShot.exists()) {
          await setDoc(bundleRef, {
            bundle,
            totalInvestment: failed ? 0 : coinInvestment,
            failedTxs: failed
              ? [
                  {
                    coin: coin.coin,
                    value: coinInvestment,
                  },
                ]
              : [],
          });
        } else {
          if (failed) {
            const txArray = bundleSnapShot.data().failedTxs;
            const coinTxs = txArray.filter((entry: any) => entry.entry === coin.coin);
            const existingEntryIndex =
              coinInvestment > 0
                ? coinTxs.findIndex((entry: any) => entry.value > 0)
                : coinTxs.findIndex((entry: any) => entry.value < 0);

            if (existingEntryIndex !== -1) {
              // eslint-disable-next-line
              txArray[existingEntryIndex].value += coinInvestment;
              await updateDoc(bundleRef, {
                failedTxs: txArray,
              });
            } else {
              await updateDoc(bundleRef, {
                failedTxs: arrayUnion({ coin: coin.coin, value: coinInvestment }),
              });
            }
          } else {
            await updateDoc(bundleRef, { totalInvestment: increment(coinInvestment) });
          }
        }

        const coinsCollectionRef = collection(bundleRef, 'coins');
        const coinRef = doc(coinsCollectionRef, coin.coin);

        await getDoc(coinRef).then(async (coinSnapShot) => {
          if (!coinSnapShot.exists()) {
            await setDoc(coinRef, {
              coin,
              amount: 0,
              currentValue: 0,
            });
          }
        });

        const transactionRef = doc(coinRef, 'transactions', transactionResponse.transactionHash);

        await setDoc(transactionRef, transactionResponse);
      });
    });
  },

  addUserTransactionSummary: async function (
    walletAddress: string,
    bundle: BundleData,
    investedValue: number,
    investedCoins: any[],
    indexTokenAmount: number,
  ) {
    if (auth.currentUser?.uid == null) {
      return null;
    }
    const portfolioRef = doc(firestore, 'portfolio', walletAddress);

    await getDoc(portfolioRef).then(async (docSnapShot) => {
      if (!docSnapShot.exists()) {
        await setDoc(portfolioRef, {
          walletAddress,
        });
      }
      const bundlesCollectionRef = collection(portfolioRef, 'bundles');
      const bundleRef = doc(bundlesCollectionRef, bundle.name);
      await getDoc(bundleRef).then(async (bundleSnapShot) => {
        if (!bundleSnapShot.exists()) {
          await setDoc(bundleRef, {
            bundle,
            totalInvestment: investedValue,
            investedCoins,
            totalIndexToken: indexTokenAmount,
          });
        } else {
          const newInvCoins = bundleSnapShot.data().investedCoins;
          investedCoins.forEach((icoin: any) => {
            const index = newInvCoins.indexOf(
              newInvCoins.find((coin: any) => coin.coin === icoin.coin),
            );
            if (index !== -1) {
              newInvCoins[index].value += icoin.value;
              newInvCoins[index].ait += icoin.ait;
            }
          });
          await updateDoc(bundleRef, {
            totalInvestment: increment(investedValue),
            investedCoins: newInvCoins,
            totalIndexToken: increment(indexTokenAmount),
          });
        }
      });
    });
  },

  addShoppingCartItems: async function (cartItems: CartItem[]) {
    if (auth.currentUser?.uid == null) {
      return null;
    }
    const docRef = doc(firestore, 'users', auth.currentUser.uid);

    await updateDoc(docRef, {
      shoppingCartItems: cartItems,
    }).then(() => {});
  },

  removeFailedTxs: async function (
    walletAddress: string,
    bundleName: string,
    type: string,
  ): Promise<any> {
    if (auth.currentUser === null) {
      console.log('User not authorized');
      return;
    }
    const portfolioRef = doc(firestore, 'portfolio', walletAddress);

    await getDoc(portfolioRef).then(async (docSnapShot) => {
      if (!docSnapShot.exists()) {
        await setDoc(portfolioRef, {
          walletAddress,
        });
      }
      const bundlesCollectionRef = collection(portfolioRef, 'bundles');
      const bundleRef = doc(bundlesCollectionRef, bundleName);
      await getDoc(bundleRef).then(async (bundleSnapShot) => {
        if (!bundleSnapShot.exists()) {
          const txArray = bundleSnapShot.data()?.failedTxs;
          const txToKeep = txArray.filter((entry: any) =>
            type === 'invest' ? entry.value < 0 : entry.value > 0,
          );
          await updateDoc(bundleRef, {
            failedTxs: txToKeep,
          });
        }
      });
    });
  },

  getFailedTxs: async function (walletAddress: string): Promise<any> {
    if (auth.currentUser === null) {
      console.log('User not authorized');
      return;
    }

    const docRef = doc(firestore, 'portfolio', walletAddress) as DocumentReference<IPortfolio>;

    return await new Promise((resolve, reject) => {
      onSnapshot(docRef, (doc) => {
        if (doc.exists()) {
          const bundlesCollectionRef = collection(docRef, 'bundles');

          getDocs(bundlesCollectionRef)
            .then((bundles) => {
              let failedInvs: any[] = [];
              let faileWds: any[] = [];

              bundles.forEach((bundle) => {
                if (bundle.exists()) {
                  const bundleData = bundle.data();

                  if (bundleData.failedCoins?.length > 0) {
                    failedInvs = failedInvs.concat([
                      {
                        bundle: bundleData.bundle.name,
                        coins: bundleData.failedCoins.filter((entry: any) => entry.value > 0),
                      },
                    ]);
                    faileWds = faileWds.concat([
                      {
                        bundle: bundleData.bundle.name,
                        coins: bundleData.failedCoins.filter((entry: any) => entry.value < 0),
                      },
                    ]);
                  }
                }
              });
              resolve({ failedInvs, faileWds });
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve([]);
        }
      });
      // write code to stop listening to changes.
    });
  },
  // getShoppingCartItems: async function (): Promise<ICartItem[]> {
  //   if (auth.currentUser !== null) {
  //     const docRef = doc(firestore, 'users', auth.currentUser.uid)

  //     const cartItems = await getDoc(docRef).then(async (doc): Promise<ICartItem[]> => {
  //       if (doc.exists()) {
  //         const cartItems: ICartItem[] = doc.data().shoppingCartItems;
  //         return cartItems;
  //       } else {
  //         return [];
  //       }
  //     });

  //     return cartItems;
  //   }
  //   return [];
  // },

  getShoppingCartItems: async function (): Promise<CartItem[]> {
    if (auth.currentUser !== null) {
      const docRef = doc(firestore, 'users', auth.currentUser.uid);

      return await new Promise((resolve, reject) => {
        // const unsubscribe =
        onSnapshot(docRef, async (doc) => {
          if (doc.exists()) {
            const cartItems: CartItem[] = doc.data().shoppingCartItems;
            resolve(cartItems);
          } else {
            resolve([]);
          }
        });
        // write code to stop listening to changes.
      });
    } else {
      return [];
    }
  },

  getPortfolio: async function (walletAddress: string): Promise<IPortfolio> {
    if (auth.currentUser !== null) {
      const docRef = doc(firestore, 'portfolio', walletAddress) as DocumentReference<IPortfolio>;

      return await new Promise((resolve, reject) => {
        // const unsubscribe =
        onSnapshot(docRef, (doc: DocumentSnapshot<IPortfolio>) => {
          if (doc.exists()) {
            const bundlesCollectionRef = collection(docRef, 'bundles');

            getDocs(bundlesCollectionRef)
              .then((bundles) => {
                const investments: Investment[] = [];

                let weightedRiskProfile = 0;
                let totalInvestment = 0;

                bundles.forEach((bundle) => {
                  if (bundle.exists()) {
                    const bundleData = bundle.data();
                    totalInvestment += formatTokenBalance(bundleData.totalInvestment as number);

                    investments.push({
                      bundle: bundleData.bundle,
                      initialInvestmentValue: formatTokenBalance(bundleData.totalInvestment),
                      currentInvestmentValue: formatTokenBalance(bundleData.totalInvestment),
                      totalIndexTokenBalance: formatTokenBalance(bundleData.totalIndexToken),
                    });

                    weightedRiskProfile +=
                      formatTokenBalance(bundleData.totalInvestment) *
                      (bundleData.bundle.investor - 1) *
                      50;
                  }
                });

                const portfolio: IPortfolio = {
                  numberOfInvestments: bundles.size,
                  totalInvestedValue: totalInvestment,
                  totalCurrentValue: totalInvestment,
                  performance: 0,
                  averageRiskProfile: weightedRiskProfile / totalInvestment,
                  performancePercentage: 0,
                  investments,
                };

                resolve(portfolio);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve({
              numberOfInvestments: 0,
              totalInvestedValue: 0,
              totalCurrentValue: 0,
              performance: 0,
              averageRiskProfile: 0,
              performancePercentage: 0,
              investments: [],
            });
          }
        });
        // write code to stop listening to changes.
      });
    }

    return await Promise.resolve({
      numberOfInvestments: 0,
      totalInvestedValue: 0,
      totalCurrentValue: 0,
      performance: 0,
      averageRiskProfile: 0,
      performancePercentage: 0,
      investments: [],
    });
  },

  getInvestedCoins: async function (walletAddress: string): Promise<any> {
    if (auth.currentUser !== null) {
      const docRef = doc(firestore, 'portfolio', walletAddress) as DocumentReference<IPortfolio>;

      return await new Promise((resolve, reject) => {
        onSnapshot(docRef, (doc: DocumentSnapshot<IPortfolio>) => {
          if (doc.exists()) {
            const bundlesCollectionRef = collection(docRef, 'bundles');

            getDocs(bundlesCollectionRef)
              .then((bundles) => {
                const investedCoins: any = {
                  Stabilizer: [],
                  Dynamic: [],
                  Growth: [],
                };

                bundles.forEach((bundle) => {
                  if (bundle.exists()) {
                    investedCoins[bundle.data().bundle.name].push(bundle.data().investedCoins);
                  }
                });

                resolve(investedCoins);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            resolve({});
          }
        });
      });
    }
  },

  setPortfolio: async function (walletAddress: string, portfolio: IPortfolio): Promise<void> {
    try {
      const docRef = doc(firestore, 'portfolio', walletAddress);

      await setDoc(docRef, portfolio);

      console.log('Portfolio updated successfully');
    } catch (error) {
      console.error('Error updating portfolio:', error);
      throw error; // Re-throw the error to handle it in the calling code
    }
  },
};
// getPortfolio: async function (walletAddress: string): Promise<IPortfolio> {
// if (auth.currentUser !== null) {
// const docRef = doc(firestore, 'portfolio', walletAddress);
// const docRef: DocumentReference<IPortfolio> = doc(firestore, 'portfolio', walletAddress);

// const portfolio = await getDoc(docRef).then(async (doc): Promise<IPortfolio> => {
// const portfolio = onSnapshot(docRef, async (doc: DocumentSnapshot<IPortfolio>): Promise<IPortfolio> => {
//   if (doc.exists()) {
//     const bundlesCollectionRef = collection(doc.ref, 'bundles');
//     const bundles = await getDocs(bundlesCollectionRef);

//     const investments: IInvestment[] = [];

//     let weightedRiskProfile = 0;
//     let totalInvestment = 0;
//     for (let loopBundles = 0; loopBundles < bundles.docs.length; loopBundles++) {
//       const bundle = bundles.docs[loopBundles];
//       if (bundle.exists()) {
//         totalInvestment += bundle.data().totalInvestment as number;
//         investments.push({
//           bundle: bundle.data().bundle,
//           initialInvestmentValue: bundle.data().totalInvestment,
//           currentInvestmentValue: bundle.data().totalInvestment,
//         });

//         weightedRiskProfile +=
//           bundle.data().totalInvestment * (bundle.data().bundle.investor - 1) * 50;
//       }
//     }
//     return {
//       numberOfInvestments: bundles.size,
//       totalInvestedValue: totalInvestment,
//       totalCurrentValue: totalInvestment,
//       performance: 0,
//       averageRiskProfile: weightedRiskProfile / totalInvestment,
//       performancePercentage: 0,
//       investments,
//     };
//   } else {
//     return {
//       numberOfInvestments: 0,
//       totalInvestedValue: 0,
//       totalCurrentValue: 0,
//       performance: 0,
//       averageRiskProfile: 0,
//       performancePercentage: 0,
//       investments: [],
//     };
//   }
// }, doc.id));

//   return portfolio;
// }
// return {
//   numberOfInvestments: 0,
//   totalInvestedValue: 0,
//   totalCurrentValue: 0,
//   performance: 0,
//   averageRiskProfile: 0,
//   performancePercentage: 0,
//   investments: [],
// };
// },
// };
