import Layout from 'components/Layout/Layout';
import React from 'react';

export const NotFound = (): JSX.Element => {
  return (
    <Layout>
      <div className="mt-5 mx-auto text-center">
        <h1>Not found but we will search for that page</h1>
        <p>
          While we do you can return to our main page
          <a href="/">
            <button className="btn btn-link btn-secondary">by clicking here.</button>
          </a>
        </p>
        <p>Temp page</p>
      </div>
    </Layout>
  );
};
