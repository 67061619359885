import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importing translation files
import homeTranslationEN from './locales/en/home.json';
import homeTranslationNL from './locales/nl/home.json';
import dashboardTranslationEN from './locales/en/dashboard.json';
import dashboardTranslationNL from './locales/nl/dashboard.json';
import portfolioTranslationEN from './locales/en/portfolio.json';
import portfolioTranslationNL from './locales/nl/portfolio.json';
import guidedtourTranslationEN from './locales/en/guidedtour.json';
import guidedtourTranslationNL from './locales/nl/guidedtour.json';

const resources = {
  en: {
    home: homeTranslationEN,
    dashboard: dashboardTranslationEN,
    portfolio: portfolioTranslationEN,
    guidedtour: guidedtourTranslationEN,
  },
  nl: {
    home: homeTranslationNL,
    dashboard: dashboardTranslationNL,
    portfolio: portfolioTranslationNL,
    guidedtour: guidedtourTranslationNL,
  },
};

async function initI18n(): Promise<void> {
  try {
    await i18n.use(initReactI18next).init({
      resources,
      lng: 'en',
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
    });

    console.log('i18n.isInitialized:', i18n.isInitialized);
  } catch (error) {
    console.error('Error initializing i18n:', error);
  }
}

export default initI18n;
