import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
// import feedbackIcon from 'assets/images/feedback-icon.svg';
import helpLinkIcon from 'assets/images/external-link-icon.svg';
import guidedTourIcon from 'assets/images/guided-tour-icon.svg';
import { DashboardContext, type IDashboardContext } from './DashboardContext';
import './DashboardQuestMarkMobileMenu.scss';
import { useTranslation } from 'react-i18next';

interface MobileMenuProps {
  handleShareFeedClick: () => void;
}
export default function DashboardQuestMarkMobileMenu({
  handleShareFeedClick,
}: MobileMenuProps): JSX.Element {
  const { t } = useTranslation('dashboard');
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    if (dashboardContext.startedGuidedTour) event.preventDefault();
  };
  return (
    <div className="menu-item-container">
      <Link
        onClick={handleClick}
        className="menu-item"
        to={`https://fenus-wbsite.netlify.app/help/`}
        // to={`${process.env.REACT_APP_website_domain ?? ''}/help/`}
        target="_blank"
      >
        <div className="item-title">{t('help.helpcenter')}</div>
        <img src={helpLinkIcon} alt="help icon" />
      </Link>

      <Link onClick={handleClick} className="menu-item" to="/guided-tour">
        <div className="item-title">{t('help.guidedtour')}</div>
        <img src={guidedTourIcon} alt="guided tour icon" />
      </Link>
      {/* <div onClick={handleShareFeedClick} className="menu-item">
        <div className="item-title">{t('help.feedback')}</div>
        <img src={feedbackIcon} alt="Feedback icon" />
      </div> */}
    </div>
  );
}
