import { Contract, ethers } from 'ethers';
import { POLYGON_ADDRESSES } from '../blockchain/addresses/Polygon';
import { MAX_AMOUNT_TO_APPROVE } from '../blockchain/constants';
import { coinsConfigs } from '../blockchain/tokensConfig';

export const setAllowance = async (
  signer: ethers.providers.JsonRpcSigner,
  investmentAmount: number,
  tokenToApprove: string,
): Promise<{ approved: boolean; result?: any }> => {
  try {
    const tokenConfig = coinsConfigs[tokenToApprove];
    const erc20 = new Contract(tokenConfig.address, tokenConfig.abi, signer);

    const signerAddress = await signer.getAddress();
    const parsedInvestmentAmount = ethers.utils.parseUnits(investmentAmount.toString(), 18);

    const allowance = await erc20.allowance(
      signerAddress,
      POLYGON_ADDRESSES.ExchangeIssuanceZeroEx,
    );

    if (allowance.lt(parsedInvestmentAmount)) {
      const approveAllowanceTx = await erc20.approve(
        POLYGON_ADDRESSES.ExchangeIssuanceZeroEx,
        MAX_AMOUNT_TO_APPROVE,
      );
      const approveAllowanceResult = await approveAllowanceTx.wait();
      return { approved: true, result: approveAllowanceResult };
    }

    return { approved: true };
  } catch (error) {
    console.error('Error setting allowance:', error);
    throw error;
  }
};
