import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
}; // TODO comment out for local environment

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_apiKey,
//   authDomain: 'fenus-mvp-production.firebaseapp.com',
//   projectId: 'fenus-mvp-production',
//   storageBucket: 'fenus-mvp-production.appspot.com',
//   messagingSenderId: '186068143195',
//   appId: '1:186068143195:web:1ae895ce71d88a5397837e',
// }; // new config (production environment)

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
