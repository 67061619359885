import { ethers } from 'ethers';
import { type Investment, type PortfolioPerformance } from 'types/investment';
import { calculateBundleAvgIndexTokenPrice } from 'utils/calculateBundleAvgIndexTokenPrice';
import { calculatePercentageChange } from 'utils/calculatePercentageChange';
import { calculateIndexTokenBalanceValue } from 'utils/calculateIndexTokenBalanceValue';

export const getPerformanceValues = (
  item: Investment,
  indexTokenPrices: Record<string, number>,
): PortfolioPerformance => {
  const indexTokenPrice = indexTokenPrices[item.bundle.name];
  const averageTokenPrice = calculateBundleAvgIndexTokenPrice(
    item.currentInvestmentValue,
    item.totalIndexTokenBalance,
  );

  if (!item || !indexTokenPrice) {
    return { percentagePerformance: 'N/A', balancePerformance: 'N/A' };
  }

  const totalIndexTokenBalanceBN = ethers.utils.parseUnits(
    item.totalIndexTokenBalance.toString(),
    18,
  );

  const initialInvestedEurAmountBN = ethers.utils.parseUnits(
    item.initialInvestmentValue.toString(),
    18,
  );

  const currentInvestedEurAmount = calculateIndexTokenBalanceValue(
    totalIndexTokenBalanceBN,
    indexTokenPrice,
  );

  const balancePerformance =
    currentInvestedEurAmount !== ethers.constants.Zero
      ? ethers.utils.formatUnits(currentInvestedEurAmount.sub(initialInvestedEurAmountBN), 18)
      : 'N/A';

  const percentagePerformance =
    averageTokenPrice !== ethers.constants.Zero
      ? calculatePercentageChange(indexTokenPrice, averageTokenPrice)
      : 'N/A';

  return { percentagePerformance, balancePerformance };
};
