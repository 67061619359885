import type { SafeEventEmitterProvider } from '@web3auth/base';
import { type Contract, type ContractInterface, ethers, BigNumber } from 'ethers';
import { type WalletProvider } from '../types/wallet';
import { TransactionState } from 'constants/TransactionState';
import { type TransactionResponse } from 'types/transaction';

const ethProvider = (provider: SafeEventEmitterProvider): WalletProvider => {
  const getAccounts = async (): Promise<string | undefined> => {
    try {
      const ethersProvider = new ethers.providers.Web3Provider(provider);
      const signer = ethersProvider.getSigner();

      // Get user's Ethereum public address
      const address = await signer.getAddress();
      return address;
    } catch (error) {
      console.error('Error', error);
    }
  };

  const getSigner: () => any = () => {
    const ethersProvider = new ethers.providers.Web3Provider(provider as any);
    const signer = ethersProvider.getSigner();

    return signer;
  };

  const getAddress = async (): Promise<string> => {
    try {
      // const ethersProvider = new ethers.BrowserProvider(provider as any);
      const ethersProvider = new ethers.providers.Web3Provider(provider);

      const signer = ethersProvider.getSigner();

      // Get user's Ethereum public address
      const address = await signer.getAddress();
      return address;
    } catch (error: any) {
      console.log(error);
      return error.toString();
    }
  };

  const getBalance = async (): Promise<string | undefined> => {
    try {
      const ethersProvider = new ethers.providers.Web3Provider(provider);
      const signer = ethersProvider.getSigner();

      // Get user's Ethereum public address
      const address = await signer.getAddress();

      // Get user's balance in ether
      const balance = ethers.utils.formatEther(
        await ethersProvider.getBalance(address), // Balance is in wei
      );

      return balance;
    } catch (error) {
      console.error('Error', error);
    }
    return undefined;
  };

  const signMessage = async (message: string): Promise<string | null> => {
    try {
      const ethersProvider = new ethers.providers.Web3Provider(provider);
      const signer = ethersProvider.getSigner();

      // Sign the message
      const signature = await signer.signMessage(message);
      return signature; // Return the signature
    } catch (error) {
      console.log('error', error);
      return null;
    }
  };

  let nonceOffset = 0;

  async function getNonce(): Promise<number> {
    const ethersProvider = new ethers.providers.Web3Provider(provider as any);
    const signer = ethersProvider.getSigner();

    // Get user's Ethereum public address
    const address = await signer.getAddress();
    const baseNonce = ethersProvider.getTransactionCount(address);
    return await baseNonce.then((nonce: number) => nonce + nonceOffset++);
  }

  async function listenForTransactionMine(
    transactionResponse: ethers.providers.TransactionResponse,
    provider: ethers.providers.Web3Provider,
  ): Promise<ethers.providers.TransactionReceipt> {
    console.log(`Mining ${transactionResponse.hash}`);
    return await new Promise<ethers.providers.TransactionReceipt>((resolve, reject) => {
      provider.once(transactionResponse.hash, (transactionReceipt) => {
        resolve(transactionReceipt);
      });
    });
  }

  const signAndSendTransaction = async (
    transaction: ethers.providers.TransactionRequest,
  ): Promise<TransactionResponse> => {
    try {
      const ethersProvider = new ethers.providers.Web3Provider(provider as any);
      const signer = ethersProvider.getSigner();
      transaction.nonce = await getNonce();
      const tx = await signer.sendTransaction(transaction);
      // const txRes = await tx.wait();
      const txRes = await listenForTransactionMine(tx, ethersProvider);
      console.log(txRes);

      if (txRes !== undefined) {
        return {
          status: TransactionState.Sent,
          transactionHash: txRes.transactionHash,
          transaction,
        };
      } else {
        return {
          status: TransactionState.Failed,
          transactionHash: '',
          transaction,
        };
      }
    } catch (error) {
      console.log('error', error);
      return {
        status: TransactionState.Failed,
        transactionHash: '',
        transaction,
      };
    }
  };

  const signTransaction = async (
    transaction: ethers.providers.TransactionRequest,
  ): Promise<string> => {
    try {
      const ethersProvider = new ethers.providers.Web3Provider(provider as any);
      const signer = ethersProvider.getSigner();

      if (transaction.value !== undefined) {
        transaction.value = BigNumber.from(transaction.value);
      }

      const txRes = await signer.signTransaction(transaction);

      return txRes;
    } catch (error) {
      console.log('error', error);
      return TransactionState.Failed;
    }
  };

  const getContract = (addressOrName: string, contractInterface: ContractInterface): Contract => {
    const ethersProvider = new ethers.providers.Web3Provider(provider as any);
    return new ethers.Contract(addressOrName, contractInterface, ethersProvider);
  };

  const call = async (
    transaction: ethers.utils.Deferrable<ethers.providers.TransactionRequest>,
    blockTag?: ethers.providers.BlockTag | Promise<ethers.providers.BlockTag> | undefined,
  ): Promise<string> => {
    const ethersProvider = new ethers.providers.Web3Provider(provider as any);
    return await ethersProvider.call(transaction, blockTag);
  };

  return {
    getAccounts,
    getSigner,
    getAddress,
    getBalance,
    signMessage,
    signAndSendTransaction,
    signTransaction,
    getContract,
    call,
  };
};

export default ethProvider;
