import React, { useEffect, useState, useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useWeb3Auth } from 'services/web3auth';
import { DashboardContext, type IDashboardContext } from '../Dashboard/DashboardContext';
import { moneriumAuthAF } from 'services/monerium';

import '../SelectProfilePopup.scss';
import { useTranslation } from 'react-i18next';

export const MoneriumNotAuthedPopup: any = () => {
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const { provider } = useWeb3Auth();
  const [signature, setSignature] = useState<any>(null);
  const { t } = useTranslation('dashboard');

  useEffect(() => {
    const getttingSignature: any = async () => {
      if (provider === null) return;

      const signature = await provider?.signMessage(
        'I hereby declare that I am the address owner.',
      );
      setSignature(signature);
      console.log('signature for monerium:', signature);
      // provider
      //   ?.signMessage('I hereby declare that I am the address owner.')
      //   .then((signature) => {
      //     setSignature(signature); getSignature
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
    };
    getttingSignature();
  }, [provider]);

  const handleClose: any = (): void => {
    dashboardContext.setShowMoneriumNotAuthedPopup(false);
  };

  const handleAuth: any = async () => {
    await moneriumAuthAF(dashboardContext.account, signature);
    dashboardContext.setShowMoneriumNotAuthedPopup(false);
  };

  return (
    <>
      <Modal
        onHide={handleClose}
        show={dashboardContext.showMoneriumNotAuthedPopup}
        animation={false}
        className="select-profile-modal not-authed-modal"
        centered
      >
        <Modal.Header>
          <Modal.Title>{t('monerium.header')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('monerium.content')}</p>
          <p style={{ marginTop: '-1rem' }}>{t('monerium.content2')}</p>
          <p>
            {t('monerium.terms1')}{' '}
            <a
              style={{ color: '#71fecf' }}
              href="https://monerium.com/policies/business-terms-of-service/"
              target="_blank"
              rel="noreferrer"
            >
              {t('monerium.terms2')}
            </a>
          </p>
          <p className="not-authed-grey">{t('monerium.note')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="w-100 not-authed-button" onClick={handleAuth}>
            {t('monerium.btn')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
