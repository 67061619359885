import React, { useEffect, useMemo, useState, useContext } from 'react';

import type { BundleData } from 'types';
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './Bundle.scss';

import chart from 'assets/images/bundle-chart-icon.svg';
import coins from 'assets/images/bundle-coins-icon.svg';
import help from 'assets/images/bundle-help-icon.svg';
import openIcon from 'assets/images/open-icon.svg';
import closeIcon from 'assets/images/close-icon.svg';
import { BundleInvest } from './BundleInvest';
import { CoinDisplay } from './CoinDisplay';
import { BundlePerformance } from './BundlePerformance';
import { BundleFAQ } from './BundleFAQ';
import { BundleCoins } from './BundleCoins';
import { BundleCoinDetails } from './BundleCoinDetails';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
// import PriceData from 'services/priceData';
// import { formatValue } from 'react-currency-input-field';
import { type IDashboardContext, DashboardContext } from 'components/Dashboard/DashboardContext';
import { usePriceData } from 'context/priceDataContext';
import { show3YearsPerformaceText } from 'config/featureFlags';

interface Props {
  badgeName: string;
  badgeColor: string;
  bundle: BundleData;
  activeBundle: BundleData | undefined;
  setActiveBundle: any;
}

export const Bundle = (props: Props): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const [expanded, setExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const [activeCoin, setActiveCoin] = useState({});
  const [readMore, setReadMore] = useState(false);
  // const [priceDataLoaded, setPriceDataLoaded] = useState(false);
  // const [performanceValue, setPerformanceValue] = useState(0); //TODO what is this doing?
  const bundle = props.bundle;
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const { getPerformance } = usePriceData();

  const { scrollToItem, getItemElementById } = React.useContext(VisibilityContext);

  const showCoinDetails = (key: string, coin: any, percentage: number): void => {
    setActiveCoin({
      coin,
      percentage,
    });
    setActiveTab(key);
  };

  const handleOpen = (key: string): void => {
    if (expanded && activeTab === key) {
      setExpanded(false);
    } else {
      setExpanded(true);
      setActiveTab(key);
      props.setActiveBundle(bundle);
      setTimeout(() => {
        scrollToItem(getItemElementById(bundle.name) as Element);
      }, 100);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!expanded) {
      setActiveTab('');
    }
  }, [expanded]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (props.activeBundle && props.activeBundle.name !== bundle.name) {
      setExpanded(false);
      setActiveTab('');
    }
  }, [props.activeBundle]);

  useMemo(() => {
    const getChartData = async (): Promise<void> => {
      const fetchedPerformance = await getPerformance(props.bundle);
      props.bundle.performance = fetchedPerformance;
      // setPerformanceValue(fetchedPerformance); //TODO what is this doing?
      // setPriceDataLoaded(true);
    };

    getChartData().catch(console.error);
  }, []);

  // const getPerformanceFigures = (): string => {
  //   const prefix = performanceValue >= 0 ? '+' : '';
  //   return prefix + `${performanceValue?.toFixed(2)}`;
  // };

  return (
    <div className={`${expanded ? 'expanded' : ''} d-inline-block invest-bundle-wrapper`}>
      <Tab.Container id="left-tabs-example" defaultActiveKey="first" activeKey={activeTab}>
        <Container className="invest-bundle">
          <Row>
            <Col>
              <Nav className="flex-column float-end mt-2">
                <Nav.Item>
                  <Nav.Link
                    eventKey="first"
                    // style={{ display: 'none' }}
                    onClick={() => {
                      if (!dashboardContext.showGuidedTour) {
                        handleOpen('first');
                      }
                    }}
                  >
                    <img src={chart} alt="Chart" width={20} height={20} />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="second"
                    className={`${activeTab === 'fourth' ? 'active' : ''}`}
                    onClick={() => {
                      if (!dashboardContext.showGuidedTour) {
                        handleOpen('second');
                      }
                    }}
                  >
                    <img src={coins} alt="Coins" width={20} height={20} />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    eventKey="third"
                    onClick={() => {
                      if (!dashboardContext.showGuidedTour) {
                        handleOpen('third');
                      }
                    }}
                  >
                    <img src={help} alt="Help" width={20} height={20} />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <div>
                <div className="badge" style={{ backgroundColor: props.badgeColor }}>
                  {props.badgeName}
                </div>
                <h4>{bundle.name}</h4>
                <img
                  src={bundle.image}
                  alt={bundle.name}
                  className="mx-auto d-block bundle-image img-fluid"
                />
                <div className="d-flex">
                  <div className={`${readMore ? '' : 'collapsed'} flex-grow-1 bundle-description`}>
                    {bundle.shortDescription}
                  </div>
                  <div className="align-self-center">
                    <a
                      href="#"
                      className="read-more"
                      onClick={() => {
                        if (!dashboardContext.showGuidedTour) {
                          setReadMore(!readMore);
                        }
                      }}
                    >
                      {readMore ? (
                        <img src={closeIcon} alt="close" width={15} height={7.5} />
                      ) : (
                        <img src={openIcon} alt="open" width={15} height={7.5} />
                      )}
                    </a>
                  </div>
                </div>
                {show3YearsPerformaceText && <p className="performance">{t('bundles.3years')}</p>}
                <div className="d-flex justify-content-center">
                  <div className="performance-figures">
                    {/* {priceDataLoaded ? getPerformanceFigures() : '--'}% */}
                    {/* {priceDataLoaded ? `+${performanceValue}` : '--'}% */}
                    {/* props.bundle.performance */}
                  </div>
                  <CoinDisplay coins={bundle.coins} coinSize={32} />
                </div>
                <hr />
                <BundleInvest bundle={bundle} />
              </div>
            </Col>
            <Col className={`${expanded ? '' : 'd-none'} bundle-details`}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <BundlePerformance
                    bundle={bundle}
                    setExpanded={setExpanded}
                    activeTab={activeTab}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <BundleCoins
                    showCoinDetails={showCoinDetails}
                    coins={bundle.coins}
                    setExpanded={setExpanded}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <BundleFAQ bundle={bundle} setExpanded={setExpanded} />
                </Tab.Pane>
                <Tab.Pane eventKey="fourth">
                  <BundleCoinDetails
                    activeCoin={{ ...activeCoin, Price: 20 }}
                    handleOpen={handleOpen}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Container>
      </Tab.Container>
    </div>
  );
};
