import Layout from 'components/Layout/Layout';
import { useTranslation } from 'react-i18next';
import logo from 'assets/images/fenus_logo_no_text.svg';
import useBodyClass from 'hooks/useBodyClass';
import { useNavigate } from 'react-router-dom';
import { sendSignInLinkToEmail } from 'firebase/auth';
import { auth } from 'config/firebaseConfig';

import './EmailLink.scss';

export const EmailLink = (): JSX.Element => {
  const email = window.localStorage.getItem('emailForSignIn');
  const { t } = useTranslation('home');

  const navigate = useNavigate();

  const logInWithEmail = async (email: any): Promise<void> => {
    console.log('logInWithEmail');

    try {
      const actionCodeSettings = {
        url: (process.env.REACT_APP_domain as string) + '/email-login',
        // url: 'http://localhost:3000/email-login',
        // url: "http://fenus-academy.netlify.app/email-login",
        handleCodeInApp: true,
      };

      sendSignInLinkToEmail(auth, email, actionCodeSettings)
        .then(() => {
          console.log('link sent');

          window.localStorage.setItem('emailForSignIn', email);

          navigate('/email-link');
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err: any) {
      console.error(err);
      alert(err.message);
    }
  };

  const isEmail = (email: string): boolean =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const handleEmailLogin = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    if (email !== null && email !== undefined && email.trim() !== '') {
      try {
        event.preventDefault();
        if (isEmail(email)) {
          await logInWithEmail(email);
        } else {
          // setError(true);
        }
      } finally {
        /* empty */
      }
    }
  };

  useBodyClass(['start-body']);
  return (
    <Layout>
      <div className="mx-auto text-center email-link">
        <div className="logo d-none d-md-block">
          <img src={logo} alt="Fenus logo" width={29} height={35} />
        </div>
        <h1>{t('emailLink.header')}</h1>
        <p>
          {t('emailLink.content1')} <strong>{email}</strong> {t('emailLink.content2')}
        </p>
        <button className="btn btn-secondary w-100" onClick={handleEmailLogin}>
          {t('emailLink.resend')}
        </button>
        <p className="help">
          {t('emailLink.help1')} <a href="/help">{t('emailLink.help2')}</a>
        </p>
      </div>
    </Layout>
  );
};
