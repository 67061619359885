import DashboardLayout from 'components/Dashboard/Layout/DashboardLayout';
import { PortfolioOverview } from 'components/Dashboard/PortfolioOverview';

import useBodyClass from 'hooks/useBodyClass';
import { DashboardBundles } from 'components/Dashboard/DashboardBundles';
import { DashboardMobileBundles } from 'components/Dashboard/DashboardMobileBundles';
import { useContext, useEffect } from 'react';
import { type IDashboardContext, DashboardContext } from 'components/Dashboard/DashboardContext';
import { useWeb3Auth } from 'services/web3auth';
import { UserServices } from 'services/UserServices';
import { useTranslation } from 'react-i18next';
import { useGetUserIndexTokenBalance } from 'hooks/useGetUserIndexTokenBalance';
import { formatTokenBalance } from 'utils/formatTokenBalance';
import { ethers } from 'ethers';
import { BASE_COIN } from '../blockchain/constants';

export const Dashboard = (): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);

  // const [account, setAccount] = useState('');
  const { provider, getAddress, wb3athProvider } = useWeb3Auth();
  const ethersProvider = new ethers.providers.Web3Provider(wb3athProvider);

  useEffect(() => {
    if (dashboardContext.account !== '') {
      const signer = ethersProvider.getSigner(dashboardContext.account);
      const fetchBal = useGetUserIndexTokenBalance(BASE_COIN, dashboardContext.account, signer);
      fetchBal.then((res) => {
        dashboardContext.setBalance(formatTokenBalance(Number(res?.toString())) ?? 0);
      });
    }
  }, [dashboardContext.account]);

  useEffect(() => {
    // declare the data fetching function

    const getAccountDetails = async (): Promise<void> => {
      try {
        if (provider !== null) {
          // Load Shopping cart items
          const items = await UserServices.getShoppingCartItems();
          if (items !== undefined) {
            dashboardContext.setShoppingCartItems(items);
          } else {
            dashboardContext.setShoppingCartItems([]);
          }

          const address = await getAddress();
          dashboardContext.setAccount(address);
          let tempAccount = '';
          tempAccount = address;

          // const accounts = await getAccounts();
          // let tempAccount = '';

          // if (Array.isArray(accounts)) {
          //   dashboardContext.setAccount(accounts[0]);
          //   // setAccount(accounts[0]);
          //   tempAccount = accounts[0];
          // } else {
          //   dashboardContext.setAccount(accounts);
          //   // setAccount(accounts);
          //   tempAccount = accounts;
          // }

          // Load portfolio

          const savedPortfolio = await UserServices.getPortfolio(tempAccount);
          if (savedPortfolio !== null) {
            dashboardContext.setPortfolio(savedPortfolio);
          }

          const { failedInvs, faileWds } = await UserServices.getFailedTxs(tempAccount);
          console.log('failedTxs from tempAccount:', failedInvs, faileWds);
          dashboardContext.setFailedInvestments(failedInvs);
          dashboardContext.setFailedWithdrawals(faileWds);
        } else {
          /* empty */
        }
      } finally {
        /* empty */
      }
    };

    // call the function
    getAccountDetails().catch(console.error);
  }, [provider]);

  useBodyClass(['dashboard-body']);
  return (
    <>
      <DashboardLayout headerText={t('header.dashboard')}>
        <div className="d-none d-md-block">
          <PortfolioOverview showHeader={true} />
          <DashboardBundles />
        </div>
        <div className="d-md-none d-block">
          <DashboardMobileBundles />
        </div>

        {/* <HelpCenterIFrame /> */}
      </DashboardLayout>
    </>
  );
};
