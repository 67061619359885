import React, { useCallback, useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import uploadIcon from 'assets/images/upload-icon.svg';
import { DashboardContext, type IDashboardContext } from 'components/Dashboard/DashboardContext';
import toastMessageIcon from 'assets/images/message-toast.svg';
import cancel from 'assets/images/cancel.svg';
import { useNavigate } from 'react-router-dom';
import { storage, firestore } from '../../config/firebaseConfig';
import { collection, addDoc } from 'firebase/firestore';

import { toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import './ShareFeedbackForm.scss';
import TermsAndCondition from './TermsAndCondition';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useTranslation } from 'react-i18next';

interface IData {
  ticketNumber: string;
  email: string;
  message: string;
  topic: string;
  checkboxValues: any;
  downloadUrl?: string;
}

export default function ShareFeedbackForm(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const isSmallScreen = useMediaQuery({ maxWidth: 768 });
  dashboardContext.setShowShareFeedbackPopup(!isSmallScreen);

  const [email, setEmail] = useState('');
  const [topic, setTopic] = useState('');
  const [message, setMessage] = useState('');
  const [checkboxValues, setCheckboxValues] = useState({
    ParticipateInProductResearch: true,
    ReceiveEmailUpdates: true,
  });
  const [image, setImage] = useState<File | null | any>([]);
  const [submitting, setSubmitting] = useState(false);
  const [showTermsConditon, setShowTermsCondition] = useState(false);

  const resetForm = (): void => {
    setEmail('');
    setTopic('');
    setMessage('');
    setImage([]);
  };
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = event.target;
    setCheckboxValues({
      ...checkboxValues,
      [name]: checked,
    });
  };

  // Add new images
  // const onDrop = useCallback((acceptedFiles: File[]) => {
  //   setImage((prevImages: File[]) =>
  //     prevImages.concat(
  //       acceptedFiles.map((file) =>
  //         Object.assign(file, {
  //           preview: URL.createObjectURL(file),
  //         }),
  //       ),
  //     ),
  //   );
  // }, []);

  // Accept only one Image
  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (image.length > 0) {
      setImage([]);
    }

    const file = acceptedFiles[0];
    setImage([Object.assign(file, { preview: URL.createObjectURL(file) })]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
  });
  function shortenName(fileName?: string | null): string {
    const maxLength = 4;

    if (fileName !== undefined && fileName !== null && fileName.length <= maxLength) {
      return fileName;
    }
    const shortName = (fileName?.substring(0, maxLength) ?? '') + '...';
    const extension = fileName?.split('.').pop() ?? '';
    return shortName + extension;
  }

  const thumbs = image.map((file: any) => (
    <React.Fragment key={file.name}>
      <div className="image-container">
        <img className="uploaded-img" src={URL.createObjectURL(file)} alt={file.name} />
        <img
          className="remove-icon"
          src={cancel}
          alt="cancel icon"
          onClick={() => {
            removeImage(file.name);
          }}
        />
        <p>{shortenName(file.name)}</p>
      </div>
    </React.Fragment>
  ));

  const removeImage = (fileNameToRemove: string): void => {
    const updatedImages = image.filter((file: any) => file.name !== fileNameToRemove);
    setImage(updatedImages);
  };

  async function getTicketNumber(): Promise<string> {
    try {
      // const response = await fetch(url);
      const response = await fetch('https://getticketnumber-qfmxwrvf7q-uc.a.run.app', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      // const response = await fetch('http://127.0.0.1:5001/fenus-mvp/us-central1/getSequence');
      const data = await response.text();
      return data;
    } catch (error: any) {
      throw new Error(`Error fetching data`);
    }
  }

  async function uploadImage(ticketNumber: string): Promise<string> {
    return await new Promise((resolve, reject) => {
      const file = image[0];
      if (file !== null) {
        const reader = new FileReader();
        reader.onload = async () => {
          if (reader.result !== null) {
            const arrayBuffer = new Uint8Array(reader.result as ArrayBuffer);
            const imageRef = ref(storage, `/feedback-tickets/${ticketNumber}`);
            const imageSnapshot = uploadBytesResumable(imageRef, arrayBuffer);

            imageSnapshot.on(
              'state_changed',
              (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(`Upload is ${progress}% done`);
              },
              (error) => {
                console.error('Upload error', error);
                reject(error);
              },
              async () => {
                try {
                  const downloadURL = await getDownloadURL(imageRef);
                  resolve(downloadURL);
                } catch (error) {
                  console.log('Download URL not available yet');
                  reject(error);
                }
              },
            );
          }
        };
        reader.readAsArrayBuffer(file);
      }
    });
  }

  async function uploadData(data: IData): Promise<void> {
    const feedbackCollection = collection(firestore, 'ticket-feedback');
    try {
      const docRef = await addDoc(feedbackCollection, data);
      console.log('uploaded successfully', docRef);
    } catch (error) {
      console.log(error);
    }
  }
  const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setSubmitting(true);
    try {
      const ticketNumber = await getTicketNumber();
      console.log('ticketNumber:', ticketNumber);
      if (ticketNumber !== '' && image.length > 0) {
        try {
          const downloadURL = await uploadImage(ticketNumber);
          const dataToUpload = {
            email,
            message,
            topic,
            checkboxValues,
            ticketNumber,
            imageDownloadUrl: downloadURL,
          };
          await uploadData(dataToUpload);
        } catch (error) {
          console.log('Error getting download URL:', error);
        }
      } else if (ticketNumber !== '' && image.length === 0) {
        const dataToUpload = {
          email,
          message,
          topic,
          checkboxValues,
          ticketNumber,
        };
        await uploadData(dataToUpload);
      }

      const formData = new FormData() as any;
      formData.append('ticketNumber', ticketNumber);
      formData.append('message', message);
      if (email !== '') {
        formData.append('email', email);
      }
      const bodyData = {
        ticketNumber,
        message,
        email: email !== '' ? email : undefined,
      };
      if (ticketNumber !== null) {
        await fetch('/.netlify/functions/hello', {
          method: 'post',
          headers: {
            'Content-Type': 'application/json', // Set content type to JSON
          },
          body: JSON.stringify(bodyData), // Convert object to JSON string
        })
          .then(async (data) => {
            if (data.ok) {
              toast.dismiss();
              toast.info(
                <div> Thank you! You’ve successfully submitted your feedback to us!</div>,
                {
                  autoClose: 3000,
                  icon: () => <img src={toastMessageIcon} />,
                },
              );
              resetForm();
              dashboardContext.setShowShareFeedbackPopup(false);
              navigate('/dashboard');
            } else {
              throw new Error('not ok ');
            }
          })
          .catch((error: any) => {
            alert(error);
            setSubmitting(false);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {showTermsConditon && (
        <TermsAndCondition
          extraClass="termsClass"
          handleBackClick={() => {
            setShowTermsCondition(false);
          }}
        />
      )}
      <form
        className="share-feedback-form"
        name="Share Feedback"
        method="POST"
        data-netlify="true"
        netlify-honeypot="pomodoro"
        onSubmit={async (event) => {
          await handleFormSubmit(event);
        }}
      >
        <h2 className="feedback-header mb-4">{t('feedback.header')}</h2>

        <label className="input-label" htmlFor="email">
          {t('feedback.email')}
        </label>
        <input
          className=" width-height-border email-input margin-bottom"
          type="text"
          name="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <label className="input-label" htmlFor="topic">
          {t('feedback.iwant')}...*
        </label>
        <select
          className="width-height-border topic-input margin-bottom"
          name="topic"
          value={topic}
          onChange={(e) => {
            setTopic(e.target.value);
          }}
          required
        >
          <option value="">{t('feedback.select')}</option>
          <option value="Report an issue">{t('feedback.report')}</option>
          <option value="Suggest new functionalities">{t('feedback.suggest')}</option>
          <option value="Leave a comment">{t('feedback.comment')}</option>
        </select>
        <label className="input-label" htmlFor="description">
          {t('feedback.description')}*
        </label>
        <textarea
          className="description margin-bottom"
          id="message"
          name="message"
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
        ></textarea>
        {topic === 'Report an issue' && (
          <>
            <label className=" upload-label input-label" htmlFor="upload-image">
              {t('feedback.upload')}* <br />
              <span>{t('feedback.screenshot')}</span>
            </label>
            <div className="image-box margin-bottom mb-3">
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="upload-paragraph d-flex flex-column align-items-center">
                  <img src={uploadIcon} alt="upload icon" />
                  <p>{t('feedback.dragndrop')}</p>{' '}
                </div>
              </div>
            </div>
          </>
        )}
        <p className="terms-condition-txt">
          {t('feedback.terms1')}{' '}
          <span
            onClick={() => {
              setShowTermsCondition(true);
            }}
          >
            {t('feedback.terms2')}
          </span>
          .
        </p>
        <div className="checkbox-container mb-3">
          <label className="checkbox-label">
            <input
              type="checkbox"
              name="ParticipateInProductResearch"
              onChange={handleCheckboxChange}
              checked={checkboxValues.ParticipateInProductResearch}
            />
            <div className="pseudo-input">✓</div>
            <span>{t('feedback.research')}</span>
          </label>
          <label className="checkbox-label">
            <input
              type="checkbox"
              name="ReceiveEmailUpdates"
              onChange={handleCheckboxChange}
              checked={checkboxValues.ReceiveEmailUpdates}
            />
            <div className="pseudo-input">✓</div>
            <span>{t('feedback.updates')}</span>
          </label>
        </div>
        {image !== null && <div className="thumbs-container">{thumbs}</div>}
        <div className=" d-flex flex-row justify-content-end feedback-button-container ">
          <button
            className="cancel-btn btn "
            onClick={() => {
              dashboardContext.setShowShareFeedbackPopup(false);
              // for mobile page when browser size reduces from mobile to desktop and back to mobile
              dashboardContext.setShowShareFeedbackForm(false);
            }}
          >
            {t('feedback.cancel')}
          </button>
          <button
            disabled={topic === '' || message === '' || submitting}
            type="submit"
            className="submit-btn btn"
          >
            {t('feedback.submit')}
          </button>
        </div>
      </form>
    </>
  );
}
