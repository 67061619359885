import Layout from 'components/Layout/Layout';
import React, { useEffect, useState } from 'react';
// import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import './WelcomeVideo.scss';
import { useNavigate } from 'react-router-dom';

export const WelcomeVideo = (): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const navigate = useNavigate();
  const [showButtons, setShowButtons] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowButtons(true);
    }, 5000);
    return () => {
      clearTimeout(timer);
    };
  }, [setShowButtons]);

  return (
    <Layout>
      <div className="welcome-video">
        <h1 className="mx-auto text-center d-block d-md-none mb-4">{t('welcome.header')}</h1>
        <h1 className="mx-auto text-center d-none d-md-block mb-4">{t('welcome.header')}</h1>
        <div className="welcome-text-container d-none d-md-block mb-5">
          <div className=" welcome-text mx-auto text-center">{t('welcome.content1')}</div>
          <div className="welcome-text mx-auto text-center">{t('welcome.content2')}</div>
        </div>
        <div className="mx-auto text-center player-wrapper">
          {/* <ReactPlayer
            className="react-player"
            width="100%"
            height="100%"
            playing={true}
            light={true}
            controls={true}
            url="assets/videos/welcoming-video-en.mp4"
            // url="https://www.youtube.com/watch?v=C0DPdy98e4c"
          /> */}
          <video
            className="react-player"
            width="100%"
            height="100%"
            autoPlay={true}
            controls={true}
            src={`/videos/welcoming-video-${i18n.language === 'en' ? 'en' : 'nl'}.mp4`}
          ></video>
        </div>
        <div id="next-step-btns" className={`d-none d-md-block ${showButtons ? '' : 'btn-hide'}`}>
          <div className="">{t('welcome.question')}</div>
          <button
            className={`btn btn-secondary`}
            onClick={() => {
              navigate('/dashboard');
            }}
          >
            {t('welcome.skip')}
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              navigate('/guided-tour');
            }}
          >
            {t('welcome.letsgo')}
          </button>
        </div>

        <h2 className="mx-auto text-initial d-block d-md-none">
          {t('welcome.content1')}
          {t('welcome.content2')}
        </h2>
        <div className="mobile-buttons d-md-none">
          <div style={{ flex: '19%', marginRight: '2%' }}>
            <button
              className="btn btn-outline-secondary  w-100"
              onClick={() => {
                navigate('/dashboard');
              }}
              disabled={!showButtons}
            >
              {t('welcome.skip')}
            </button>
          </div>
          <div style={{ flex: '70%' }}>
            <button
              className={`btn btn-primary w-100`}
              onClick={() => {
                navigate('/guided-tour');
              }}
              disabled={!showButtons}
            >
              {t('welcome.tour')}
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};
