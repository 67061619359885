/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import './DashboardSidebar.scss';
import { WalletOverview } from './WalletOverview';
import { DashboardContext, type IDashboardContext } from './DashboardContext';
import { useTranslation } from 'react-i18next';

import dashboard from 'assets/images/dashboard-icon.svg';
import portfolio from 'assets/images/my-portfolio-icon.svg';
import logoutIcon from 'assets/images/logout-icon.svg';

export const DashboardSidebar = (): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    if (dashboardContext.startedGuidedTour) event.preventDefault();
  };

  return (
    <div className="d-flex flex-column dashboard-sidebar">
      <WalletOverview />
      <NavLink
        onClick={handleClick}
        id="dashboard-link"
        className="menu d-flex align-items-center"
        to="/dashboard"
      >
        <img src={dashboard} width={18} height={18} alt="Dashboard" />
        <span>{t('sidebar.dashboard')}</span>
      </NavLink>
      <NavLink
        onClick={handleClick}
        id="my-portfolio-link"
        className="menu d-flex align-items-center"
        to="/portfolio"
      >
        <img src={portfolio} width={16} height={20} alt="My Portfolio" />
        <span>{t('sidebar.portfolio')}</span>
      </NavLink>
      {/* <NavLink
        onClick={handleClick}
        id="help-center-link"
        className="menu d-flex align-items-center"
        to={`${process.env.REACT_APP_website_domain}/help/`}
        target="_blank"
      >
        <img src={help} width={18} height={19} alt="Help" />
        <span className="flex-grow-1">{t('sidebar.help')}</span>
        <img src={externalLinkIcon} width={18} height={18} alt="Help external link" />
      </NavLink> */}

      <NavLink id="my-help-link" className="d-none" to="/guided-tour-support">
        <img src={portfolio} width={16} height={20} alt="My Portfolio" />
        <span>Embedded Help page</span>
      </NavLink>

      <div
        className="menu d-flex align-items-center logout mt-auto"
        onClick={() => {
          dashboardContext.setShowLogout(true);
        }}
      >
        <img src={logoutIcon} width={18} height={19} alt="Log out" />
        <span>{t('sidebar.logout')}</span>
      </div>
    </div>
  );
};
