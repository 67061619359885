import backIcon from 'assets/images/bundle-back-icon.svg';
// import { useEthPrices } from 'hooks/useEthPrices';
import './BundleCoinDetails.scss';
import React, { useContext, useEffect, useState } from 'react';
import { type IDashboardContext, DashboardContext } from '../Dashboard/DashboardContext';

import { InfoTooltip } from 'components/InfoTooltip';
// import { formatValue } from 'react-currency-input-field';
import { useTranslation } from 'react-i18next';
import { convertCurrency } from '../../utils/currencyUtils';

interface Props {
  activeCoin: any;
  handleOpen: any;
}

// interface TokenFields {
//   id: string;
//   symbol: string;
//   name: string;
//   derivedETH: string;
//   volumeUSD: string;
//   volume: string;
//   feesUSD: string;
//   txCount: string;
//   totalValueLocked: string;
//   totalValueLockedUSD: string;
// }

const truncate = (inputString: string): string => {
  return inputString.length > 210 ? inputString.substring(0, 200) + '...' : inputString;
};

export const BundleCoinDetails = (props: Props): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const coin = props.activeCoin;
  const [expandDetails, setExpandDetails] = useState(false);
  // const [displayCoinMarketCap, setDisplayCoinMarketCap] = useState(0);
  // const [shortenMarketCap, setShortenMarketCap] = useState('');
  const [displayCoinPrice, setDisplayCoinPrice] = useState(0);
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);

  // useEffect(() => {
  //   if (coin?.coin !== undefined) {
  //     // setDisplayCoinMarketCap(coin.coin.marketData.market_cap);
  //     // setShortenMarketCap((coin.coin.marketData.market_cap * 10 ** -9).toFixed(1) + 'B');
  //   }
  // }, [coin]);

  useEffect(() => {
    if (coin?.coin !== undefined) {
      if (dashboardContext.currency === 1) {
        convertCurrency(coin.coin.marketData.market_cap, 'EUR', 'USD')
          .then((convertAmount) => {
            if (convertAmount !== null) {
              // setDisplayCoinMarketCap(convertAmount);
              // setShortenMarketCap((convertAmount * 10 ** -9).toFixed(1) + 'B');
            }
          })
          .catch((error) => {
            console.log(error);
          });
        setDisplayCoinPrice(coin.coin.marketData.price);
      }
      if (dashboardContext.currency === 2) {
        convertCurrency(coin.coin.marketData.market_cap, 'USD', 'EUR')
          .then((convertAmount) => {
            if (convertAmount !== null) {
              // setDisplayCoinMarketCap(convertAmount);
              // setShortenMarketCap((convertAmount * 10 ** -9).toFixed(1) + 'B');
            }
          })
          .catch((error) => {
            console.log(error);
          });
        convertCurrency(coin.coin.marketData.price, 'USD', 'EUR')
          .then((convertAmount) => {
            if (convertAmount !== null) {
              setDisplayCoinPrice(convertAmount);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [coin, dashboardContext.currency]);

  // const ethPrices = useEthPrices();

  // const parsed = data?.tokens
  //   ? data.tokens.reduce((accum: { [address: string]: TokenFields }, poolData) => {
  //       accum[poolData.id] = poolData
  //       return accum
  //     }, {})
  //   : {}

  // if (ethPrices != null) {
  //   const priceUSD = current ? parseFloat(current.derivedETH) * ethPrices.current : 0;
  // }

  if (coin === undefined || coin.coin === undefined) {
    return <></>;
  }
  return (
    <div className="coin-details p-3">
      <div
        className="header-container d-md-block d-none d-lg-flex align-items-start mb-2"
        onClick={() => {
          props.handleOpen('second');
        }}
      >
        <div className=" back-coin-container d-inline-block">
          <img
            className="bundle-back d-none d-md-inline-block"
            src={backIcon}
            alt="Back"
            height={18}
          />
          <div className="d-inline-block coin-icon">
            <img
              src={`/cryptoicons/${coin.coin.icon as string}`}
              key={coin.coin.icon}
              className="icon"
              width={24}
              height={24}
            />
          </div>
        </div>

        <div className="d-inline-block coin-header">
          {coin.coin.name.split(' ').length <= 2 ? (
            <>
              {coin.coin.name} &#40;{coin.percentage}%&#41;
            </>
          ) : (
            <>
              {coin.coin.name.split(' ').slice(0, -1).join(' ')}
              <br />
              {coin.coin.name.split(' ').slice(-1)} &#40;{coin.percentage}%&#41;
            </>
          )}
        </div>
      </div>
      <div className="coin-content">
        <p className="coin-description">
          {expandDetails ? (
            <>
              {coin.coin.description.split('\n').map((paragraph: string, index: number) => (
                <React.Fragment key={index}>
                  {paragraph}
                  <br />
                </React.Fragment>
              ))}
              <a
                className="read-more"
                onClick={() => {
                  setExpandDetails(false);
                }}
              >
                {t('coin.less')}
              </a>
            </>
          ) : (
            <>
              {truncate(coin.coin.description)}{' '}
              <a
                className="read-more"
                onClick={() => {
                  setExpandDetails(true);
                }}
              >
                {t('coin.more')}
              </a>
            </>
          )}
        </p>
        {/* <div className="coin-performance">
          <div className="container">
            <div className="row">
              <div className="col-7 performance-header">
                {t('coin.performance')}
                <span>{t('coin.3yr')}</span>
              </div>
              <div className="col-5 performance-value">{coin.coin.performance.value}%</div>
            </div>
          </div>
        </div> */}
        <div className="coin-performance">
          <div className="container">
            <div className="row data-row">
              <div className="col-7 performance-header">{t('coin.data')}</div>
              <div className="col-5 performance-value"></div>
            </div>
            <div className="row data-row">
              <div className="col-7 default-header">{t('coin.rank')}</div>
              <div className="col-5 default-value">#{coin.coin.marketData.cmc_rank}</div>
            </div>
            <div className="row data-row">
              <div className="col-7 default-header">{t('coin.price')}</div>
              {/* <div className="col-5 default-value">
                {getCurrencySymbol(dashboardContext.currency)}
                {formatValue({ value: displayCoinPrice.toString(), decimalScale: 3 })}
              </div> */}
              <div className="col-5 default-value">
                {dashboardContext.currencyInfo.symbol}
                {displayCoinPrice.toFixed(2)}
              </div>
            </div>
            <div className="row data-row">
              <div className="col-8 default-header" style={{ paddingRight: '0' }}>
                {t('coin.dominance')}
                <InfoTooltip tooltip={t('coin.content1')} />
              </div>
              <div className="col-4 default-value">
                {coin.coin.marketData.market_cap_dominance}%
              </div>
            </div>
            <div className="row data-row">
              <div className="col-6 default-header" style={{ width: '70%' }}>
                {t('coin.cap')}
                <InfoTooltip tooltip={t('coin.content2')} />
              </div>
              <div className="col-6 default-value" style={{ width: '30%' }}>
                {/* ${(coin.coin.marketData.market_cap * 10 ** -9).toFixed(1)}B
                {/* {formatValue({
                  value: coin.coin.marketData.market_cap.toString(),
                  decimalScale: 0,
                })} */}

                {/* {getCurrencySymbol(dashboardContext.currency)} */}
                {/* {formatValue({
                  value: shortenMarketCap,
                  decimalScale: 0,
                })} */}
                {/* {shortenMarketCap} */}
                {dashboardContext.currencyInfo.symbol}
                {(
                  coin.coin.marketData.market_cap *
                  dashboardContext.currencyInfo.rate *
                  10 ** -9
                ).toFixed(1) + 'B'}
                {/* {(coin.coin.marketData.market_cap * dashboardContext.currencyInfo.rate).toFixed(2)} */}
                {/* {(+shortenMarketCap * dashboardContext.currencyInfo.rate).toFixed(2)} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
