import { useMonerium } from 'context/moneriumContext';
import React, { useContext, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DashboardContext, type IDashboardContext } from './DashboardContext';
import copy from '../../assets/images/copy-icon-dark.svg';
import back from '../../assets/images/back-arrow-icon.svg';

import '../SelectProfilePopup.scss';
import { useTranslation } from 'react-i18next';

export const FundWalletPopup: any = () => {
  const [showCopiedName, setShowCopiedName] = useState(false);
  const [showCopiedIBAN, setShowCopiedIBAN] = useState(false);
  const [step, setStep] = useState(1);
  const { iban, name } = useMonerium();
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const { t } = useTranslation('dashboard');

  const handleClose: any = (): void => {
    dashboardContext.setShowFundWalletPopup(false);
  };

  return (
    <>
      {step === 1 ? (
        <Modal
          onHide={handleClose}
          show={dashboardContext.showFundWalletPopup}
          animation={false}
          className="select-profile-modal fund-wallet-modal"
          centered
        >
          <div className="d-block d-md-none top-mobile">
            <div className="top-mobile-inner">
              <h1>{t('onramp_0.header')}</h1>
              <div className="">{t('onramp_0.exit')}</div>
            </div>
            <div className="bar"></div>
          </div>
          <Modal.Header>
            <Modal.Title>
              {' '}
              <img src={require('../../assets/images/copy.png')} alt="copy" />{' '}
              {t('onramp_0.copyInfo')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="fund-wallet-body">
            <p>{t('onramp_0.content')}</p>
            <span className="fund-wallet-grey">{t('onramp_0.name')}</span>
            <div
              className="fund-wallet-flex"
              onClick={() => {
                navigator.clipboard.writeText(name).catch(() => {});
                setShowCopiedName(true);
                setTimeout(() => {
                  setShowCopiedName(false);
                }, 3000);
              }}
            >
              <OverlayTrigger
                trigger="click"
                overlay={<Tooltip id="test">{t('onramp_0.copiedName')}</Tooltip>}
                placement="top"
                show={showCopiedName}
              >
                <div role="button" className="w-100">
                  <div className="fund-wallet-flex">
                    <span className="fund-wallet-big">{name}</span>
                    {/* <img src={copy} alt="Copy" /> */}
                  </div>
                </div>
              </OverlayTrigger>
            </div>
            <span className="fund-wallet-grey">{t('onramp_0.iban')}</span>
            <div
              className="fund-wallet-flex"
              onClick={() => {
                navigator.clipboard.writeText(iban).catch(() => {});
                setShowCopiedIBAN(true);
                setTimeout(() => {
                  setShowCopiedIBAN(false);
                }, 3000);
              }}
            >
              <OverlayTrigger
                trigger="click"
                overlay={<Tooltip id="test">{t('onramp_0.copiedIBAN')}</Tooltip>}
                placement="top"
                show={showCopiedIBAN}
              >
                <div role="button" className="w-100">
                  <div className="fund-wallet-flex">
                    <span className="fund-wallet-big">{iban}</span>
                    <img src={copy} alt="Copy" />
                  </div>
                </div>
              </OverlayTrigger>
            </div>
          </Modal.Body>
          <Modal.Footer className="d-none d-md-block">
            <div className="fund-wallet-flex fund-wallet-footer-content">
              <p className="fund-wallet-grey">1 of 2</p>
              <div
                className="fund-wallet-button"
                onClick={() => {
                  setStep(2);
                }}
              >
                {t('onramp_0.next')}
              </div>
            </div>
          </Modal.Footer>
          <div className="d-md-none d-flex bottom-mobile">
            <div className="back">
              <img src={back} alt="" onClick={handleClose} />
            </div>
            <div
              className="next"
              onClick={() => {
                setStep(2);
              }}
            >
              {t('onramp_0.next')}
            </div>
          </div>
        </Modal>
      ) : (
        <Modal
          onHide={handleClose}
          show={dashboardContext.showFundWalletPopup}
          animation={false}
          className="select-profile-modal fund-wallet-modal"
          centered
        >
          <div className="d-block d-md-none top-mobile">
            <div className="top-mobile-inner">
              <h1>{t('onramp_0.header')}</h1>
              <div className="">{t('onramp_0.exit')}</div>
            </div>
            <div className="bar full"></div>
          </div>
          <Modal.Header>
            <Modal.Title>
              {' '}
              <img src={require('../../assets/images/bank.png')} alt="copy" /> {t('onramp_1.bank')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="fund-wallet-body">
            <ol>
              <li>{t('onramp_1.li_1')}</li>
              <li>{t('onramp_1.li_2')}</li>
              <li>{t('onramp_1.li_3')}</li>
              <li>{t('onramp_1.li_4')}</li>
              <li>{t('onramp_1.li_5')}</li>
            </ol>
            <span className="fund-wallet-grey grey-2">{t('onramp_1.note')}</span>
          </Modal.Body>
          <Modal.Footer className="d-none d-md-block">
            <div className="fund-wallet-flex fund-wallet-footer-content">
              <p className="fund-wallet-grey">2 of 2</p>
              <div className="fund-wallet-buttons">
                <div
                  className="back"
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  <img src={back} alt="" className="" />
                </div>
                <div className="fund-wallet-button" onClick={handleClose}>
                  {t('onramp_1.done')}
                </div>
              </div>
            </div>
          </Modal.Footer>
          <div className="d-md-none d-flex bottom-mobile">
            <div
              className="back"
              onClick={() => {
                setStep(1);
              }}
            >
              <img src={back} alt="" />
            </div>
            <div className="next" onClick={handleClose}>
              Done
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
