import { useEffect } from 'react';

export const addBodyClass = (className: string): void => {
  document.body.classList.add(className);
};
export const removeBodyClass = (className: string): void => {
  document.body.classList.remove(className);
};

export default function useBodyClass(className: string[]): void {
  useEffect(() => {
    // Set up
    className instanceof Array ? className.map(addBodyClass) : addBodyClass(className);

    // Clean up
    return () => {
      className instanceof Array ? className.map(removeBodyClass) : removeBodyClass(className);
    };
  }, [className]);
}
