import './BundleCoins.scss';
import { type Coin } from 'types/bundleData';
// import getCoins from 'config/coinsConfig';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import backIcon from 'assets/images/bundle-back-icon.svg';
import nextIcon from 'assets/images/next-icon.svg';
import { ProgressBar } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { usePriceData } from 'context/priceDataContext';
import { type ICoinsArray } from 'config/coinsConfig';

interface Props {
  setExpanded: any;
  showCoinDetails: any;
  coins: Coin[];
}

export const BundleCoins = (props: Props): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const { coinsArray } = usePriceData();
  const coins: Coin[] = props.coins;
  const [coinsState, setCoinsState] = useState<any | null>(null);

  const getCoinGroups = (coins: Coin[]): JSX.Element[] => {
    const coinsDivs = [];
    const coinsC: ICoinsArray = coinsArray;

    for (let loop = 0; loop < coins.length; loop++) {
      const dynamicKey = coins[loop].coin as keyof typeof coinsC;
      const coin = coinsC[dynamicKey];

      if (coin !== undefined) {
        coinsDivs.push(
          <div
            className="coin-details d-flex"
            key={coin.name}
            onClick={() => {
              props.showCoinDetails('fourth', coin, coins[loop].percentage);
            }}
          >
            <div className="">
              <img
                src={`/cryptoicons/${coin?.icon}`}
                key={coin?.icon}
                className="icon"
                width={24}
                height={24}
              />
            </div>
            <div className="flex-grow-1">
              <div className="d-flex">
                <div className="flex-grow-1">
                  {`${coin.name} (${coin.symbol})`}
                  <div className="rank d-inline-block">
                    {t('allocation.rank')} #{coin.marketData.cmc_rank}
                  </div>
                </div>
                <div className="">{coins[loop].percentage}%</div>
              </div>
              <ProgressBar now={coins[loop].percentage} className="bundle-percent" />
            </div>
            <div className="">
              <img src={nextIcon} alt="More details" className="next" height={12} width={6} />
            </div>
          </div>,
        );
      }
    }

    return coinsDivs;
  };

  useEffect(() => {
    setCoinsState(getCoinGroups(coins));
  }, [i18n.language, t, coinsArray]);

  return (
    <div className="bundle-coins">
      <h5
        onClick={() => {
          props.setExpanded(false);
        }}
      >
        <img
          className="bundle-back d-none d-md-inline-block"
          src={backIcon}
          alt="Back"
          height={18}
        />
        {t('allocation.investment')}
      </h5>
      <div className="coin-groups">{coinsState !== null ? coinsState : getCoinGroups(coins)}</div>
    </div>
  );
};
