import React, { useContext, useEffect, useState } from 'react';

import './DashboardMobileBundles.scss';

import { useAuth } from 'hooks/useAuth';
import { UserServices } from 'services/UserServices';
import type { BundleData } from 'types/bundleData';
import { BundleMobile } from '../Bundles/BundleMobile';
import getBundles from 'config/bundlesConfig';
import { DashboardContext, type IDashboardContext } from './DashboardContext';

export const DashboardMobileBundles = (): JSX.Element => {
  const { isSignedIn } = useAuth();
  const [selectedProfile, setSelectedProfile] = useState(0);
  const [selectedBundles, setSelectedBundles] = useState<BundleData[]>([]);
  const [activeBundle, setActiveBundle] = useState<BundleData>();
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);

  const getProfileString = (profile: number): string => {
    switch (profile) {
      case 1:
        return 'Careful';
      case 2:
        return 'Balanced';
      case 3:
        return 'Adventurous';
      default:
        return 'Undefined';
    }
  };

  useEffect(() => {
    const getProfile = async (): Promise<void> => {
      const profile = await UserServices.getSelectedProfile();
      setSelectedProfile(profile);
    };
    getProfile().catch(console.error);
  }, [isSignedIn]);

  useEffect(() => {
    const bundles = getBundles;

    setSelectedBundles(bundles);
  }, [selectedProfile]);

  return (
    <div className="dashboard-mobile-bundles">
      <div className="dashboard-bundles-content">
        <div className="bundle-selector">
          <div className="invest-bundle-group">
            {selectedBundles.map((bundle) => (
              <BundleMobile
                badgeName={getProfileString(bundle.investor)}
                badgeColor={
                  getProfileString(bundle.investor) ===
                  getProfileString(dashboardContext.selectedProfile)
                    ? '#defdf2'
                    : '#ececee'
                }
                activeBundle={activeBundle}
                setActiveBundle={setActiveBundle}
                key={bundle.key}
                bundle={bundle}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
