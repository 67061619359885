import { useContext, useState } from 'react';

import plus from 'assets/images/plus_icon.svg';
import { type IDashboardContext, DashboardContext } from 'components/Dashboard/DashboardContext';
import CurrencyInput from 'react-currency-input-field';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import toastWarningIcon from 'assets/images/toast-warning-icon.svg';
import { type Investment } from 'types/investment';

interface Props {
  investment: Investment;
  isMobile?: boolean;
  buttonText?: string;
}

export const BundleWithdraw = (props: Props): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const [withdrawValue, setWithdrawValue] = useState(0);
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const [value, setValue] = useState<string | number>();
  async function handleClick(withdrawBundle: Investment): Promise<void> {
    if (
      withdrawValue === null ||
      withdrawValue === undefined ||
      typeof withdrawValue !== 'number' ||
      withdrawValue <= 0
    ) {
      toast.warn(t('invest.statement'), {
        icon: () => <img src={toastWarningIcon} />,
      });

      return;
    }

    if (withdrawValue > withdrawBundle.currentInvestmentValue) {
      toast.warn('Transaction failed: insufficient portfolio value.', {
        icon: () => <img src={toastWarningIcon} />,
      });
      return;
    }

    dashboardContext.setWithdrawInvestment({
      investment: withdrawBundle,
      withdrawAmount: withdrawValue,
    });
    console.log('dashboardContext.withdrawInvestment', dashboardContext.withdrawInvestment);

    dashboardContext.setShowWithdraw(true);

    toast.dismiss();
    setWithdrawValue(0);
    setValue(undefined);
  }

  return (
    <div className="d-flex bundle-invest">
      <div className="p-md-1 dollar">{dashboardContext.currencyInfo.symbol}</div>
      <div className="p-1 d-flex flex-grow-1">
        <CurrencyInput
          className="invest-input withdraw-input"
          value={value ?? ''}
          step={0.1}
          decimalScale={2}
          decimalsLimit={2}
          onValueChange={(value, name, values) => {
            if (values?.float !== undefined && values?.float !== null) {
              setWithdrawValue(values.float);
              setValue(value);
            } else {
              setWithdrawValue(0);
              setValue('');
            }
          }}
        />
        <div
          className="max-withdraw-btn"
          onClick={() => {
            const civ: number = Math.floor(props.investment.currentInvestmentValue * 100) / 100;
            setWithdrawValue(civ);
            setValue(civ);
          }}
        >
          Max.
        </div>
      </div>
      <div className="p-1">
        {props.buttonText !== undefined ? (
          <div
            className="invest-text col d-flex align-items-center justify-content-center"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              // eslint-disable-next-line
              handleClick(props.investment);
            }}
          >
            {props.buttonText}
          </div>
        ) : (
          <div
            className="invest-plus col d-flex align-items-center justify-content-center"
            onClick={() => {
              // eslint-disable-next-line
              handleClick(props.investment);
            }}
          >
            <img src={plus} alt="invest" width={17} height={17} />
          </div>
        )}
      </div>
    </div>
  );
};
