import { useContext, useState, useEffect } from 'react';
import './DashboardLayout.scss';
import { Header } from '../../Layout/Header';
import { DashboardSidebar } from 'components/Dashboard/DashboardSidebar';
import { DashboardMobileHeader } from 'components/Dashboard/DashboardMobileHeader';
import { UserProfileMobile } from 'components/Dashboard/UserProfileMobile';
import { DashboardHeader } from './DashboardHeader';
import { DashboardCheckout } from './DashboardCheckout';
import { DashboardMobileFooter } from '../DashboardMobileFooter';
import { type IDashboardContext, DashboardContext } from '../DashboardContext';
import { PortfolioWithdraw } from './PortfolioWithdraw';
import { ShepherdTourContext } from 'react-shepherd';
import { Logout } from 'components/Logout';
import { SelectProfilePopup } from 'components/SelectProfilePopup';
import { LanguageSwitcher } from 'components/LanguageSwitcher';
import GuidedTourHeader from 'components/GuidedTourHeader';
import { useNavigate } from 'react-router-dom';
import { ShareFeedbackPopup } from 'components/ShareFeedbackPopup';
import DashboardQuestMarkMobileMenu from '../DashboardQuestMarkMobileMenu';
import backIcon from 'assets/images/back-icon.svg';
import ShareFeedbackForm from '../ShareFeedbackForm';
// import { SwitchCurrencyPopup } from 'components/SwitchCurrencyPopup';
import { FundWalletPopup } from '../FundWalletPopup';
import { RetrieveFromWalletPopup } from '../RetrieveFromWalletPopup';
import { MoneriumNotAuthedPopup } from '../MoneriumNotAuthedPopup';
import { PortfolioWithdrawMobile } from './PortfolioWithdrawMobile';
// import { SuccessTransaction } from 'components/SuccessTransaction';

// import { DeleteUser } from 'components/DeleteUser';

interface Props {
  headerText: string;
  children?: JSX.Element | JSX.Element[];
}

const DashboardLayout: React.FC<Props> = (props: Props) => {
  const navigate = useNavigate();
  // const [count, setCount] = useState(0);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showQuestMarkMenu, setShowQuestMarkMenu] = useState(false);
  // const [showShareFeedbackForm, setShowShareFeedbackForm] = useState(false);
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const tour = useContext(ShepherdTourContext);

  const dismissTour = (): void => {
    dashboardContext.setShowGuidedTour(false);
    dashboardContext.setStartedGuidedTour(false);
    navigate('/dashboard');
  };
  tour?.on('cancel', dismissTour);
  tour?.on('complete', dismissTour);

  // Add progress steps (1 of X) to the guided tour pop ups
  tour?.on('show', () => {
    // using Timeout so that Shepherd has time to draw the new step before trying to add the progress text
    setTimeout(() => {
      if (tour === null) return;
      const currentStep = tour.getCurrentStep();
      if (currentStep === null) return;
      const currentStepElement = currentStep?.getElement();
      const footer = currentStepElement?.querySelector('.shepherd-footer');

      const innerBar = document.createElement('span');

      if (tour?.steps.indexOf(currentStep) + 1 < tour?.steps.length) {
        // don't show the progress steps for the last step
        innerBar.innerText = `${tour?.steps.indexOf(currentStep) + 1} of ${tour?.steps.length - 1}`;
      }
      // add class to the progress holder
      innerBar.className = 'shepherd-progress flex-grow-1 mt-2 text-start';

      // skip adding progress bar to the first popup because it is a welcome dialog
      if (currentStepElement !== null && currentStepElement !== undefined) {
        if (tour?.steps.indexOf(currentStep) !== 0 && footer?.childNodes.length === 3) {
          footer?.insertBefore(innerBar, footer?.firstChild);
        }
        if (tour?.steps.indexOf(currentStep) === 0 && footer?.childNodes.length === 2) {
          footer?.insertBefore(innerBar, footer?.firstChild);
        }
      }
    }, 10);
  });
  useEffect(() => {
    if (!dashboardContext.showShareFeedbackForm && showMenu) {
      setShowQuestMarkMenu(true);
    }
  }, [dashboardContext.showShareFeedbackForm]);
  useEffect(() => {
    if (
      dashboardContext.showGuidedTour &&
      tour !== null &&
      tour.steps.length > 0 &&
      !dashboardContext.startedGuidedTour
    ) {
      dashboardContext.setStartedGuidedTour(true);
      setTimeout(() => {
        tour.start();
      }, 100);
    }
  }, [dashboardContext.showGuidedTour, tour]);

  const handleBackClick = (): void => {
    if (showQuestMarkMenu) {
      setShowQuestMarkMenu(false);
      setShowMenu(false);
    } else if (dashboardContext.showShareFeedbackForm) {
      dashboardContext.setShowShareFeedbackForm(false);
      setShowQuestMarkMenu(true);
    }
  };
  const handleShareFeedClick = (): void => {
    setShowQuestMarkMenu(false);
    dashboardContext.setShowShareFeedbackForm(true);
  };

  const handleHelpClick = (): void => {
    setShowMenu(true);
    setShowQuestMarkMenu(true);
  };

  return (
    <>
      {/* <HelpCenterIFrame /> */}
      {dashboardContext.showGuidedTour && <GuidedTourHeader />}
      <div
        className={`d-none d-md-flex dashboard-layout ${
          dashboardContext.showGuidedTour ? 'dashboard-layout-tour' : ''
        }`}
      >
        <div className="left-sidebar">
          <Header />
          <DashboardSidebar />
        </div>
        <div className="flex-grow-1 dashboard-content-frame">
          <DashboardHeader headerText={props.headerText} />
          <div className="dashboard-content">{props.children}</div>
        </div>
      </div>
      {!dashboardContext.showWithdraw && (
        <div
          className={`d-md-none d-block dashboard-layout-mobile position-relative ${
            dashboardContext.showGuidedTour ? 'dashboard-layout-tour' : ''
          }`}
          onClick={() => {
            if (dashboardContext.expandShoppingCart) dashboardContext.setExpandShoppingCart(false);
          }}
        >
          <Header />
          {!showMenu ? (
            <>
              {showUserProfile ? (
                <UserProfileMobile setShowUserProfile={setShowUserProfile} />
              ) : (
                <>
                  <DashboardMobileHeader
                    handleHelpClick={handleHelpClick}
                    setShowUserProfile={setShowUserProfile}
                  />
                  {props.children}
                </>
              )}
            </>
          ) : (
            <>
              <div className="menu-cointainer">
                <div className="menu-header d-flex justify-content-between">
                  <div className="back-arrow-container" onClick={handleBackClick}>
                    <img src={backIcon} alt="back-icon" />
                  </div>
                  <div className="header-title">Help</div>
                </div>
                {showQuestMarkMenu && (
                  <DashboardQuestMarkMobileMenu handleShareFeedClick={handleShareFeedClick} />
                )}
                {dashboardContext.showShareFeedbackForm && <ShareFeedbackForm />}
              </div>
            </>
          )}
        </div>
      )}
      {(dashboardContext.showWithdraw || dashboardContext.showSuccessTransaction) && (
        <PortfolioWithdrawMobile />
      )}
      {/* <PortfolioWithdrawMobile /> */}
      {/* {dashboardContext.showSuccessTransaction && (
        <SuccessTransaction
          close={returnCloseSuccesstransaction}
          withdraw={false}
          items={processedCartItems}
        />
      )} */}
      <div className="d-md-none d-block">
        <DashboardMobileFooter />
      </div>
      <DashboardCheckout />
      <Logout />
      {/* <DeleteUser /> */}
      <SelectProfilePopup />
      <FundWalletPopup />
      <MoneriumNotAuthedPopup />
      <RetrieveFromWalletPopup />
      <LanguageSwitcher />
      <PortfolioWithdraw />
      {/* <SwitchCurrencyPopup /> */}

      {dashboardContext.showShareFeedbackPopup && <ShareFeedbackPopup />}
    </>
  );
};

DashboardLayout.propTypes = {};

export default DashboardLayout;
