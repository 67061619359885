import { useMonerium } from 'context/moneriumContext';
import React, { useState, useContext, useRef, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useWeb3Auth } from 'services/web3auth';
import { DashboardContext, type IDashboardContext } from '../Dashboard/DashboardContext';
import back from '../../assets/images/back-arrow-icon.svg';
// import profile from '../../assets/images/profile-icon.svg';
import bank from '../../assets/images/bank-icon.svg';
import info from '../../assets/images/info-icon.png';

import '../SelectProfilePopup.scss';
import { useTranslation } from 'react-i18next';

export const RetrieveFromWalletPopup: any = () => {
  const { provider } = useWeb3Auth();
  const { sendWithMonerium, iban } = useMonerium();
  const [step, setStep] = useState(0);
  const [type, setType] = useState<string | null>(null);
  const { t } = useTranslation('dashboard');
  const ibanInfo = useRef(null);

  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const [data, setData] = useState({
    walletAddress: dashboardContext.account,
    currency: 'eur',
    amount: '',
    iban: '',
    name: '',
  });
  const [data2, setData2] = useState({
    walletAddress: dashboardContext.account,
    currency: 'eur',
    amount: '',
    address: '',
  });

  useEffect(() => {
    setData2({
      ...data2,
      amount: ``,
    });
    setData({
      ...data,
      amount: ``,
    });
  }, [dashboardContext.showRetrieveFromWalletPopup]);

  const handleClose: any = (): void => {
    dashboardContext.setShowRetrieveFromWalletPopup(false);
  };

  const handleConfirm: any = async () => {
    if (dashboardContext.account.length > 0) {
      const newData = type === 'bank' ? data : data2;
      newData.walletAddress = dashboardContext.account;
      await sendWithMonerium(provider, newData, type);
      dashboardContext.setShowRetrieveFromWalletPopup(false);
    } else {
      console.error('No walletAddress');
    }
  };

  const handleInputChange: any = (e: any) => {
    const target = e.target as HTMLInputElement;

    const name = target.name;
    const value = target.value;

    type === 'bank' ? setData({ ...data, [name]: value }) : setData2({ ...data2, [name]: value });
  };

  return (
    <>
      {step === 0 ? (
        <Modal
          onHide={handleClose}
          show={dashboardContext.showRetrieveFromWalletPopup}
          animation={false}
          className="select-profile-modal retrieve-from-modal"
          centered
        >
          <Modal.Header>
            <Modal.Title>{t('offramp_0.header')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{t('offramp_0.content')}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="w-100 confirm-button"
              onClick={() => {
                setStep(1);
              }}
            >
              {t('offramp_0.btn')}
            </Button>
          </Modal.Footer>
        </Modal>
      ) : step === 1 ? (
        <Modal
          onHide={handleClose}
          show={dashboardContext.showRetrieveFromWalletPopup}
          animation={false}
          className="select-profile-modal retrieve-from-modal"
          centered
          // onClick={() => {
          //   if (ibanInfo.current !== null) {
          //     const el = ibanInfo.current as HTMLDivElement;
          //     if (el.style.opacity === '1') {
          //       el.style.opacity = '0';
          //     }
          //   }
          // }}
        >
          <Modal.Header>
            <div className="d-none d-md-block">
              <img
                src={back}
                alt=""
                className="back"
                onClick={() => {
                  setStep(0);
                }}
              />
            </div>
            <Modal.Title>{t('offramp_1.header')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{t('offramp_1.content')}</p>
            <p className="small">{t('offramp_1.from')}</p>
            <div className="spans">
              <span>{t('offramp_1.currentWallet')}</span>
              <span>{iban}</span>
            </div>
            <p className="small">{t('offramp_1.sendto')}</p>
            <div className="buttons">
              <div
                className="small-button"
                style={type === 'bank' ? { background: '#d7fef1' } : { background: 'none' }}
                onClick={() => {
                  setType('bank');
                }}
              >
                <img src={bank} alt="" className="bank" />
                {t('offramp_1.bank')}
              </div>
              {/* <div
                className="small-button"
                style={type === 'wallet' ? { background: '#d7fef1' } : { background: 'none' }}
                onClick={() => {
                  setType('wallet');
                }}
              >
                <img src={profile} alt="" className="profile" />
                {t('offramp_1.wallet')}
              </div> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            {type === 'bank' ? (
              <>
                <div className="inputs-cont">
                  <input
                    type="text"
                    name="name"
                    placeholder={String(t('offramp_1.name'))}
                    value={data.name}
                    onChange={handleInputChange}
                  />
                  <div className="input-cover">
                    <div className="iban-info" ref={ibanInfo}>
                      <div className="dark">
                        {t('ibanInfo.content1')} <br /> {t('ibanInfo.content2')}
                      </div>
                      <div className="white">
                        <p>{t('ibanInfo.details')}:</p>
                        BIC: AAABBBCC1XXX <br />
                        IBAN: NL11 0000 0000 0000 0000 22
                      </div>
                    </div>
                    <input
                      type="text"
                      name="iban"
                      placeholder="IBAN"
                      value={data.iban}
                      onChange={handleInputChange}
                    />
                    <img
                      src={info}
                      alt=""
                      className="info"
                      onMouseOut={() => {
                        if (ibanInfo.current !== null) {
                          const el = ibanInfo.current as HTMLDivElement;
                          el.style.opacity = '0';
                          el.style.zIndex = '-1';
                        }
                      }}
                      onMouseOver={() => {
                        if (ibanInfo.current !== null) {
                          const el = ibanInfo.current as HTMLDivElement;
                          el.style.opacity = '1';
                          el.style.zIndex = '999';
                        }
                      }}
                    />
                  </div>
                  <div className="input-cover">
                    <input
                      type="text"
                      name="amount"
                      placeholder={String(t('offramp_1.amount'))}
                      value={data.amount}
                      onChange={handleInputChange}
                    />
                    <div
                      className="max"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setData({
                          ...data,
                          amount: `${(
                            dashboardContext.available * dashboardContext.currencyInfo.rate
                          ).toFixed(2)}`,
                        });
                      }}
                    >
                      Max.
                    </div>
                  </div>
                </div>
                <Button
                  className="w-100 confirm-button"
                  onClick={() => {
                    setStep(2);
                  }}
                >
                  {t('offramp_1.confirm')}
                </Button>
                <button
                  className="w-100 back-btn d-block d-md-none"
                  onClick={() => {
                    setStep(0);
                  }}
                >
                  {t('offramp_1.back')}
                </button>
              </>
            ) : type === 'wallet' ? (
              <>
                <div className="inputs-cont">
                  <input
                    type="text"
                    name="address"
                    placeholder={String(t('offramp_1.address'))}
                    value={data2.address}
                    onChange={handleInputChange}
                  />
                  <div className="input-cover">
                    <input
                      type="text"
                      name="amount"
                      placeholder={String(t('offramp_1.amount'))}
                      value={data2.amount}
                      onChange={handleInputChange}
                    />
                    <div
                      className="max"
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setData2({
                          ...data2,
                          amount: `${(
                            dashboardContext.available * dashboardContext.currencyInfo.rate
                          ).toFixed(2)}`,
                        });
                      }}
                    >
                      Max.
                    </div>
                  </div>
                </div>
                <Button
                  className="w-100 confirm-button"
                  onClick={() => {
                    setStep(2);
                  }}
                >
                  {t('offramp_1.confirm')}
                </Button>
                <button
                  className="w-100 back-btn d-block d-md-none"
                  onClick={() => {
                    setStep(0);
                  }}
                >
                  {t('offramp_1.back')}
                </button>
              </>
            ) : (
              <div
                className="back-btn d-flex d-md-none"
                onClick={() => {
                  setStep(0);
                }}
              >
                {t('offramp_1.back')}
              </div>
            )}
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal
          onHide={handleClose}
          show={dashboardContext.showRetrieveFromWalletPopup}
          animation={false}
          className="select-profile-modal retrieve-from-modal"
          centered
        >
          <Modal.Header>
            <div className="d-none d-md-block">
              <img
                src={back}
                alt=""
                className="back"
                onClick={() => {
                  setStep(1);
                }}
              />
            </div>
            <Modal.Title>{t('offramp_0.header')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="inputs-cont">
              {type === 'bank' ? (
                <>
                  <input
                    type="text"
                    name="name"
                    placeholder="Account Name"
                    disabled={true}
                    value={data.name}
                  />
                  <input
                    type="text"
                    name="iban"
                    placeholder="IBAN"
                    disabled={true}
                    value={data.iban}
                  />
                  <input
                    type="text"
                    name="amount"
                    disabled={true}
                    placeholder="Amount"
                    value={dashboardContext.currencyInfo.symbol + data.amount}
                  />
                </>
              ) : (
                <>
                  <input
                    type="text"
                    name="address"
                    placeholder="IBAN"
                    disabled={true}
                    value={data2.address}
                  />
                  <input
                    type="text"
                    name="amount"
                    disabled={true}
                    placeholder="Amount"
                    // value={'€' + data2.amount}
                    value={dashboardContext.currencyInfo.symbol + data2.amount}
                  />
                </>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="buttons last-buttons">
              <Button
                className="w-100 confirm-button cancel"
                onClick={() => {
                  setStep(0);
                  handleClose();
                }}
              >
                {t('offramp_1.cancel')}
              </Button>
              <Button className="w-100 confirm-button" onClick={handleConfirm}>
                {t('offramp_1.confirm')}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
