import React from 'react';
// import { Link } from 'react-router-dom';

interface IProps {
  icon: string;
  text: string;
  link: string;
  small: boolean;
}

export const ContactButton: React.FC<IProps> = ({ icon, text, link, small }: IProps) => {
  const handleLinkOpen: () => void = () => {
    window.open(link, '_blank');
  };

  return (
    <div
      onClick={handleLinkOpen}
      className={small ? 'cb-btn-cont cb-btn-cont-small' : 'cb-btn-cont'}
    >
      <div className={small ? 'cb-icon-wrap cb-icon-wrap-small' : 'cb-icon-wrap'}>
        <img
          src={icon}
          alt="Right"
          height={18}
          className={small ? 'cb-icon cb-icon-small' : 'cb-icon'}
        />
      </div>
      <div className={small ? 'cb-text cb-text-small' : 'cb-text'}>{text}</div>
    </div>
  );
};
