import React, { useEffect, useMemo, useRef, useState, useContext } from 'react';

import type { BundleData } from 'types/bundleData';
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap';

import './BundleMobile.scss';

import chart from 'assets/images/bundle-chart-icon.svg';
import coins from 'assets/images/bundle-coins-icon.svg';
import help from 'assets/images/bundle-help-icon.svg';
import openIcon from 'assets/images/open-icon.svg';
import closeIcon from 'assets/images/close-icon.svg';
import { BundleInvest } from './BundleInvest';
import { CoinDisplay } from './CoinDisplay';
import { BundlePerformance } from './BundlePerformance';
import { BundleFAQ } from './BundleFAQ';
import { BundleMobileCoins } from './BundleMobileCoins';
// import { formatValue } from 'react-currency-input-field';
// import PriceData from 'services/priceData';
import { useTranslation } from 'react-i18next';
import { type IDashboardContext, DashboardContext } from 'components/Dashboard/DashboardContext';
import { usePriceData } from 'context/priceDataContext';
import { show3YearsPerformaceText } from 'config/featureFlags';

interface Props {
  badgeName: string;
  badgeColor: string;
  bundle: BundleData;
  activeBundle: BundleData | undefined;
  setActiveBundle: any;
}

export const BundleMobile = (props: Props): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const [expanded, setExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const [readMore, setReadMore] = useState(false);
  const [priceDataLoaded, setPriceDataLoaded] = useState(false);
  const [performanceValue, setPerformanceValue] = useState(0);
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const bundle = props.bundle;
  const scrollRef = useRef<any>();
  const { getPerformance } = usePriceData();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!expanded) {
      setActiveTab('');
    }
  }, [expanded]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (props.activeBundle && props.activeBundle.name !== bundle.name) {
      setExpanded(false);
      setActiveTab('');
    }
  }, [props.activeBundle]);

  const showCoinDetails = (key: string, coin: any): void => {
    setActiveTab(key);
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>, key: string): void => {
    event?.preventDefault();
    if (expanded && activeTab === key) {
      setExpanded(false);
      setActiveTab('');
    } else {
      setExpanded(true);
      setActiveTab(key);
      props.setActiveBundle(bundle);
      setTimeout(() => {
        if (scrollRef.current != null) {
          scrollRef.current.scrollIntoView(true);
        }
      }, 300);
    }
  };

  const getPerformanceFigures = (): string => {
    const prefix = performanceValue >= 0 ? '+' : '';
    // return formatValue({
    //   value: performanceValue.toString(),
    //   decimalScale: 2,
    //   prefix,
    // });
    return prefix + `${performanceValue?.toFixed(2)}`;
  };

  useMemo(() => {
    const getChartData = async (): Promise<void> => {
      const fetchedPerformance = await getPerformance(props.bundle);
      props.bundle.performance = fetchedPerformance;
      setPerformanceValue(fetchedPerformance);
      setPriceDataLoaded(true);
    };

    getChartData().catch(console.error);
  }, []);

  return (
    <div className={`${expanded ? 'expanded' : ''} invest-bundle-mobile-wrapper`}>
      <Tab.Container id="left-tabs-example" activeKey={activeTab}>
        <Container className="invest-bundle">
          <Row>
            <Col>
              <div>
                <div className="d-flex justify-content-between mb-3">
                  <div style={{ backgroundColor: props.badgeColor }} className="badge">
                    {props.badgeName}
                  </div>
                  {show3YearsPerformaceText && (
                    <p className="performance pt-1">{t('bundles.3years')}</p>
                  )}
                </div>
                <div className="d-flex">
                  <div>
                    <img
                      src={bundle.image}
                      alt={bundle.name}
                      className="d-block bundle-image"
                      height={88}
                      width={88}
                    />
                    <CoinDisplay coins={bundle.coins} coinSize={24} />
                  </div>
                  <div className="d-flex flex-column">
                    <div className="d-flex">
                      <h4 className="flex-grow-1">{bundle.name}</h4>
                      <div className="performance-figures d-none">
                        {priceDataLoaded ? getPerformanceFigures() : '--'}%
                      </div>
                    </div>
                    <div className="d-flex">
                      <div
                        className={`${readMore ? '' : 'collapsed'} flex-grow-1 bundle-description`}
                      >
                        {bundle.shortDescription}
                      </div>
                      <div
                        className="align-self-center"
                        role="button"
                        onClick={() => {
                          if (!dashboardContext.showGuidedTour) {
                            setReadMore(!readMore);
                          }
                        }}
                      >
                        {readMore ? (
                          <img src={closeIcon} alt="close" width={15} height={7.5} />
                        ) : (
                          <img src={openIcon} alt="open" width={15} height={7.5} />
                        )}
                      </div>
                    </div>
                    <div className="bundle-invest-wrapper">
                      <BundleInvest isMobile={true} bundle={bundle} />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row ref={scrollRef}>
            <Col className="invest-bundle-buttons">
              <Nav className="d-flex">
                <Nav.Item className="flex-fill text-center">
                  <Nav.Link
                    disabled={dashboardContext.showGuidedTour}
                    eventKey="first"
                    onClick={(event) => {
                      handleOpen(event, 'first');
                    }}
                  >
                    <img src={chart} alt="Chart" width={20} height={20} />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="flex-fill text-center">
                  <Nav.Link
                    disabled={dashboardContext.showGuidedTour}
                    eventKey="second"
                    onClick={(event) => {
                      handleOpen(event, 'second');
                    }}
                  >
                    <img src={coins} alt="Coins" width={20} height={20} />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="flex-fill text-center">
                  <Nav.Link
                    disabled={dashboardContext.showGuidedTour}
                    eventKey="third"
                    onClick={(event) => {
                      handleOpen(event, 'third');
                    }}
                  >
                    <img src={help} alt="Help" width={20} height={20} />
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col className={`${expanded ? '' : 'd-none'} bundle-details`}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <BundlePerformance
                    bundle={bundle}
                    setExpanded={setExpanded}
                    activeTab={activeTab}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <BundleMobileCoins
                    showCoinDetails={showCoinDetails}
                    coins={bundle.coins}
                    setExpanded={setExpanded}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <BundleFAQ bundle={bundle} setExpanded={setExpanded} />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Container>
      </Tab.Container>
    </div>
  );
};
