import { EurEABI } from './abis/EurEABI';
import { FenusIndexTokenABI } from './abis/FenusIndexTokenABI';
import { POLYGON_ADDRESSES } from './addresses/Polygon';

import { type CoinsConfigs } from 'types';

export const coinsConfigs: CoinsConfigs = {
  eure: {
    name: 'EurE',
    symbol: 'EurE',
    address: POLYGON_ADDRESSES.EurE,
    abi: EurEABI,
  },
  stabilizer: {
    name: 'Fenus Stabilizer Index',
    symbol: 'fsi',
    address: POLYGON_ADDRESSES.Stabilizer,
    abi: FenusIndexTokenABI,
  },
  growth: {
    name: 'Fenus Growth Index',
    symbol: 'fgi',
    address: POLYGON_ADDRESSES.Growth,
    abi: FenusIndexTokenABI,
  },
  dynamic: {
    name: 'Fenus Dynamic Index',
    symbol: 'fdi',
    address: POLYGON_ADDRESSES.Dynamic,
    abi: FenusIndexTokenABI,
  },
};
