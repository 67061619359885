import { Button, Collapse } from 'react-bootstrap';
import './DashboardMobileFooter.scss';
import { useContext, useEffect, useState } from 'react';
import { type IDashboardContext, DashboardContext } from './DashboardContext';
import { type CartItem } from 'types/cart';
import { useTranslation } from 'react-i18next';

import cartItemDelete from 'assets/images/cart-item-delete.svg';
import cartItemEdit from 'assets/images/cart-item-edit.svg';
import cartEditApprove from 'assets/images/cart-edit-approve.svg';

import openIcon from 'assets/images/open-icon.svg';
import closeIcon from 'assets/images/close-icon.svg';

import { useNavigate } from 'react-router-dom';
import { addBodyClass, removeBodyClass } from 'hooks/useBodyClass';
import CurrencyInput from 'react-currency-input-field';

export const DashboardMobileFooter = (): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const [subTotal, setSubTotal] = useState(0);
  const [cartItems, setCartItems] = useState<JSX.Element[]>([]);
  const navigate = useNavigate();

  const processInvestment = (): void => {
    navigate('/mobile-checkout');
  };

  const deleteItem = (index: number): void => {
    const items = dashboardContext.shoppingCartItems;

    if (items.length > 1) {
      items.splice(index, 1);
      dashboardContext.setShoppingCartItems(items.slice());
    } else {
      dashboardContext.setShoppingCartItems([]);
    }
  };

  // const getPerformanceFigures = (item: ICartItem): string => {
  //   const prefix = item.bundle.performance >= 0 ? '+' : '';
  //   return formatValue({
  //     value: item.bundle.performance.toString(),
  //     decimalScale: 2,
  //     prefix,
  //   });
  // };

  useEffect(() => {
    const getItems = (items: CartItem[]): JSX.Element[] => {
      const itemDivs = [];
      let runningTotal = 0;

      const newItems = [...items];

      for (let loop = 0; loop < items.length; loop++) {
        const item = items[loop];
        runningTotal += item.investmentValue;
        itemDivs.push(
          <div key={loop} className="item-container">
            <div className="d-flex item-header">
              <div className="flex-fill item-name">{item.bundle.name}</div>
              <div className="flex-fill text-end ">
                {/* € */}
                {dashboardContext.currencyInfo.symbol}
                {item.editing ? (
                  <CurrencyInput
                    className="item-input w-50"
                    step={0.1}
                    decimalsLimit={2}
                    decimalScale={2}
                    defaultValue={item.investmentValue * dashboardContext.currencyInfo.rate}
                    onValueChange={(value, name, values) => {
                      if (values?.float !== undefined && values?.float !== null) {
                        item.investmentValue = values?.float / dashboardContext.currencyInfo.rate;
                      } else {
                        item.investmentValue = 0;
                      }
                    }}
                  />
                ) : (
                  // formatValue({ value: item.investmentValue.toString(), decimalScale: 2 })
                  (item.investmentValue * dashboardContext.currencyInfo.rate).toFixed(2)
                )}
              </div>
            </div>
            <div className="d-flex item-performance">
              <div className="flex-grow-1">
                {/* 3 year performance
                {item.bundle.performance === 0 ? getPerformanceFigures(item) : '--'}% */}
              </div>
              <div
                className="item-delete"
                onClick={() => {
                  deleteItem(loop);
                }}
              >
                <img src={cartItemDelete} />
              </div>
              <div className="item-edit">
                {item.editing ? (
                  <img
                    src={cartEditApprove}
                    onClick={() => {
                      newItems[loop].editing = false;
                      dashboardContext.setShoppingCartItems(newItems);
                    }}
                  />
                ) : (
                  <img
                    src={cartItemEdit}
                    onClick={() => {
                      newItems[loop].editing = true;
                      dashboardContext.setShoppingCartItems(newItems);
                    }}
                  />
                )}
              </div>
            </div>
          </div>,
        );
      }
      setSubTotal(runningTotal);
      return itemDivs;
    };
    setCartItems(getItems(dashboardContext.shoppingCartItems));
  }, [dashboardContext.shoppingCartItems]);

  if (dashboardContext.shoppingCartItems?.length <= 0) {
    removeBodyClass('mobile-expanded');
  } else {
    addBodyClass('mobile-expanded');
  }

  return (
    <div
      className={`${
        dashboardContext.shoppingCartItems?.length <= 0 ? 'd-none' : 'd-block'
      } fixed-bottom dashboard-mobile-footer`}
      onClick={(event) => {
        event.preventDefault();
      }}
    >
      <div
        className="cart-subtotal"
        onClick={() => {
          dashboardContext.setExpandShoppingCart(!dashboardContext.expandShoppingCart);
        }}
      >
        Subtotal amount:
        {/* €{formatValue({ value: subTotal.toString(), decimalScale: 2 })} */}
        {dashboardContext.currencyInfo.symbol}
        {(subTotal * dashboardContext.currencyInfo.rate).toFixed(2)}
        <div className="cart-open-icon d-inline-block">
          {dashboardContext.expandShoppingCart ? (
            <img src={openIcon} alt="open" width={15} height={7.5} />
          ) : (
            <img src={closeIcon} alt="close" width={15} height={7.5} />
          )}
        </div>
      </div>
      <Collapse in={dashboardContext.expandShoppingCart}>
        <div>
          <div>{cartItems}</div>
          <div className="notice">*{t('cart.statement')}</div>
        </div>
      </Collapse>
      <Button
        variant="primary"
        className="w-100 process-button"
        onClick={processInvestment}
        disabled={dashboardContext.shoppingCartItems?.length < 1}
      >
        {t('cart.process')}
      </Button>
    </div>
  );
};
