import profile from 'assets/images/profile-inverse-icon.svg';
import plus from 'assets/images/plus_icon.svg';
import backArrowIcon from 'assets/images/back-arrow-icon.svg';
import i18n from 'i18next';

// Please, do not delete!

// export const appTourSteps = [
//   {
//     title: `<span>👤</span> ${i18n.t('guidedtour:appStep1.header')}`,
//     id: 'user-account',
//     text: [
//       `
//       <p>
//       ${i18n.t(
//         'guidedtour:appStep1.content1',
//       )} <img src=${profile} alt="Profile" height=${24} width=${24} /> ${i18n.t(
//         'guidedtour:appStep1.content2',
//       )}
//       </p>
//       `,
//     ],
//     attachTo: { element: '.profile-popup-modal .modal-dialog', on: 'left' as const },
//     buttons: [
//       {
//         type: 'next',
//         text: i18n.t('guidedtour:btn.next'),
//       },
//     ],
//     beforeShowPromise: async (): Promise<any> => {
//       const d = document.getElementById('showProfilePopup');
//       window.scrollTo(0, 0);
//       d?.click();
//     },
//   },
//   {
//     id: 'fenus-wallet',
//     title: `👝 ${i18n.t('guidedtour:appStep2.header')}`,
//     text: [i18n.t('guidedtour:appStep2.content')],
//     attachTo: { element: '#desktop-wallet-overview', on: 'right' as const },
//     buttons: [
//       {
//         type: 'back',
//         classes: 'shepherd-button-secondary',
//         text: `<img src=${backArrowIcon} alt="Back" height=12 width=12>`,
//       },
//       {
//         type: 'next',
//         text: i18n.t('guidedtour:btn.next'),
//       },
//     ],
//     beforeShowPromise: async (): Promise<any> => {
//       const element: HTMLElement = document.getElementsByClassName(
//         'profile-popup-backdrop',
//       )[0] as HTMLElement;
//       element?.click();
//     },
//   },
//   {
//     id: 'investing-bundles',
//     title: `📈 ${i18n.t('guidedtour:appStep3.header')}`,
//     text: [i18n.t('guidedtour:appStep3.content')],
//     beforeShowPromise: async (): Promise<any> => {
//       const element: HTMLElement = document.getElementById('all-investing-bundles') as HTMLElement;
//       element?.click();
//     },
//     attachTo: { element: '.dashboard-bundles', on: 'top' as const },
//     buttons: [
//       {
//         type: 'back',
//         classes: 'shepherd-button-secondary',
//         text: `<img src=${backArrowIcon} alt="Back" height=12 width=12>`,
//       },
//       {
//         type: 'next',
//         text: i18n.t('guidedtour:btn.next'),
//       },
//     ],
//   },
//   {
//     id: 'bundle-insights',
//     title: `📑 ${i18n.t('guidedtour:appStep4.header')}`,
//     text: i18n.t('guidedtour:appStep4.content'),
//     attachTo: { element: '.flex-column.float-end.nav', on: 'top' as const },
//     buttons: [
//       {
//         type: 'back',
//         classes: 'shepherd-button-secondary',
//         text: `<img src=${backArrowIcon} alt="Back" height=12 width=12>`,
//       },
//       {
//         type: 'next',
//         text: i18n.t('guidedtour:btn.next'),
//       },
//     ],
//   },
//   {
//     id: 'make-investments',
//     title: `➕ ${i18n.t('guidedtour:appStep5.header')}`,
//     text: `${i18n.t('guidedtour:appStep5.content1')} <div
//     class="shepherd-invest-plus col d-inline-flex align-items-center justify-content-center">
//     <img src=${plus} alt="invest" width=${8} height=${8} />
//   </div> ${i18n.t('guidedtour:appStep5.content2')}`,
//     attachTo: { element: '.bundle-invest', on: 'top' as const },
//     buttons: [
//       {
//         type: 'back',
//         classes: 'shepherd-button-secondary',
//         text: `<img src=${backArrowIcon} alt="Back" height=12 width=12>`,
//       },
//       {
//         type: 'next',
//         text: i18n.t('guidedtour:btn.next'),
//       },
//     ],
//   },
//   {
//     id: 'cart',
//     title: `🛒 ${i18n.t('guidedtour:appStep6.header')}`,
//     text: i18n.t('guidedtour:appStep6.content'),
//     beforeShowPromise: async (): Promise<any> => {
//       const d = document.getElementById('showShoppingCart');
//       window.scrollTo(0, 0);
//       d?.click();
//     },
//     attachTo: { element: '.shopping-cart-modal .modal-dialog', on: 'right' as const },
//     buttons: [
//       {
//         type: 'back',
//         classes: 'shepherd-button-secondary',
//         text: `<img src=${backArrowIcon} alt="Back" height=12 width=12>`,
//       },
//       {
//         type: 'next',
//         text: i18n.t('guidedtour:btn.next'),
//       },
//     ],
//   },
//   {
//     id: 'investment-performance',
//     title: `📊 ${i18n.t('guidedtour:appStep7.header')}`,
//     text: i18n.t('guidedtour:appStep6.content'),
//     beforeShowPromise: async (): Promise<any> => {
//       const element: HTMLElement = document.getElementsByClassName(
//         'shopping-cart-backdrop',
//       )[0] as HTMLElement;
//       element?.click();
//       const d = document.getElementById('dashboard-link');
//       d?.click();
//     },
//     attachTo: { element: '.portfolio-overview-content', on: 'bottom' as const },
//     buttons: [
//       {
//         type: 'back',
//         classes: 'shepherd-button-secondary',
//         text: `<img src=${backArrowIcon} alt="Back" height=12 width=12>`,
//       },
//       {
//         type: 'next',
//         text: i18n.t('guidedtour:btn.next'),
//       },
//     ],
//   },
//   {
//     id: 'my-portfolio',
//     title: `📄 ${i18n.t('guidedtour:appStep8.header')}`,
//     text: i18n.t('guidedtour:appStep8.content'),
//     beforeShowPromise: async (): Promise<any> => {
//       const d = document.getElementById('my-portfolio-link');
//       d?.click();
//     },
//     attachTo: { element: '#my-portfolio-link', on: 'right' as const },
//     buttons: [
//       {
//         type: 'back',
//         classes: 'shepherd-button-secondary',
//         text: `<img src=${backArrowIcon} alt="Back" height=12 width=12>`,
//       },
//       {
//         type: 'next',
//         text: i18n.t('guidedtour:btn.next'),
//       },
//     ],
//   },
// ];

// export const supportTourSteps = [
//   {
//     id: 'help-center',
//     title: `👨🏻‍💻 ${i18n.t('guidedtour:supportStep1.header')}`,
//     text: i18n.t('guidedtour:supportStep1.content'),
//     attachTo: { element: '#help-center-link', on: 'right' as const },
//     scrollTo: true,
//     buttons: [
//       {
//         type: 'back',
//         classes: 'shepherd-button-secondary',
//         text: `<img src=${backArrowIcon} alt="Back" height=12 width=12>`,
//       },
//       {
//         type: 'next',
//         text: i18n.t('guidedtour:btn.next'),
//       },
//     ],
//   },
//   {
//     id: 'faqs',
//     title: `💬 ${i18n.t('guidedtour:supportStep2.header')}`,
//     text: i18n.t('guidedtour:supportStep2.content'),
//     beforeShowPromise: async (): Promise<any> => {
//       const d = document.getElementById('my-help-link');
//       d?.click();
//     },
//     scrollTo: true,
//     buttons: [
//       // {
//       //   type: 'back',
//       //   classes: 'shepherd-button-secondary',
//       //   text: `<img src=${backArrowIcon} alt="Back" height=12 width=12>`,
//       // },
//       {
//         type: 'next',
//         text: i18n.t('guidedtour:btn.next'),
//       },
//     ],
//   },
//   {
//     id: 'contact-us',
//     title: `💬 ${i18n.t('guidedtour:supportStep3.header')}`,
//     text: i18n.t('guidedtour:supportStep3.content'),
//     // attachTo: {
//     //   element: function () {
//     //     const iframe = document.getElementById('help-iframe') as HTMLIFrameElement;
//     //     const element = iframe?.contentWindow?.document.getElementsByTagName('contact-block')[0];
//     //     return element;
//     //   },
//     //   on: 'top' as const,
//     // },

//     scrollTo: true,
//     buttons: [
//       {
//         type: 'back',
//         classes: 'shepherd-button-secondary',
//         text: `<img src=${backArrowIcon} alt="Back" height=12 width=12>`,
//       },
//       {
//         type: 'next',
//         text: i18n.t('guidedtour:btn.next'),
//       },
//     ],
//   },
// ];

// export const finalTourSteps = [
//   {
//     id: 'greeting-fenus',
//     title: `👋🏼 ${i18n.t('guidedtour:finalStep.header')}`,
//     text: `${i18n.t('guidedtour:finalStep.content1')} <br />
//     ${i18n.t('guidedtour:finalStep.content2')}`,
//     scrollTo: true,
//     buttons: [
//       {
//         type: 'cancel',
//         text: i18n.t('guidedtour:btn.launch'),
//       },
//     ],
//   },
// ];

export const appTourSteps: () => any[] = () => {
  return [
    {
      title: `<span>👤</span> User Account`,
      id: 'user-account',
      text: [
        `
        <p>
        ${i18n.t(
          'guidedtour:appStep1.content1',
        )} <img src=${profile} alt="Profile" height=${24} width=${24} /> ${i18n.t(
          'guidedtour:appStep1.content2',
        )}
        </p>
        `,
      ],
      attachTo: { element: '.profile-popup-modal .modal-dialog', on: 'left' as const },
      buttons: [
        {
          type: 'next',
          text: i18n.t('guidedtour:btn.next'),
        },
      ],
      beforeShowPromise: async (): Promise<any> => {
        const d = document.getElementById('showProfilePopup');
        window.scrollTo(0, 0);
        d?.click();
      },
    },
    {
      id: 'fenus-wallet',
      // title: '👝 Your Fenus Wallet',
      // text: [
      //   'View the status of your Fenus wallet, including the available amount for investing and the amount you have already invested. You fund your account by exchanging your fiat currency (e.g., Euro or U.S. Dollar) for digital Euros, after which you are able to make investments. You’re also able to exchange your digital Euros back to your fiat currency.',
      // ],
      title: `👝 ${i18n.t('guidedtour:appStep2.header')}`,
      text: [i18n.t('guidedtour:appStep2.content')],
      attachTo: { element: '#desktop-wallet-overview', on: 'right' as const },
      buttons: [
        {
          type: 'back',
          classes: 'shepherd-button-secondary',
          text: `<img src=${backArrowIcon} alt="Back" height=12 width=12>`,
        },
        {
          type: 'next',
          text: i18n.t('guidedtour:btn.next'),
        },
      ],
      beforeShowPromise: async (): Promise<any> => {
        const element: HTMLElement = document.getElementsByClassName(
          'profile-popup-backdrop',
        )[0] as HTMLElement;
        element?.click();
      },
    },
    {
      id: 'investing-bundles',
      // title: '📈 Investing Bundles',
      // text: [
      //   'This section is where you can invest in investment bundles, even in multiple at the same time, which means you directly buy multiple cryptocurrencies which saves you time and effort. You can leverage the "best pick for you" feature to see which bundles fit your investor profile.',
      // ],
      title: `📈 ${i18n.t('guidedtour:appStep3.header')}`,
      text: [i18n.t('guidedtour:appStep3.content')],
      beforeShowPromise: async (): Promise<any> => {
        const element: HTMLElement = document.getElementById(
          'all-investing-bundles',
        ) as HTMLElement;
        element?.click();
      },
      attachTo: { element: '.dashboard-bundles', on: 'top' as const },
      buttons: [
        {
          type: 'back',
          classes: 'shepherd-button-secondary',
          text: `<img src=${backArrowIcon} alt="Back" height=12 width=12>`,
        },
        {
          type: 'next',
          text: i18n.t('guidedtour:btn.next'),
        },
      ],
    },
    {
      id: 'bundle-insights',
      // title: '📑 Bundle Insights',
      // text: `These buttons allow you to check the bundle's historical performance, allocations, and frequently asked questions (FAQs).`,
      title: `📑 ${i18n.t('guidedtour:appStep4.header')}`,
      text: i18n.t('guidedtour:appStep4.content'),
      attachTo: { element: '.flex-column.float-end.nav', on: 'top' as const },
      buttons: [
        {
          type: 'back',
          classes: 'shepherd-button-secondary',
          text: `<img src=${backArrowIcon} alt="Back" height=12 width=12>`,
        },
        {
          type: 'next',
          text: i18n.t('guidedtour:btn.next'),
        },
      ],
    },
    {
      id: 'make-investments',
      //   title: '➕ Make Your Investments',
      //   text: `Type in the amount you want to invest in the respective field. Then, click the button <div
      //   class="shepherd-invest-plus col d-inline-flex align-items-center justify-content-center">
      //   <img src=${plus} alt="invest" width=${8} height=${8} />
      // </div> to add it to your cart.`,
      title: `➕ ${i18n.t('guidedtour:appStep5.header')}`,
      text: `${i18n.t('guidedtour:appStep5.content1')} <div
      class="shepherd-invest-plus col d-inline-flex align-items-center justify-content-center">
      <img src=${plus} alt="invest" width=${8} height=${8} />
    </div> ${i18n.t('guidedtour:appStep5.content2')}`,
      attachTo: { element: '.bundle-invest', on: 'top' as const },
      buttons: [
        {
          type: 'back',
          classes: 'shepherd-button-secondary',
          text: `<img src=${backArrowIcon} alt="Back" height=12 width=12>`,
        },
        {
          type: 'next',
          text: i18n.t('guidedtour:btn.next'),
        },
      ],
    },
    {
      id: 'cart',
      // title: '🛒 Cart',
      // text: `You can review the investment items before proceeding with the investments.`,
      title: `🛒 ${i18n.t('guidedtour:appStep6.header')}`,
      text: i18n.t('guidedtour:appStep6.content'),
      beforeShowPromise: async (): Promise<any> => {
        const d = document.getElementById('showShoppingCart');
        window.scrollTo(0, 0);
        d?.click();
      },
      attachTo: { element: '.shopping-cart-modal .modal-dialog', on: 'right' as const },
      buttons: [
        {
          type: 'back',
          classes: 'shepherd-button-secondary',
          text: `<img src=${backArrowIcon} alt="Back" height=12 width=12>`,
        },
        {
          type: 'next',
          text: i18n.t('guidedtour:btn.next'),
        },
      ],
    },
    {
      id: 'investment-performance',
      // title: '📊 Investment Performance',
      // text: `View how your investments are performing, including total number of investments, current total invested value, performance, and risk profile.`,
      title: `📊 ${i18n.t('guidedtour:appStep7.header')}`,
      text: i18n.t('guidedtour:appStep6.content'),
      beforeShowPromise: async (): Promise<any> => {
        const element: HTMLElement = document.getElementsByClassName(
          'shopping-cart-backdrop',
        )[0] as HTMLElement;
        element?.click();
        const d = document.getElementById('dashboard-link');
        d?.click();
      },
      attachTo: { element: '.portfolio-overview-content', on: 'bottom' as const },
      buttons: [
        {
          type: 'back',
          classes: 'shepherd-button-secondary',
          text: `<img src=${backArrowIcon} alt="Back" height=12 width=12>`,
        },
        {
          type: 'next',
          text: i18n.t('guidedtour:btn.next'),
        },
      ],
    },
    {
      id: 'my-portfolio',
      // title: '📄 My Portfolio',
      // text: `View more details about your investments and perform investment/withdrawal transactions on existing investments.`,
      title: `📄 ${i18n.t('guidedtour:appStep8.header')}`,
      text: i18n.t('guidedtour:appStep8.content'),
      beforeShowPromise: async (): Promise<any> => {
        const d = document.getElementById('my-portfolio-link');
        d?.click();
      },
      attachTo: { element: '#my-portfolio-link', on: 'right' as const },
      buttons: [
        {
          type: 'back',
          classes: 'shepherd-button-secondary',
          text: `<img src=${backArrowIcon} alt="Back" height=12 width=12>`,
        },
        {
          type: 'next',
          text: i18n.t('guidedtour:btn.next'),
        },
      ],
    },
  ];
};

export const supportTourSteps: () => any[] = () => {
  return [
    {
      id: 'help-center',
      // title: '👨🏻‍💻 Fenus Help Center',
      // text: 'We will guide you to our official website where you can find FAQs and channels to reach out to our support team.',
      title: `👨🏻‍💻 ${i18n.t('guidedtour:supportStep1.header')}`,
      text: i18n.t('guidedtour:supportStep1.content'),
      // beforeShowPromise: async (): Promise<any> => {
      //   const link = document.createElement('link');
      //   link.rel = 'prerender';
      //   link.href = `${process.env.REACT_APP_website_domain ?? 'localhost:8000'}/help/`;
      //   document.head.appendChild(link);
      // },
      attachTo: { element: '#showHelpPopup', on: 'left' as const },
      scrollTo: true,
      buttons: [
        {
          type: 'back',
          classes: 'shepherd-button-secondary',
          text: `<img src=${backArrowIcon} alt="Back" height=12 width=12>`,
        },
        {
          type: 'next',
          text: i18n.t('guidedtour:btn.next'),
        },
      ],
    },
    {
      id: 'faqs',
      // title: '💬 FAQs',
      // text: 'On the help center page, you can browse through the questions asked by other people. Here, you may find answers to your own queries and concerns.',
      title: `💬 ${i18n.t('guidedtour:supportStep2.header')}`,
      text: i18n.t('guidedtour:supportStep2.content'),
      beforeShowPromise: async (): Promise<any> => {
        // const d = document.getElementById('my-help-link');
        const d = document.getElementById('helpcenter-iframe');
        if (d !== null) {
          d.style.marginTop = '0';
          d.style.display = 'block';
        }
      },
      scrollTo: true,
      buttons: [
        // {
        //   type: 'back',
        //   classes: 'shepherd-button-secondary',
        //   text: `<img src=${backArrowIcon} alt="Back" height=12 width=12>`,
        // },
        {
          type: 'next',
          text: i18n.t('guidedtour:btn.next'),
        },
      ],
    },
    {
      id: 'contact-us',
      // title: '💬 Contact Us',
      // text: 'Feel free to reach out to us through a message or give us a call. We want you to feel comfortable and are happy to assist you in any way that suits you best.',
      title: `💬 ${i18n.t('guidedtour:supportStep3.header')}`,
      text: i18n.t('guidedtour:supportStep3.content'),
      // attachTo: {
      //   element: function () {
      //     const iframe = document.getElementById('help-iframe') as HTMLIFrameElement;
      //     const element = iframe?.contentWindow?.document.getElementsByTagName('contact-block')[0];
      //     return element;
      //   },
      //   on: 'top' as const,
      // },
      beforeShowPromise: async (): Promise<any> => {
        const d = document.getElementById('helpcenter-iframe');
        if (d !== null) d.style.marginTop = '-95vh';
        // marginTop: '-95vh'
        // d?.dispatchEvent(new MouseEvent('dblclick', { bubbles: true }));
      },

      scrollTo: true,
      buttons: [
        {
          type: 'back',
          classes: 'shepherd-button-secondary',
          text: `<img src=${backArrowIcon} alt="Back" height=12 width=12>`,
        },
        {
          type: 'next',
          text: i18n.t('guidedtour:btn.next'),
        },
      ],
    },
  ];
};

export const finalTourSteps: () => any[] = () => {
  return [
    {
      id: 'greeting-fenus',
      // title: '👋🏼 Greetings from Fenus',
      // text: `Welcome to Fenus, your co-pilot in the world of investing! <br />
      // We take on the hard work, keeping you informed about your investments and empowering you to make smart decisions effortlessly. Sit back, relax, and enjoy hassle-free investing with us.`,
      title: `👋🏼 ${i18n.t('guidedtour:finalStep.header')}`,
      text: `${i18n.t('guidedtour:finalStep.content1')} <br />
      ${i18n.t('guidedtour:finalStep.content2')}`,
      beforeShowPromise: async (): Promise<any> => {
        // const d = document.getElementById('helpcenter-iframe-open');
        // d?.click();
        const d = document.getElementById('helpcenter-iframe');
        if (d !== null) d.style.display = 'none';
      },
      scrollTo: true,
      buttons: [
        {
          type: 'cancel',
          text: 'Launch Fenus',
        },
      ],
    },
  ];
};
