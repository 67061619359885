import { type ChangeEvent, type FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { startEnrollMultiFactor } from 'services/firebase';
import { useWeb3Auth } from 'services/web3auth';
import { multiFactor } from 'firebase/auth';

// import the toggle button css.
// https://www.w3schools.com/howto/howto_css_switch.asp

const defaultFormFields = {
  tel: '',
  country: '',
};

export const Profile = (): JSX.Element => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const [isChecked, setIsChecked] = useState(false);
  const [phoneInput, setPhoneInput] = useState(false);
  const { logout, firebaseUser, setIsLoading } = useWeb3Auth();
  const { tel, country } = formFields;
  const navigate = useNavigate();

  // Check the button state on the initial render.
  useEffect(() => {
    if (firebaseUser != null) {
      const checkMfaEnrollment = multiFactor(firebaseUser).enrolledFactors;
      if (checkMfaEnrollment.length >= 1) {
        setIsChecked(true);
        setPhoneInput(false);
        setIsLoading(false);
      } else {
        setIsChecked(false);
        setIsLoading(false);
      }
    }
  }, [firebaseUser, setIsLoading]);

  // Watch the toggle button
  useEffect(() => {
    if (!isChecked) {
      setPhoneInput(true);
    }
  }, [isChecked]);

  const resetFormFields = (): void => {
    setFormFields(defaultFormFields);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    console.log('Submitting');

    try {
      const phoneNumber = ''; // phone(tel, {country: country})

      if (tel !== '') {
        await startEnrollMultiFactor(phoneNumber);
      }

      resetFormFields();
      setPhoneInput(false);
      // Redirect to the MFA route when the phone number is successful.
      navigate('/confirm-mfa-enroll');
    } catch (error) {
      console.log(error);
    }
  };

  const handleToggleButton = (e: ChangeEvent<HTMLInputElement>): void => {
    setIsChecked(e.target.checked);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <div>
      <h3>Welcome! </h3>
      <div className="card">
        <p className="mfa-label">Multi-Factor Authentication</p>
        <label className="switch">
          <input type="checkbox" onChange={handleToggleButton} checked={isChecked} />
          <span className="slider"></span>
        </label>
        <form className="form-container" onSubmit={handleSubmit}>
          {isChecked && phoneInput && (
            <>
              <div className="container">
                <input
                  type="tel"
                  name="tel"
                  value={tel}
                  onChange={handleChange}
                  pattern="^\d{1,15}$" // no more than 15 digits
                  required
                  placeholder="Your Phone Number"
                />
                <select name="country" value={country} onChange={handleChange}>
                  <option value="NL">NL</option>
                  <option value="US">US</option>
                </select>
              </div>
              <input id="recaptcha" type="submit" value="Send Phone" />
            </>
          )}
        </form>
      </div>
      <button onClick={logout}>Log Out</button>
    </div>
  );
};

export default Profile;
