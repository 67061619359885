import { type Investment } from 'types/investment';
import './PortfolioBundlePerformance.scss';
import { formatValue } from 'react-currency-input-field';
import { useTranslation } from 'react-i18next';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import infoIcon from 'assets/images/info-icon.svg';
import { BundleInvest } from 'components/Bundles/BundleInvest';
import backArrowIcon from 'assets/images/back-arrow-icon.svg';
import { BundleWithdraw } from 'components/Bundles/BundleWithdraw';
import { type IPriceData } from 'services/priceData';
import { usePriceData } from 'context/priceDataContext';
import { DashboardContext, type IDashboardContext } from 'components/Dashboard/DashboardContext';
import { formatCurrencyPerformance } from 'utils/formatCurrencyPerformance';

const nodata = {
  id: 'nodata',
  afterDraw: function (chart: any) {
    if (chart.data.datasets[0].data.length === 0) {
      // No data is present
      const ctx = chart.ctx;
      const width = chart.width;
      const height = chart.height;
      chart.clear();

      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText('Loading Data...', width / 2, height / 2);
      ctx.restore();
    }
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Filler,
  nodata,
  Legend,
);

// export const options = {
//   responsive: true,
//   pointStyle: false,
//   plugins: {
//     annotation: {
//       annotations: {
//         line1: {
//           yMin: ({ chart }: any) => {
//             // const length = chart.data.datasets[0].data.length - 1;
//             return chart.data.datasets[0].data[0]?.y;
//           },
//           yMax: ({ chart }: any) => {
//             // const length = chart.data.datasets[0].data.length - 1;
//             return chart.data.datasets[0].data[0]?.y;
//           },
//           borderColor: '#423F3F',
//           borderDash: [6, 6],
//           borderDashOffset: 0,
//           borderWidth: 1,
//         },
//       },
//     },
//     tooltip: {
//       callbacks: {
//         title: function (context: any) {
//           const index = context[0].label.lastIndexOf(',');
//           return context[0].label.slice(0, index);
//         },
//         label: function (context: any) {
//           // const length = context.dataset.data.length - 1;
//           const firstValue = context.dataset.data[0].y;
//           const label = ((context.raw.y - firstValue) / firstValue) * 100;
//           return formatValue({ value: label.toString(), decimalScale: 1, suffix: '%' });
//         },
//       },
//     },
//     hover: { mode: null },
//     legend: {
//       display: false,
//     },
//     title: {
//       display: false,
//     },
//   },
//   scales: {
//     y: {
//       display: false, // Hide Y axis labels
//     },
//     x: {
//       type: 'time' as any,
//       time: {
//         unit: 'day',
//       },
//       ticks: {
//         maxRotation: 0,
//       },
//     },
//   },
// };
const chartAreaBorder = {
  id: 'chartAreaBorder',
  beforeDraw(
    chart: {
      ctx: any;
      chartArea: { left: number; top: number; right: number; bottom: number };
    },

    args: any,
    options: { borderColor: any; borderWidth: any },
  ) {
    const {
      ctx,
      chartArea: { left, top, right, bottom },
    } = chart;
    const radius = 10;
    ctx.save();
    ctx.strokeStyle = options.borderColor;
    ctx.lineWidth = options.borderWidth;
    ctx.beginPath();
    ctx.moveTo(left + radius, top);
    ctx.lineTo(right - radius, top);
    ctx.arcTo(right, top, right, top + radius, radius);
    ctx.lineTo(right, bottom - radius);
    ctx.arcTo(right, bottom, right - radius, bottom, radius);
    ctx.lineTo(left + radius, bottom);
    ctx.arcTo(left, bottom, left, bottom - radius, radius);
    ctx.lineTo(left, top + radius);
    ctx.arcTo(left, top, left + radius, top, radius);
    ctx.closePath();
    ctx.stroke();
    ctx.restore();
  },
};

export const options = {
  responsive: true,
  pointStyle: false,
  plugins: {
    annotation: {
      annotations: {
        line1: {
          yMin: ({ chart }: any) => {
            return chart.data.datasets[0].data[0]?.y;
          },
          yMax: ({ chart }: any) => {
            return chart.data.datasets[0].data[0]?.y;
          },
          borderColor: '#423F3F',
          borderDash: [6, 6],
          borderDashOffset: 0,
          borderWidth: 1,
        },
      },
    },
    chartAreaBorder: {
      borderColor: '#ECECEE',
      borderWidth: 1,
      borderDash: [5, 5],
      borderDashOffset: 2,
    },

    tooltip: {
      callbacks: {
        title: function (context: any) {
          const index = context[0].label.lastIndexOf(',');
          return context[0].label.slice(0, index);
        },
        label: function (context: any) {
          const currentValue = context.raw.y;
          return formatValue({ value: currentValue.toString(), decimalScale: 2, suffix: '%' });
        },
      },
    },
    hover: { mode: null },
    nodata,
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    y: {
      display: false,
      ticks: {
        callback: function (value: any, index: number, ticks: any) {
          // eslint-disable-next-line
          return value + '%';
        },
      },
    },
    x: {
      type: 'time' as any,
      time: {
        unit: 'day',
      },
      ticks: {
        maxRotation: 0,
      },
    },
  },
};

interface Props {
  activeInvestment: Investment;
}

const THREE_YEARS = 365 * 3;
const ONE_YEAR = 365;
const NINETY_DAYS = 90;

export const PortfolioBundlePerformance = (props: Props): JSX.Element => {
  const { t } = useTranslation('portfolio');
  const investment = props.activeInvestment;

  const [selectedDates, setSelectedDates] = useState(THREE_YEARS);
  const [showInvest, setShowInvest] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [coinPriceData, setCoinPriceData] = useState<IPriceData[]>();
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const [priceData, setPriceData] = useState<any>();
  const { getBundleData } = usePriceData();

  useEffect(() => {
    const getChartData = async (): Promise<void> => {
      if (props.activeInvestment?.bundle !== undefined) {
        const priceData = getBundleData(props.activeInvestment?.bundle);
        if (priceData === undefined) return;
        setCoinPriceData(priceData._1095);
        setPriceData({
          '1095': priceData._1095,
          '365': priceData._365,
          '90': priceData._90,
        });
      }
    };
    setShowInvest(false);
    setShowWithdraw(false);
    getChartData().catch(console.error);
  }, [props.activeInvestment?.bundle]);

  useEffect(() => {
    if (priceData !== undefined) setCoinPriceData(priceData[`${selectedDates}`]);
  }, [selectedDates, priceData]);

  if (investment === undefined || !dashboardContext.performances[investment?.bundle.name]) {
    return <div></div>;
  }
  const data = {
    datasets: [
      {
        data: coinPriceData,
        fill: true,
        borderColor: '#71FECF',
        backgroundColor: 'rgba(215, 254, 241, 0.5)',
      },
    ],
  };
  ChartJS.register(chartAreaBorder);

  if (!investment || !dashboardContext.performances[investment?.bundle.name].percentagePerformance)
    return <div></div>;

  const performance = dashboardContext.performances[investment?.bundle.name];
  const balancePerformance = parseFloat(performance.balancePerformance || '0');
  const percentagePerformance = parseFloat(performance.percentagePerformance || '0');
  const isPositive = percentagePerformance >= 0;

  return (
    <div className="portfolio-performance flex-grow-1 d-flex flex-column">
      <div className="header d-flex flex-row">
        <div className="flex-grow-1">
          <div className="performance">{t('chart.performance')}</div>
          <div className="name d-none d-md-block">{investment?.bundle.name}</div>
        </div>
        <div className="d-none d-md-block">
          <div className="current-positon">
            {dashboardContext.currencyInfo.symbol}
            {(
              investment.currentInvestmentValue +
              balancePerformance * dashboardContext.currencyInfo.rate
            ).toFixed(2)}{' '}
          </div>
          <div className="d-flex item-performance justify-content-end">
            <div className="value">
              {dashboardContext.performances[investment?.bundle.name].percentagePerformance &&
                formatCurrencyPerformance(
                  dashboardContext.currencyInfo.symbol,
                  parseFloat(
                    dashboardContext.performances[investment?.bundle.name].balancePerformance,
                  ) * dashboardContext.currencyInfo.rate,
                )}
              {dashboardContext.performances[investment?.bundle.name].percentagePerformance !==
                'N/A' && (
                <div className={`percent float-end ${isPositive ? 'positive' : 'negative'}`}>
                  {dashboardContext.performances[investment?.bundle.name].percentagePerformance}%
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="date-selector d-flex">
        <div
          className={classNames(
            'p-1 px-2 mx-1 dates rounded',
            selectedDates === NINETY_DAYS && 'active',
          )}
          onClick={() => {
            setSelectedDates(NINETY_DAYS);
          }}
        >
          14 {t('chart.days')}
        </div>
        <div
          className={classNames(
            'p-1 px-2 mx-1 dates rounded',
            selectedDates === ONE_YEAR && 'active',
          )}
          onClick={() => {
            setSelectedDates(ONE_YEAR);
          }}
        >
          30 {t('chart.days')}
        </div>
        <div
          className={classNames(
            'p-1 px-2 mx-1 dates rounded',
            selectedDates === THREE_YEARS && 'active',
          )}
          onClick={() => {
            setSelectedDates(THREE_YEARS);
          }}
        >
          365 {t('chart.days')}
        </div>
      </div>
      <div className="performance-chart">
        <Line options={options} data={data} />
      </div>
      <div className="notice">
        <img src={infoIcon} alt="Info" width={14} height={14} />
        {t('chart.content')}
      </div>
      {!showInvest && !showWithdraw && (
        <div className="d-flex">
          <button
            className="withdraw-button flex-fill"
            onClick={async () => {
              setShowWithdraw(true);
            }}
          >
            {t('chart.withdraw')}
          </button>
          <button
            className="invest-button flex-fill"
            onClick={() => {
              setShowInvest(true);
            }}
          >
            {t('chart.invest')}
          </button>
        </div>
      )}
      {showInvest && (
        <div className="d-flex ">
          <div className="p-1">
            <button
              className="back-button"
              onClick={() => {
                setShowInvest(false);
              }}
            >
              <img src={backArrowIcon} alt="Back arrow" height={14} width={14} />
            </button>
          </div>
          <BundleInvest bundle={investment.bundle} buttonText="Invest" />
        </div>
      )}
      {showWithdraw && (
        <div className="d-flex ">
          <div className="p-1">
            <button
              className="back-button"
              onClick={() => {
                setShowWithdraw(false);
              }}
            >
              <img src={backArrowIcon} alt="Back arrow" height={14} width={14} />
            </button>
          </div>
          <BundleWithdraw investment={investment} buttonText={t('chart.withdraw') ?? 'Withdraw'} />
        </div>
      )}
    </div>
  );
};
