import { useContext } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { DashboardContext, type IDashboardContext } from './Dashboard/DashboardContext';
import { useWeb3Auth } from 'services/web3auth';
import { useNavigate } from 'react-router-dom';

import './Logout.scss';

export const Logout = (): JSX.Element => {
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const { logout } = useWeb3Auth();
  const navigate = useNavigate();

  const logoutWallet = (): void => {
    console.log('Logout');
    logout().finally(() => {
      navigate('/');
    });
  };

  return (
    <>
      <Modal show={dashboardContext.showLogout} animation={false} className="logout-modal" centered>
        <Modal.Header>
          <Modal.Title>Leave site?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Please note that the changes you made may not be saved.</div>
        </Modal.Body>
        <Modal.Footer className="d-flex">
          <Button className="flex-fill" onClick={logoutWallet}>
            Leave
          </Button>
          <Button
            className="flex-fill"
            variant="link"
            onClick={() => {
              dashboardContext.setShowLogout(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
