import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.scss';
import { auth } from 'config/firebaseConfig';
import { UserServices } from 'services/UserServices';
// import { RecaptchaVerifier } from "firebase/auth";
import { useTranslation } from 'react-i18next';

import startViewImg from 'assets/images/start-view-image.svg';
import google from 'assets/logos/google_logo.svg';
import apple from 'assets/logos/apple_logo.svg';
import facebook from 'assets/logos/facebook_logo.svg';
import metamask from 'assets/logos/metamask_logo.svg';

import { WALLET_ADAPTERS } from '@web3auth/base';

import { useWeb3Auth } from '../services/web3auth';
import {
  GoogleAuthProvider,
  signInWithPopup,
  sendSignInLinkToEmail,
  FacebookAuthProvider,
} from 'firebase/auth';

interface Props {
  setShowTerms: any;
  setShowLoader: any;
}

export const Login = (props: Props): JSX.Element => {
  const { login, web3Auth, setFirebaseUser, loginJWT, getAddress } = useWeb3Auth();
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  const setShowTerms = props.setShowTerms;
  const setShowLoader = props.setShowLoader;

  const loginWithSocial = async (authProvider: any): Promise<void> => {
    if (web3Auth == null) {
      console.log('web3auth not initialized yet');
      return;
    }
    console.log('web3auth initialized');
    const loginRes = await signInWithPopup(auth, authProvider);
    console.log('signed In With Popup');
    setShowLoader(true);
    const address = await getAddress();
    await UserServices.addUserDoc(loginRes, authProvider.providerId, address);
    console.log('User added');

    const idToken = await loginRes.user.getIdToken(true);
    setFirebaseUser(loginRes.user);
    await loginJWT(idToken);
    console.log('got loginJWT');

    // check to see where to redirect logged in user
    const redirectUrl = await UserServices.getUserLoginRedirect();
    if (redirectUrl !== null) {
      navigate(redirectUrl);
      return;
    }

    setTimeout(function () {
      setShowLoader(false);
      console.log('should navigate');
      navigate('/dashboard');
    }, 1000);
  };

  const logInWithEmail = async (email: any): Promise<void> => {
    console.log('Trying to login with', email);

    try {
      const actionCodeSettings = {
        url: (process.env.REACT_APP_domain as string) + '/email-login',
        handleCodeInApp: true,
      };

      // const actionCodeSettings = {
      //   url: "http://localhost:3000/email_login",
      //   handleCodeInApp: true
      // };

      sendSignInLinkToEmail(auth, email, actionCodeSettings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem('emailForSignIn', email);
          navigate('/email-link');
        })
        .catch((err) => {
          console.error(err);
          alert(err.message);
          // const errorCode = error.code;
          // const errorMessage = error.message;
          // ...
        });
    } catch (err: any) {
      console.error(err);
      alert(err.message);
    }
  };

  const handleMetamaskLogin = async (): Promise<void> => {
    try {
      await login(WALLET_ADAPTERS.METAMASK, 'jwt');
    } finally {
      /* empty */
    }
  };
  const isEmail = (email: string): boolean =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const handleEmailLogin = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    try {
      event.preventDefault();
      if (isEmail(email)) {
        await logInWithEmail(email);
      } else {
        console.log('email not valid');
        setError(true);
      }
    } finally {
      /* empty */
    }
  };

  const { t } = useTranslation('home');

  return (
    <div className="login">
      <form
        onSubmit={async (event) => {
          await handleEmailLogin(event);
        }}
      >
        <img className="startViewImg" src={startViewImg} alt="Get started" />
        <input
          required
          type="email"
          className={`form-control ${error ? 'is-invalid' : ''}`}
          id="login_email"
          placeholder="Email"
          onChange={(event) => {
            setEmail(event.target.value);
            setError(false);
          }}
        />
        <p>
          {t('login.warning')}{' '}
          <a href="#" onClick={() => setShowTerms(true)}>
            {t('login.terms')}{' '}
          </a>
          {t('login.warning2')}
        </p>
        <button type="submit" disabled={email === ''} className="btn btn-primary w-100">
          {t('login.btn')}
        </button>
      </form>
      <div className="login-divider d-md-none">{t('login.continue')}</div>

      <div className="container">
        <div className="row gx-md-1">
          <div className="col-3 col-md-4">
            <button
              className="btn-login btn btn-outline-secondary w-100"
              onClick={async () => {
                await loginWithSocial(new GoogleAuthProvider());
              }}
            >
              <img className="social-logo" src={google} width="32" height="32" alt="google login" />
              <span className="d-none d-md-inline">Google</span>
            </button>
          </div>
          <div className="col-3  col-md-4">
            <button
              className="btn-login btn btn-outline-secondary w-100"
              onClick={async () => {
                await loginWithSocial(new FacebookAuthProvider());
              }}
            >
              <img
                className="social-logo"
                src={facebook}
                width="32"
                height="32"
                alt="facebook login"
              />
              <span className="d-none d-md-inline">Facebook</span>
            </button>
          </div>
          <div className="col-3 col-md-4">
            <button
              className="btn-login btn btn-outline-secondary w-100"
              disabled
              onClick={async () => {
                await loginWithSocial('apple');
              }}
            >
              <img className="social-logo" src={apple} width="32" height="32" alt="apple login" />
              <span className="d-none d-md-inline">Apple ID</span>
            </button>
          </div>
          <div className="col-3  col-md-4 mt-md-2 mx-md-auto text-md-center">
            <button
              className="btn btn-outline-secondary"
              disabled
              onClick={async () => {
                await handleMetamaskLogin();
              }}
            >
              <img
                className="social-logo"
                src={metamask}
                width="32"
                height="32"
                alt="metamask login"
              />
              <span className="d-none d-md-inline">Metamask</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
