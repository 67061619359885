import { useContext, useState } from 'react';
import './BundleInvest.scss';
import { useTranslation } from 'react-i18next';

import plus from 'assets/images/plus_icon.svg';
import { type IDashboardContext, DashboardContext } from 'components/Dashboard/DashboardContext';
import { type BundleData } from 'types/bundleData';
import CurrencyInput from 'react-currency-input-field';
import { toast } from 'react-toastify';
import { getCurrencySymbol } from '../../utils/currencyUtils';
import toastWarningIcon from 'assets/images/toast-warning-icon.svg';

interface Props {
  bundle: BundleData;
  isMobile?: boolean;
  buttonText?: string;
}

export const BundleInvest = (props: Props): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const [investValue, setInvestValue] = useState(0);
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const [value, setValue] = useState<string | number>();

  function handleClick(bundle: BundleData): void {
    const items =
      dashboardContext.shoppingCartItems.length > 0 ? dashboardContext.shoppingCartItems : [];

    if (
      investValue === null ||
      investValue === undefined ||
      typeof investValue !== 'number' ||
      investValue <= 0
    ) {
      toast.warn(t('invest.statement'), {
        icon: () => <img src={toastWarningIcon} />,
      });
      return;
    }

    if (investValue >= dashboardContext.available) {
      toast.warn(t('invest.failed1'), {
        icon: () => <img src={toastWarningIcon} />,
      });
      return;
    }

    // if (investValue < 50) {
    //   toast.warn(t('invest.failed3'), {
    //     icon: () => <img src={toastWarningIcon} />,
    //   });
    //   return;
    // }

    if (investValue > 5000) {
      toast.warn(t('invest.failed4'), {
        icon: () => <img src={toastWarningIcon} />,
      });
      return;
    }

    const existingBundle = dashboardContext.shoppingCartItems.find(
      (item) => item.bundle.name === bundle.name,
    );

    if (existingBundle === undefined) {
      dashboardContext.setShoppingCartItems([
        ...items,
        {
          bundle,
          investmentValue: investValue / dashboardContext.currencyInfo.rate,
          editing: false,
        },
      ]);
    } else {
      existingBundle.investmentValue += investValue / dashboardContext.currencyInfo.rate;
      dashboardContext.setShoppingCartItems(items.slice());
    }

    if (props.isMobile !== true) {
      dashboardContext.setShowShoppingCart(true);
    }
    toast.dismiss();
    setInvestValue(0);
    setValue(undefined);
  }

  return (
    <div className="d-flex bundle-invest">
      <div className="p-md-1 dollar">{getCurrencySymbol(dashboardContext.currency)}</div>
      <div className="p-1 flex-grow-1">
        <CurrencyInput
          className="invest-input"
          value={value ?? ''}
          step={0.1}
          decimalScale={2}
          decimalsLimit={2}
          disabled={dashboardContext.showGuidedTour}
          onValueChange={(value, name, values) => {
            if (values?.float !== undefined && values?.float !== null) {
              setInvestValue(values.float);
              setValue(value);
            } else {
              setInvestValue(0);
              setValue('');
            }
          }}
        />
      </div>
      <div className="p-1">
        {props.buttonText !== undefined ? (
          <div
            className="invest-text col d-flex align-items-center justify-content-center"
            onClick={() => {
              if (!dashboardContext.showGuidedTour) {
                handleClick(props.bundle);
              }
            }}
          >
            {props.buttonText}
          </div>
        ) : (
          <div
            className="invest-plus col d-flex align-items-center justify-content-center"
            onClick={() => {
              if (!dashboardContext.showGuidedTour) {
                handleClick(props.bundle);
              }
            }}
          >
            <img src={plus} alt="invest" width={17} height={17} />
          </div>
        )}
      </div>
    </div>
  );
};
