import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// images
import tour_selected from 'assets/images/tour_selected.svg';
import tour_empty from 'assets/images/tour_empty.svg';
import clock_icon from 'assets/images/clock-icon.svg';

import './TourSelectCard.scss';

interface Props {
  header: string;
  description: string;
  setSelectedTour: any;
  selectedTour: boolean;
  time: string;
}

export const TourSelectCard = (props: Props): JSX.Element => {
  return (
    <div
      className={`tour-option ${props.selectedTour ? 'tour-highlight' : ''}`}
      onClick={() => props.setSelectedTour(!props.selectedTour)}
    >
      <Container>
        <Row>
          <Col md={2} xl={1} className="d-none d-md-block">
            <img src={props.selectedTour ? tour_selected : tour_empty} alt="selected" />
          </Col>
          <Col xs={12} md={10} xl={11} className="order-md-5 order-1">
            <h2>
              {props.header}
              <span className="time float-end">
                <img src={clock_icon} alt="clock icon" /> {props.time}
              </span>
            </h2>
            <p dangerouslySetInnerHTML={{ __html: props.description }}></p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
