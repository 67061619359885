import { useContext, useEffect, useState } from 'react';
import { DashboardContext, type IDashboardContext } from '../DashboardContext';
import questionMark from 'assets/images/desktop/question-mark.svg';
import shoppingCart from 'assets/images/desktop/shopping-cart.svg';
import profile from 'assets/images/profile-inverse-icon.svg';
// import { useTranslation } from 'react-i18next';

import './DashboardHeader.scss';
import { DashboardShoppingCart } from './DashboardShoppingCart';
import { DashboardProfilePopup } from './DashboardProfilePopup';
import { DashboardQuestMarkPopup } from './DashboardQuestMarkPopup';

// import { Link } from 'react-router-dom';

interface Props {
  headerText: string;
}

export const DashboardHeader = (props: Props): JSX.Element => {
  // const { t } = useTranslation('dashboard');
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const [itemCount, setItemCount] = useState(
    dashboardContext.shoppingCartItems?.length !== 0
      ? dashboardContext.shoppingCartItems?.length
      : 0,
  );

  const handleShowCart = (): void => {
    dashboardContext.setShowShoppingCart(true);
  };

  const handleShowProfile = (): void => {
    dashboardContext.setShowProfilePopup(true);
  };

  const handleShowQuestionMarkPopup = (): void => {
    dashboardContext.setShowQuestionMarkPopup(true);
  };

  useEffect(() => {
    setItemCount(dashboardContext.shoppingCartItems?.length);
  }, [dashboardContext.shoppingCartItems]);

  return (
    <>
      <div className="dashboard-header container-fluid">
        <div className="row g-0">
          <div className="col-8">
            <h2>{props.headerText}</h2>
          </div>
          <div className="col-4">
            <div className="text-end">
              <div
                role="button"
                id="showHelpPopup"
                className="d-inline-block text-end profile"
                onClick={handleShowQuestionMarkPopup}
              >
                <img src={questionMark} alt="question mark" height={43} width={43} />
              </div>
              <div
                role="button"
                id="showProfilePopup"
                className="d-inline-block text-end profile"
                onClick={handleShowProfile}
              >
                <img src={profile} alt="Profile" height={43} width={43} />
              </div>
              <div
                role="button"
                id="showShoppingCart"
                className="d-inline-block text-end shopping-cart"
                onClick={handleShowCart}
              >
                <img src={shoppingCart} alt="Shopping Cart" height={43} width={43} />
                <span
                  className={`${
                    itemCount > 0 ? '' : 'd-none'
                  } position-absolute top-0 start-50  badge rounded-pill`}
                >
                  {itemCount}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DashboardShoppingCart />
      <DashboardProfilePopup />
      <DashboardQuestMarkPopup />
    </>
  );
};
