/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Col, Container, Row } from 'react-bootstrap';
import questionMark from 'assets/images/question-mark-mobile.svg';

// import helpCircle from 'assets/images/help-circle.svg';
// import tourIcon from 'assets/images/tour-icon.svg';
import { useTranslation } from 'react-i18next';

import './DashboardMobileHeader.scss';
import { WalletOverviewMobile } from './WalletOverviewMobile';

interface Props {
  setShowUserProfile: any;
  handleHelpClick: () => void;
}

export const DashboardMobileHeader = ({
  setShowUserProfile,
  handleHelpClick,
}: Props): JSX.Element => {
  const { t } = useTranslation('dashboard');
  return (
    <div className="mobile-header">
      <Container>
        <div className="d-flex justify-content-between mb-3 ">
          <div className="welcome text-start">{t('header.welcome')},</div>
          <div
            onClick={handleHelpClick}
            id="help-img-container"
            className="text-end d-flex help-img-container"
          >
            <img className="question-mark-img" src={questionMark} alt="question mark" />
            <div>Help</div>
          </div>
        </div>
        <Row>
          <Col>
            <WalletOverviewMobile setShowUserProfile={setShowUserProfile} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
