import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './StartFAQ.scss';

export const StartFAQ = (): JSX.Element => {
  const { t } = useTranslation('home');
  return (
    <div className="start-faq">
      <Accordion defaultActiveKey="-1" flush className="">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t('startfaq.header4')}</Accordion.Header>
          <Accordion.Body>
            {t('startfaq.content4')} <br /> <br />
            <ul>
              <li>{t('startfaq.content4_3')}</li>
              <li className="mt-2">{t('startfaq.content4_4')}</li>
              <li className="mt-2">{t('startfaq.content4_5')}</li>
            </ul>
            {t('startfaq.content4_2')} <br /> <br /> {t('startfaq.content4_1')}{' '}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{t('startfaq.header2')}</Accordion.Header>
          <Accordion.Body>{t('startfaq.content2')}</Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>{t('startfaq.header3')}</Accordion.Header>
          <Accordion.Body>{t('startfaq.content3')}</Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};
