import React, { useEffect, useState } from 'react';
import { auth } from 'config/firebaseConfig';
import {
  isSignInWithEmailLink,
  signInWithEmailLink,
  getMultiFactorResolver,
  PhoneMultiFactorGenerator,
  sendSignInLinkToEmail,
} from 'firebase/auth';
import { UserServices } from 'services/UserServices';
import { useWeb3Auth } from 'services/web3auth';
import Layout from 'components/Layout/Layout';
import { Loading } from 'components/Layout/Loading';
import logo from 'assets/images/fenus_logo_no_text.svg';

import './EmailLogin.scss';
import useBodyClass from 'hooks/useBodyClass';
import { useNavigate } from 'react-router-dom';

export const EMailLogin = (): JSX.Element => {
  const { loginJWT, web3Auth, getAddress } = useWeb3Auth();
  // const { login, web3Auth } = useWeb3Auth();
  const [emailAddr, setEmailAddr] = useState('');
  const [showLoader, setShowLoader] = useState(true);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const checkEmailSignIn = async (email: string): Promise<void> => {
    if (web3Auth == null) {
      console.log('web3auth not initialized yet');
      return;
    }

    if (isSignInWithEmailLink(auth, window.location.href)) {
      signInWithEmailLink(auth, email, window.location.href)
        .then(async function (loginResult) {
          window.localStorage.removeItem('emailForSignIn');
          const address = await getAddress();
          await UserServices.addUserDoc(loginResult, 'EmailLink', address);
          const idToken = await loginResult.user.getIdToken(true);
          await loginJWT(idToken);
          // await login();

          console.log('logged in');
        })
        .catch(function (error) {
          if (error.code === 'auth/multi-factor-auth-required') {
            // The user is a multi-factor user. Second factor challenge is required.
            const resolver = getMultiFactorResolver(auth, error);
            if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
              // User selected a phone second factor.
              // ...
              console.log('Not implemented yet');
            } else {
              // Unsupported second factor.
              // Note that only phone second factors are currently supported.
              console.log('Unsupported second factor');
            }
          } else {
            console.log('Error signing in');
            console.log(error);
          }
        });
    }
  };
  const logInWithEmail = async (email: any): Promise<void> => {
    console.log('Trying to login with', email);

    try {
      const actionCodeSettings = {
        url: (process.env.REACT_APP_domain as string) + '/email-login',
        handleCodeInApp: true,
      };

      sendSignInLinkToEmail(auth, email, actionCodeSettings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem('emailForSignIn', email);
          navigate('/email-link');
        })
        .catch((err) => {
          console.error(err);
          alert(err.message);
          // const errorCode = error.code;
          // const errorMessage = error.message;
          // ...
        });
    } catch (err: any) {
      console.error(err);
      alert(err.message);
    }
  };
  const isEmail = (email: string): boolean =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const handleEmailLogin = async (email: string): Promise<void> => {
    if (isEmail(email)) {
      await logInWithEmail(email);
    } else {
      console.log('email not valid');
      setError(true);
    }
  };

  useEffect(() => {
    console.log('Checking email login');
    const email = window.localStorage.getItem('emailForSignIn');

    if (email !== null) {
      checkEmailSignIn(email).catch(() => {});
    } else {
      console.log('Email not found');
      setShowLoader(false);
    }
  }, [web3Auth]);

  const submitEmail = (): void => {
    handleEmailLogin(emailAddr).catch(() => {});
  };

  const confirmEmailView = (
    <div className="mx-auto text-center email-login">
      <div className="logo d-none d-md-block">
        <img src={logo} alt="Fenus logo" width={29} height={35} />
      </div>
      <h1>Enter your email</h1>
      <p>
        We notice that you’re using a different device to open the verification link. Please enter
        again your email here to complete the email verification.
      </p>

      <input
        required
        type="email"
        className={`form-control ${error ? 'is-invalid' : ''}`}
        id="login_email"
        placeholder="Email"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setEmailAddr(e.currentTarget.value);
          setError(false);
        }}
      />
      <button
        className="btn btn-primary w-100"
        disabled={emailAddr.trim() === ''}
        onClick={submitEmail}
      >
        Submit
      </button>
      <p className="help">
        Need help? visit our <a href="https://fenus.io/help/">Fenus Help Center.</a>
      </p>
    </div>
  );

  useBodyClass(['start-body']);
  return <Layout>{showLoader ? <Loading /> : confirmEmailView}</Layout>;
};
