import { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-moment';
import { Line } from 'react-chartjs-2';

import annotationPlugin from 'chartjs-plugin-annotation';

import './BundlePerformance.scss';
import backIcon from 'assets/images/bundle-back-icon.svg';
import infoIcon from 'assets/images/info-icon.svg';
import { type IPriceData } from 'services/priceData';
import { type BundleData } from 'types/bundleData';
import { formatValue } from 'react-currency-input-field';
import { usePriceData } from 'context/priceDataContext';

const nodata = {
  id: 'nodata',
  afterDraw: function (chart: any) {
    if (chart.data.datasets[0].data.length === 0) {
      // No data is present
      const ctx = chart.ctx;
      const width = chart.width;
      const height = chart.height;
      chart.clear();

      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText('Loading Data...', width / 2, height / 2);
      ctx.restore();
    }
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  TimeScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  nodata,
  annotationPlugin,
);

// export const options = {
//   responsive: true,
//   pointStyle: false,
//   plugins: {
//     annotation: {
//       annotations: {
//         line1: {
//           yMin: ({ chart }: any) => {
//             // const length = chart.data.datasets[0].data.length - 1;
//             return chart.data.datasets[0].data[0]?.y;
//           },
//           yMax: ({ chart }: any) => {
//             // const length = chart.data.datasets[0].data.length - 1;
//             return chart.data.datasets[0].data[0]?.y;
//           },
//           borderColor: '#423F3F',
//           borderDash: [6, 6],
//           borderDashOffset: 0,
//           borderWidth: 1,
//         },
//       },
//     },
//     tooltip: {
//       callbacks: {
//         title: function (context: any) {
//           const index = context[0].label.lastIndexOf(',');
//           return context[0].label.slice(0, index);
//         },
//         label: function (context: any) {
//           // const length = context.dataset.data.length - 1;
//           const firstValue = context.dataset.data[0].y;
//           const label = ((context.raw.y - firstValue) / firstValue) * 100;
//           return formatValue({ value: label.toString(), decimalScale: 2, suffix: '%' });
//         },
//       },
//     },
//     hover: { mode: null },
//     nodata,
//     legend: {
//       display: false,
//     },
//     title: {
//       display: false,
//     },
//   },
//   scales: {
//     y: {
//       display: false, // Hide Y axis labels
//       ticks: {
//         // Include a dollar sign in the ticks
//         callback: function (value: any, index: number, ticks: any) {
//           // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
//           return value + '%';
//         },
//       },
//     },
//     x: {
//       type: 'time' as any,
//       time: {
//         unit: 'day',
//       },
//       ticks: {
//         maxRotation: 0,
//       },
//     },
//   },
// };

export const options = {
  responsive: true,
  pointStyle: false,
  plugins: {
    annotation: {
      annotations: {
        line1: {
          yMin: ({ chart }: any) => {
            return chart.data.datasets[0].data[0]?.y;
          },
          yMax: ({ chart }: any) => {
            return chart.data.datasets[0].data[0]?.y;
          },
          borderColor: '#423F3F',
          borderDash: [6, 6],
          borderDashOffset: 0,
          borderWidth: 1,
        },
      },
    },
    tooltip: {
      callbacks: {
        title: function (context: any) {
          const index = context[0].label.lastIndexOf(',');
          return context[0].label.slice(0, index);
        },
        label: function (context: any) {
          const currentValue = context.raw.y;
          return formatValue({ value: currentValue.toString(), decimalScale: 2, suffix: '%' });
        },
      },
    },
    hover: { mode: null },
    nodata,
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
  },
  scales: {
    y: {
      display: false,
      ticks: {
        callback: function (value: any, index: number, ticks: any) {
          // eslint-disable-next-line
          return value + '%';
        },
      },
    },
    x: {
      type: 'time' as any,
      time: {
        unit: 'day',
      },
      ticks: {
        maxRotation: 0,
      },
    },
  },
};

interface Props {
  activeTab: string;
  setExpanded: any;
  bundle: BundleData;
}

const THREE_YEARS = 365 * 3;
const ONE_YEAR = 365;
const NINETY_DAYS = 90;

export const BundlePerformance = (props: Props): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const [selectedDates, setSelectedDates] = useState(THREE_YEARS);
  const [coinPriceData, setCoinPriceData] = useState<IPriceData[]>();
  // const [allCoinPriceData, setAllCoinPriceData] = useState<IPriceData[]>();

  const [priceData, setPriceData] = useState<any>();
  const { getBundleData } = usePriceData();

  useMemo(() => {
    if (props.activeTab === 'first') {
      const getChartData = async (): Promise<void> => {
        // const priceData = await PriceData.getBundlePriceData(props.bundle);
        const priceData = getBundleData(props.bundle);
        if (priceData === undefined) return;
        setCoinPriceData(priceData._1095);
        setPriceData({
          '1095': priceData._1095,
          '365': priceData._365,
          '90': priceData._90,
        });
        // setAllCoinPriceData(priceData);
      };

      getChartData().catch(console.error);
    }
  }, [props.activeTab]);

  useEffect(() => {
    if (priceData !== undefined) setCoinPriceData(priceData[`${selectedDates}`]);
  }, [selectedDates, priceData]);

  // const calculatePercentageDifference: (data: any[]) => any[] = (data: any[]) => {
  //   const newData = [];
  //   for (let i = 0; i < data.length; i++) {
  //     if (i === 0) {
  //       newData.push({ ...data[i], y: 0 });
  //     } else {
  //       const percentageDifference = ((data[i].y - data[i - 1].y) / data[i - 1].y) * 100;
  //       newData.push({ ...data[i], y: percentageDifference });
  //     }
  //   }
  //   return newData;
  // };

  const data = {
    datasets: [
      {
        data: coinPriceData,
        // data: calculatePercentageDifference([
        //   { id: 6032024, y: 5, x: new Date('06.03.2024') },
        //   { id: 7032024, y: 15, x: new Date('07.03.2024') },
        //   { id: 8032024, y: 10, x: new Date('08.03.2024') },
        //   { id: 9032024, y: 8, x: new Date('09.03.2024') },
        //   { id: 10032024, y: 5, x: new Date('10.03.2024') },
        //   { id: 11032024, y: 12, x: new Date('11.03.2024') },
        // ]),
        fill: true,
        borderColor: '#71FECF',
        backgroundColor: 'rgba(215, 254, 241, 0.5)',
      },
    ],
  };

  return (
    <div className="bundle-performance">
      <h5
        onClick={() => {
          props.setExpanded(false);
        }}
      >
        <img
          className="bundle-back d-none d-md-inline-block"
          src={backIcon}
          alt="Back"
          height={18}
        />
        {t('performance.past')}
      </h5>
      <div className="date-selector d-flex">
        <div
          className={classNames('p-1 flex-fill dates', selectedDates === NINETY_DAYS && 'active')}
          onClick={() => {
            setSelectedDates(NINETY_DAYS);
          }}
        >
          90 {t('performance.days')}
        </div>
        <div
          className={classNames('p-1 flex-fill dates', selectedDates === ONE_YEAR && 'active')}
          onClick={() => {
            setSelectedDates(ONE_YEAR);
          }}
        >
          365 {t('performance.days')}
        </div>
        <div
          className={classNames('p-1 flex-fill dates', selectedDates === THREE_YEARS && 'active')}
          onClick={() => {
            setSelectedDates(THREE_YEARS);
          }}
        >
          3 {t('performance.years')}
        </div>
      </div>

      <div className="performance-chart">
        <Line options={options} data={data} />
        {coinPriceData != null && coinPriceData?.length <= 0 && <p>Loading</p>}
      </div>
      <div className="d-flex align-items-center mx-auto px-2">
        <div>
          <img src={infoIcon} alt="info" width={14} height={14} />
        </div>
        <div className="performance-text">{t('performance.content')}</div>
      </div>
    </div>
  );
};
