import { type BigNumber, Contract, type ethers } from 'ethers';
import { coinsConfigs } from '../blockchain/tokensConfig';
import { FenusIndexTokenABI } from '../blockchain/abis/FenusIndexTokenABI';
import { toast } from 'react-toastify';

/**
 * Function to fetch balance of user index token of current bundle.
 *
 * @param {string} bundleName - The name of the investment bundle.
 * @param {string} address - The user's address.
 * @param {ethers.Signer} signer - The signer instance.
 * @returns {Promise<BigNumber | null>} - The balance of the user's index token or null in case of an error.
 */

export const useGetUserIndexTokenBalance = async (
  bundleName: string,
  address: string,
  signer: ethers.Signer,
): Promise<BigNumber | null> => {
  try {
    const tokenAddress = coinsConfigs[bundleName.toLowerCase()].address;
    const FenusIndexToken = new Contract(tokenAddress, FenusIndexTokenABI, signer);
    const rawBalance = await FenusIndexToken.balanceOf(address);
    return rawBalance;
  } catch (err) {
    console.error('Error fetching token balance:', err);
    toast.error('Failed to fetch token balance');
    return null;
  }
};
