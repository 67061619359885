import { POLYGON_ADDRESSES } from '../blockchain/addresses/Polygon';
import axios from 'axios';
import { stringify } from 'qs';
import { BigNumber } from 'ethers';

export const getIssuanceSwapQuote = async (
  components: string[],
  positions: any[],
  isIssuing: boolean,
): Promise<{ quotes: any[]; totalSellAmount: BigNumber }> => {
  let totalSellAmount = BigNumber.from(0);

  const quotes = await Promise.all(
    components.map(async (component, index) => {
      const positionParsed = BigNumber.from(positions[index]._hex).toString();
      const params = isIssuing
        ? {
            buyToken: component,
            sellToken: POLYGON_ADDRESSES.EurE,
            buyAmount: positionParsed,
            slippagePercentage: 0.05,
          }
        : {
            buyToken: POLYGON_ADDRESSES.EurE,
            sellToken: component,
            sellAmount: positionParsed,
            slippagePercentage: 0.05,
          };

      const zeroExQuery = `https://polygon.api.0x.org/swap/v1/quote?${stringify(params)}`;
      const response = await axios.get(zeroExQuery, {
        headers: { '0x-api-key': process.env.REACT_APP_zero_x_API_KEY },
      });
      console.log('response', response.data);

      if (!isIssuing) {
        totalSellAmount = totalSellAmount.add(BigNumber.from(response.data.buyAmount));
      }

      return response.data.data;
    }),
  );

  return { quotes, totalSellAmount };
};
