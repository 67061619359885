import { useContext, useEffect, useState } from 'react';
import { DashboardContext, type IDashboardContext } from '../DashboardContext';
import { Button } from 'react-bootstrap';
import fenusLogo from '../../../assets/images/fenus_logo.svg';
import backIcon from '../../../assets/images/backIcon.svg';
import { SuccessTransaction } from 'components/SuccessTransaction';

import './PortfolioWithdrawMobile.scss';

import { useTranslation } from 'react-i18next';

import { InfoTooltip } from 'components/InfoTooltip';
import { type WithdrawalData } from 'types';
import { useWeb3Auth } from '../../../services/web3auth';
import { useMutationRedeemSetToken } from '../../../hooks/useMutationRedeemSetToken';
import { useIndexTokenPrice } from '../../../hooks/useIndexTokenPrice';

interface IFees {
  serviceFee: number;
  networkFee: number;
  totalFees: number;
}

const truncate = (str: string): string => {
  return str.length > 10 ? str.substring(0, 9) + '...' : str;
};

export const PortfolioWithdrawMobile = (): JSX.Element => {
  const { t } = useTranslation('portfolio');
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const [subTotal, setSubTotal] = useState(0);
  const [fees, setFees] = useState<IFees>({
    serviceFee: 0,
    networkFee: 0,
    totalFees: 0,
  });
  const [cartItems, setCartItems] = useState<JSX.Element[]>([]);
  const [processedCartItems, setProcessedCartItems] = useState<any[]>([]);
  const [openFees, setOpenFees] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [processingWithdrawal, setProcessingWithdrawal] = useState(false);
  const [showSuccessTransaction, setShowSuccessTransaction] = useState<boolean>(false);

  const { provider, wb3athProvider } = useWeb3Auth();
  const web3AuthProviders = { provider, wb3athProvider };
  const { redeemSetToken } = useMutationRedeemSetToken(web3AuthProviders);
  const { getIndexTokenPrice } = useIndexTokenPrice();

  const returnCloseSuccesstransaction: () => void = () => {
    setShowSuccessTransaction(false);
  };

  const handleSubmit = async (): Promise<void> => {
    setProcessingWithdrawal(true);
    setProcessedCartItems([dashboardContext.withdrawInvestment]);
    if (!dashboardContext.withdrawInvestment.investment) {
      console.error('No withdraw bundle selected');
      return;
    }
    const indexTokenPrice = await getIndexTokenPrice(
      dashboardContext.withdrawInvestment.investment?.bundle.name,
    );
    if (!indexTokenPrice) {
      console.error('No index token price');
      return;
    }
    await redeemSetToken(dashboardContext.withdrawInvestment, indexTokenPrice);
    setShowSuccessTransaction(true);
    setProcessingWithdrawal(false);
  };

  const handleClose = (): void => {
    if (!processingWithdrawal) dashboardContext.setShowWithdraw(false);
  };

  // const getPerformanceFigures = (performance: number): string => {
  //   const prefix = performance >= 0 ? '+' : '';
  //   return formatValue({
  //     value: performance.toString(),
  //     decimalScale: 2,
  //     prefix,
  //   });
  // };

  useEffect(() => {
    const getItem = (item: WithdrawalData): JSX.Element[] => {
      const itemDivs: JSX.Element[] = [];
      let runningTotal = 0;

      if (item.investment === undefined) {
        return itemDivs;
      }

      runningTotal += item.withdrawAmount;
      itemDivs.push(
        <div key={1} className="item-container">
          <div className="d-flex item-header">
            <div className="flex-grow-1">{item.investment.bundle.name}</div>
            <div className="">
              {/* €{formatValue({ value: item.withdrawAmount.toString(), decimalScale: 2 })} */}
              {dashboardContext.currencyInfo.symbol}
              {(item.withdrawAmount * dashboardContext.currencyInfo.rate).toFixed(2)}
            </div>
          </div>
          {/* <div className="d-flex item-performance">
            <div className="flex-grow-1">
              {item.investment.bundle.performance === 0
                ? getPerformanceFigures(item.investment.bundle.performance)
                : '--'}
              %
            </div>
          </div> */}
        </div>,
      );

      const serviceFee = parseFloat((runningTotal * 0).toFixed(2));
      const networkFee = parseFloat(dashboardContext.txFees.toFixed(2));
      const totalFees = serviceFee + dashboardContext.txFees;
      setFees({
        serviceFee,
        networkFee,
        totalFees,
      });
      setSubTotal(runningTotal - totalFees);
      return itemDivs;
    };

    setCartItems(getItem(dashboardContext.withdrawInvestment));
  }, [dashboardContext.withdrawInvestment]);

  return (
    <>
      {showSuccessTransaction && (
        <SuccessTransaction
          close={returnCloseSuccesstransaction}
          withdraw={true}
          items={processedCartItems}
          failedTxs={{}}
        />
      )}
      {!openTerms && (
        <div className="withdrawal-container">
          <div className="header-body-container">
            <div className="logo-back-container d-flex">
              <button
                className="back-container"
                onClick={handleClose}
                disabled={processingWithdrawal}
              >
                <img src={backIcon} alt="back icon" />
              </button>
              <div className="logo-container">
                <img src={fenusLogo} alt="fenus logo" />
              </div>
            </div>
            <div className="header">{t('withdraw.review')}</div>

            <div className="modal-body-container">
              <div className="d-flex required-payment">
                <div className="flex-grow-1">{t('withdraw.getback')}</div>
                <div>
                  {/* €{formatValue({ value: subTotal.toString(), decimalScale: 2 })} */}~{' '}
                  {dashboardContext.currencyInfo.symbol}
                  {(subTotal * dashboardContext.currencyInfo.rate).toFixed(2)}
                </div>
              </div>
              <div className="cart-items">{cartItems}</div>
              <div className="transaction-fees">
                <div
                  className="fees-header d-flex"
                  onClick={() => {
                    setOpenFees(!openFees);
                  }}
                  aria-controls="open fees"
                  aria-expanded={openFees}
                >
                  <div className="flex-grow-1">Transaction fees</div>
                  <div>
                    {/* €{formatValue({ value: fees.totalFees.toString(), decimalScale: 2 })} */}
                    {dashboardContext.currencyInfo.symbol}
                    {(fees.totalFees * dashboardContext.currencyInfo.rate).toFixed(2)}
                    <InfoTooltip tooltip={t('checkout.content1')} />
                  </div>
                  {/* <div className="fees-icon">
                    {openFees ? (
                      <img src={closeIcon} alt="close" width={15} height={7.5} />
                    ) : (
                      <img src={openIcon} alt="open" width={15} height={7.5} />
                    )}
                  </div> */}
                </div>
                {/* <Collapse in={openFees} className="details-container">
                  <div>
                    <div className="d-flex fee-details">
                      <div className="flex-grow-1 fee-details-dito">
                        {t('checkout.fees2')} <InfoTooltip tooltip={t('checkout.fees3')} />{' '}
                      </div>
                      <div className="details-amount">
                        {dashboardContext.currencyInfo.symbol}
                        {(fees.serviceFee * dashboardContext.currencyInfo.rate).toFixed(2)}
                      </div>
                    </div>
                    <div className="d-flex fee-details">
                      <div className="flex-grow-1 fee-details-dito">
                        {t('checkout.fees4')} <InfoTooltip tooltip={t('checkout.content1')} />{' '}
                      </div>
                      <div className="details-amount">
                        {dashboardContext.currencyInfo.symbol}
                        {(
                          (fees.networkFee + fees.swapFee) *
                          dashboardContext.currencyInfo.rate
                        ).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </Collapse> */}
              </div>
              <div className="destination-wallet d-flex">
                <div className="flex-grow-1 wallet-text">{t('withdraw.walletID')}</div>
                <div className="wallet-id">{truncate(dashboardContext.account)}</div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="submit-button-container">
              <Button
                className="process-button submit-button"
                onClick={handleSubmit}
                disabled={processingWithdrawal}
              >
                {t('withdraw.submit')}
              </Button>
            </div>
            <div className="invest-terms">
              {t('withdraw.warning1')}{' '}
              <span
                className="tnc-link"
                onClick={() => {
                  // dashboardContext.setShowWithdraw(false);
                  setOpenTerms(true);
                }}
              >
                {t('withdraw.warning2')}
              </span>
            </div>
          </div>
        </div>
      )}
      {openTerms && (
        <div className="terms-modal-mobile">
          <div className="terms-header">Terms and Conditions</div>
          <div className="terms-text">
            By using Fenus, you agree to assume full responsibility for any risks associated with
            your use, including market volatility, smart contract vulnerabilities, and loss of
            funds. All transactions on our platform are final and irreversible, and we are not
            responsible for any errors or mistakes. Prohibited activities include illegal actions
            such as money laundering or terrorist financing. Our platform is provided &quot;as
            is&quot; without any warranties, and we are not liable for any damages arising from your
            use. We reserve the right to update these terms without prior notice, and these terms
            shall be governed by the laws of the applicable jurisdiction.
          </div>
          <div className="terms-footer">
            <Button
              className="w-100 terms-button"
              onClick={() => {
                dashboardContext.setShowWithdraw(true);
                setOpenTerms(false);
              }}
            >
              I got it
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
