import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { StartView } from './StartView';
import KycImage from 'assets/images/kyc-image.svg';
import { useTranslation } from 'react-i18next';
import helpCenterIcon from 'assets/images/help-center-icon.svg';
import infoIcon from 'assets/images/info-icon.svg';

import './SelectProfile.scss';
import { ContactButton } from './ContactButton';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { UserServices } from 'services/UserServices';
// import { useNavigate } from 'react-router-dom';

export const KYCScreen = (): JSX.Element => {
  const { t } = useTranslation('dashboard');
  // const navigate = useNavigate();

  const onHandleKycUpdate = async () => {
    window.open('https://wy7p4n0euzk.typeform.com/to/d4Z0TtR9 ', '_blank');

    await UserServices.updateKycStatus('pending');
    // navigate('/select-profile');
  };

  return (
    <Container className="kyc-details ">
      <Row>
        <Col sm={12} md={6}>
          <StartView step={1}>
            <h1>
              {t('kyc.header')}{' '}
              <OverlayTrigger
                trigger="hover"
                overlay={
                  <Tooltip className="" id="kyc-info">
                    {t('kyc.userinfo')}
                  </Tooltip>
                }
                placement="auto"
              >
                <img className="info-image" src={infoIcon} alt="" />
              </OverlayTrigger>
            </h1>
            <div className="kyc-content">
              <p>{t('kyc.content1')}</p> <br />
              <p>{t('kyc.content2')}</p> <br />
              <p>{t('kyc.content3')}</p> <br />
              <p className="msg">{t('kyc.content4')}</p> <br />
            </div>
            <div className="mt-4 d-none d-md-block">
              <ContactButton
                icon={helpCenterIcon}
                text={t('kyc.helpcenter')}
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                link={`${process.env.REACT_APP_website_domain}/help/`}
                small={false}
              />
            </div>
          </StartView>
        </Col>
        <Col sm={12} className="right-section" md={6}>
          <div className="">
            <img className="right-img" src={KycImage} alt="" />

            <button onClick={onHandleKycUpdate} className="btn btn-primary fill-form-btn w-100">
              {t('kyc.btn')}
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
