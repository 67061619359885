import { useEffect, useState } from 'react';
import { Login } from 'components/Login';
import i18n from 'i18next';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Style
import './Home.scss';
import { Terms } from 'components/Terms';
import Layout from 'components/Layout/Layout';
import { StartFAQ } from 'components/StartFAQ';
import { Loading } from 'components/Layout/Loading';
import { StartView } from 'components/StartView';
import FAQ from 'components/FAQ';
import useBodyClass from 'hooks/useBodyClass';
import { ContactButtons } from 'components/ContactButtons';
// import { SelectProfile } from 'components/SelectProfile';

import { useTranslation } from 'react-i18next';

interface IProps {
  language: string | undefined;
}

export const Home = ({ language }: IProps): JSX.Element => {
  const [showLoader, setShowLoader] = useState(false);
  const [showTerms, setShowTerms] = useState(false);

  const { t } = useTranslation('home');

  useEffect(() => {
    const langchange: () => void = async () => {
      await i18n.changeLanguage(language);
    };
    langchange();
  }, []);

  const loggedOutView = (
    <Container>
      <Row>
        <Col sm={12} md={6}>
          <StartView step={0}>
            <h1>{t('startview.header')}</h1>
            <p className="d-md-none">{t('startview.content')}</p>
          </StartView>
          <div className="d-none d-md-block">
            <StartFAQ />
            <ContactButtons small={false} />
          </div>
        </Col>
        <Col sm={12} md={6}>
          {showTerms ? (
            <Terms setShowTerms={setShowTerms} />
          ) : (
            <Login setShowLoader={setShowLoader} setShowTerms={setShowTerms} />
          )}
        </Col>
      </Row>
    </Container>
  );

  useBodyClass(['start-body']);
  return (
    <Layout>
      {showLoader ? <Loading /> : loggedOutView}

      <FAQ>
        <StartFAQ />
        <ContactButtons small={true} />
      </FAQ>
    </Layout>
  );
};
