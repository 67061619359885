import { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Home } from './pages/Home';
import { type WEB3AUTH_NETWORK_TYPE } from './config/web3AuthNetwork';
import { Web3AuthProvider } from './services/web3auth';
// import { type CHAIN_CONFIG_TYPE } from './config/chainConfig';
import { EmailLink } from 'pages/EmailLink';
import RequireAuth from 'components/RequireAuth';
import ConfirmMfaEnroll from 'components/ConfirmMfaEnroll';
import Profile from 'components/Profile';
import { NotFound } from 'pages/NotFound';
import { Dashboard } from 'pages/Dashboard';
import { EMailLogin } from 'pages/EmailLogin';
import { SelectInvestorProfile } from 'pages/SelectInvestorProfile';
import { WelcomeVideo } from 'pages/WelcomeVideo';
import { GuidedTour } from 'pages/GuidedTour';
import { Help } from 'pages/Help';
import { Portfolio } from 'pages/Portfolio';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { DashboardMobileCheckout } from 'components/Dashboard/DashboardMobileCheckout';
import { DashboardContext, type IPortfolio } from 'components/Dashboard/DashboardContext';
import type { CartItem, Investment, WithdrawalData, TokenState, PortfolioPerformance } from 'types';
import { UserServices } from 'services/UserServices';
import { ShepherdTour } from 'react-shepherd';
import { offset } from '@floating-ui/dom';
import { GuidedTourSupport } from 'pages/GuidedTourSupport';
import { ToastContainer } from 'react-toastify';
import { HelpCenterIFrame } from 'components/HelpCenterIFrame';
import { useAuth } from 'hooks/useAuth';

import { convertCurrency } from './utils/currencyUtils';
import { MoneriumProvider } from 'context/moneriumContext';
import { MoneriumIntegration } from 'pages/MoneriumIntegration';
// import { Locked } from 'pages/Locked';
import { PriceDataProvider } from 'context/priceDataContext';
import { KYCForm } from 'components/KYCForm';
import * as process from 'process';

const client = new ApolloClient({
  uri: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',
  cache: new InMemoryCache(),
});

function App(): JSX.Element {
  // @todo: move the network and chain out into config
  const [web3AuthNetwork] = useState<WEB3AUTH_NETWORK_TYPE>(
    process.env.REACT_APP_MODE === 'dev' ? 'testnet' : 'sapphire_mainnet',
  );
  const [chain] = useState<any>(process.env.REACT_APP_MODE === 'dev' ? 'goerli' : 'polygon');
  const [shoppingCartItems, setShoppingCartItems] = useState<CartItem[]>([]);
  const [withdrawInvestment, setWithdrawInvestment] = useState<WithdrawalData>({
    investment: undefined,
    withdrawAmount: 0,
  });
  const [showShoppingCart, setShowShoppingCart] = useState(false);
  const [showQuestionMarkPopup, setShowQuestionMarkPopup] = useState(false);
  const [showProfilePopup, setShowProfilePopup] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [expandShoppingCart, setExpandShoppingCart] = useState(false);
  const [tokenState, setTokenState] = useState<TokenState>({});
  const [balance, updateBalance] = useState(0);
  const [account, setAccount] = useState('');
  const [showLogout, setShowLogout] = useState(false);
  const [showGuidedTour, setShowGuidedTour] = useState(false);
  const [tourSteps, setTourSteps] = useState<any>([]);
  const [showSelectProfilePopup, setShowSelectProfilePopup] = useState(false);
  const [showFundWalletPopup, setShowFundWalletPopup] = useState(false);
  const [showRetrieveFromWalletPopup, setShowRetrieveFromWalletPopup] = useState(false);
  const [showMoneriumNotAuthedPopup, setShowMoneriumNotAuthedPopup] = useState(false);
  const [showSelectLanguage, setShowSelectLanguage] = useState(false);
  const [startedGuidedTour, setStartedGuidedTour] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(0);
  const [showCurrencySwitchPopup, setShowCurrencySwitchPopup] = useState(false);
  const [showShareFeedbackPopup, setShowShareFeedbackPopup] = useState(false);
  const [showShareFeedbackForm, setShowShareFeedbackForm] = useState(false);
  const [showSuccessTransaction, setShowSuccessTransaction] = useState(false);
  const [currencyInfo, setCurrecyInfo] = useState<{ symbol: string; rate: number }>({
    symbol: '€',
    rate: 1,
  });
  const [portfolio, setPortfolio] = useState<IPortfolio>({
    numberOfInvestments: 0,
    totalInvestedValue: 0,
    totalCurrentValue: 0,
    performance: 0,
    averageRiskProfile: 0,
    performancePercentage: 0,
    investments: [],
  });
  const [currency, setCurrency] = useState(2);
  const [displayBalance, setDisplayBalance] = useState(0);
  const [displayInvestedValue, setDisplayInvestedValue] = useState(0);
  const [displayPerformance, setDisplayPerformance] = useState(0);
  const { isSignedIn } = useAuth();
  // const [locked, setLocked] = useState(true);
  // const [admin, setAdmin] = useState(window.localStorage.getItem('admin'));
  const [available, setAvailable] = useState(0);
  const [availableBN, setAvailableBN] = useState(0);
  const [txFees, setTxFees] = useState(0);
  const [txRequests, setTxRequests] = useState<any[]>([]);
  const [failedInvestments, setFailedInvestments] = useState<any[]>([]);
  const [failedWithdrawals, setFailedWithdrawals] = useState<any[]>([]);
  const [performances, setPerformances] = useState<Record<string, PortfolioPerformance>>({});
  const [totalPerformance, setTotalPerformance] = useState<PortfolioPerformance>({
    percentagePerformance: 'N/A',
    balancePerformance: 'N/A',
  });

  // useEffect(() => {
  //   setAdmin(window.localStorage.getItem('admin'));
  // }, [locked]);

  useEffect(() => {
    const getCurrency = async (): Promise<void> => {
      const currency = await UserServices.getSelectedCurrency();
      if (currency !== null && currency !== undefined) {
        setCurrency(currency);
      } else {
        setCurrency(1);
      }
    };
    // Call the function
    getCurrency().catch(console.error);
  }, [isSignedIn]);

  useEffect(() => {
    setDisplayBalance(balance);
    setDisplayInvestedValue(portfolio.totalInvestedValue);
    setDisplayPerformance(portfolio.performance);
  }, [isSignedIn, balance, portfolio.totalInvestedValue, portfolio.performance]);

  // add displaybalance and investedValue to context
  useEffect(() => {
    if (currency === 2) {
      convertCurrency(portfolio.totalInvestedValue, 'USD', 'EUR')
        .then((convertedAmount) => {
          if (convertedAmount !== null) {
            setDisplayInvestedValue(convertedAmount);
          }
        })
        .catch((error) => {
          console.error('Error converting portfolio.totalInvestedValue:', error);
        });

      convertCurrency(balance, 'USD', 'EUR')
        .then((convertedAmount) => {
          if (convertedAmount !== null) {
            setDisplayBalance(convertedAmount);
          }
        })
        .catch((error) => {
          console.error('Error converting balance:', error);
        });

      convertCurrency(portfolio.performance, 'USD', 'EUR')
        .then((convertedAmount) => {
          if (convertedAmount !== null) {
            setDisplayPerformance(convertedAmount);
          }
        })
        .catch((error) => {
          console.error('Error converting portfolio.performance:', error);
        });
    }
  }, [currency, balance, portfolio.totalInvestedValue, portfolio.performance]);

  const setBalance = (newBalance: number): void => {
    updateBalance(newBalance - portfolio.totalInvestedValue);
  };

  useEffect(() => {
    updateBalance(balance - portfolio.totalInvestedValue);
  }, [portfolio]);

  const addToPortfolio = async (newShoppingCartItems: CartItem[]): Promise<void> => {
    const cartItems = newShoppingCartItems?.length > 0 ? newShoppingCartItems : [];
    const initValue = {
      totalInvestedValue: 0,
      totalCurrentValue: 0,
      weightedRiskProfile: 0,
    };

    if (portfolio.investments.length === 0) {
      const newInvestments: Investment[] = [];

      cartItems.forEach((investment) => {
        newInvestments.push({
          bundle: investment.bundle,
          initialInvestmentValue: investment.investmentValue,
          currentInvestmentValue: investment.investmentValue,
          totalIndexTokenBalance: 0,
        });

        initValue.totalInvestedValue += investment.investmentValue;
        initValue.totalCurrentValue += investment.investmentValue;
        initValue.weightedRiskProfile +=
          investment.investmentValue * (investment.bundle.investor - 1) * 50;
      });
      setPortfolio({
        ...portfolio,
        numberOfInvestments: newInvestments.length,
        totalInvestedValue: initValue.totalInvestedValue,
        totalCurrentValue: initValue.totalCurrentValue,
        performance: initValue.totalCurrentValue - initValue.totalInvestedValue,
        performancePercentage:
          ((initValue.totalCurrentValue - initValue.totalInvestedValue) * 100) /
          initValue.totalInvestedValue,
        averageRiskProfile: initValue.weightedRiskProfile / initValue.totalInvestedValue,
        investments: newInvestments,
      });
    } else {
      const currentInvestments = [...portfolio.investments];

      cartItems.forEach((investment) => {
        const existingInvestment = currentInvestments.find(
          (item) => item.bundle?.name === investment.bundle.name,
        );
        if (existingInvestment === undefined) {
          currentInvestments.push({
            bundle: investment.bundle,
            initialInvestmentValue: investment.investmentValue,
            currentInvestmentValue: investment.investmentValue,
            totalIndexTokenBalance: 0,
          });
        } else {
          existingInvestment.initialInvestmentValue += investment.investmentValue;
          existingInvestment.currentInvestmentValue += investment.investmentValue;
        }

        setPortfolio({
          ...portfolio,
          numberOfInvestments: currentInvestments.length,
          investments: currentInvestments,
        });
      });

      const newPortfolioValues = currentInvestments.reduce((previousValue, currentValue) => {
        previousValue.totalInvestedValue += currentValue.initialInvestmentValue;
        previousValue.totalCurrentValue += currentValue.currentInvestmentValue;
        if (currentValue.bundle?.investor !== undefined) {
          previousValue.weightedRiskProfile +=
            previousValue.totalInvestedValue * (currentValue.bundle?.investor - 1) * 50;
        }
        return previousValue;
      }, initValue);

      setPortfolio({
        ...portfolio,
        numberOfInvestments: currentInvestments.length,
        totalInvestedValue: newPortfolioValues.totalInvestedValue,
        totalCurrentValue: newPortfolioValues.totalCurrentValue,
        performance: newPortfolioValues.totalCurrentValue - newPortfolioValues.totalInvestedValue,
        performancePercentage:
          ((newPortfolioValues.totalCurrentValue - newPortfolioValues.totalInvestedValue) * 100) /
          newPortfolioValues.totalInvestedValue,
        averageRiskProfile:
          newPortfolioValues.weightedRiskProfile / newPortfolioValues.totalInvestedValue,
        investments: currentInvestments,
      });
    }
  };

  useEffect(() => {
    const saveCartItems = async (): Promise<void> => {
      if (shoppingCartItems !== undefined) {
        await UserServices.addShoppingCartItems(shoppingCartItems);
      }
    };
    saveCartItems().catch(console.error);
  }, [shoppingCartItems]);

  const scrollOptions: ScrollIntoViewOptions = {
    block: 'start',
    inline: 'center',
    behavior: 'auto',
  };

  const defaultStepOptions = {
    cancelIcon: {
      enabled: true,
    },
    modalOverlayOpeningPadding: 4,
    modalOverlayOpeningRadius: 14,
    floatingUIOptions: {
      middleware: [offset({ mainAxis: 15, crossAxis: 0 })],
    },
    scrollTo: scrollOptions,
    classes: 'shepherd shepherd-welcome',
  };

  const tourOptions = {
    defaultStepOptions,
    useModalOverlay: true,
  };

  return (
    <>
      <DashboardContext.Provider
        value={{
          performances,
          setPerformances,
          totalPerformance,
          setTotalPerformance,
          shoppingCartItems,
          setShoppingCartItems,
          withdrawInvestment,
          setWithdrawInvestment,
          showShoppingCart,
          setShowShoppingCart,
          showQuestionMarkPopup,
          setShowQuestionMarkPopup,
          showProfilePopup,
          setShowProfilePopup,
          showCheckout,
          setShowCheckout,
          showWithdraw,
          setShowWithdraw,
          expandShoppingCart,
          setExpandShoppingCart,
          portfolio,
          setPortfolio,
          addToPortfolio,
          tokenState,
          setTokenState,
          balance,
          setBalance,
          account,
          setAccount,
          showGuidedTour,
          setShowGuidedTour,
          tourSteps,
          setTourSteps,
          showLogout,
          setShowLogout,
          showSelectProfilePopup,
          setShowSelectProfilePopup,
          showFundWalletPopup,
          setShowFundWalletPopup,
          showMoneriumNotAuthedPopup,
          setShowMoneriumNotAuthedPopup,
          showRetrieveFromWalletPopup,
          setShowRetrieveFromWalletPopup,
          showSelectLanguage,
          setShowSelectLanguage,
          startedGuidedTour,
          setStartedGuidedTour,
          selectedProfile,
          setSelectedProfile,
          showShareFeedbackPopup,
          setShowShareFeedbackPopup,
          showShareFeedbackForm,
          setShowShareFeedbackForm,
          showCurrencySwitchPopup,
          setShowCurrencySwitchPopup,
          currency,
          setCurrency,
          displayBalance,
          setDisplayBalance,
          displayInvestedValue,
          setDisplayInvestedValue,
          displayPerformance,
          setDisplayPerformance,
          showSuccessTransaction,
          setShowSuccessTransaction,
          currencyInfo,
          setCurrecyInfo,
          available,
          setAvailable,
          availableBN,
          setAvailableBN,
          txFees,
          setTxFees,
          txRequests,
          setTxRequests,
          failedInvestments,
          setFailedInvestments,
          failedWithdrawals,
          setFailedWithdrawals,
        }}
      >
        <PriceDataProvider>
          <MoneriumProvider>
            <ApolloProvider client={client}>
              <Web3AuthProvider chain={chain} web3AuthNetwork={web3AuthNetwork}>
                {/* <Web3AuthProvider> */}
                <ShepherdTour steps={tourSteps} tourOptions={tourOptions}>
                  <HelpCenterIFrame />
                  {/* {admin === 'true' && process.env.REACT_APP_MODE === 'dev' && locked ? ( */}
                  {/* <Routes>
                    <Route path="/" element={<Locked setLocked={setLocked} />} />
                    <Route path="*" element={<Navigate replace to="/" />} />
                  </Routes> */}
                  {/* ) : */}
                  <Routes>
                    <Route path="/" element={<Home language={undefined} />} />
                    <Route path="/en" element={<Home language={'en'} />} />
                    <Route path="/nl" element={<Home language={'nl'} />} />
                    <Route
                      path="/confirm-mfa-enroll"
                      element={
                        <RequireAuth>
                          <ConfirmMfaEnroll />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/dashboard"
                      element={
                        <RequireAuth>
                          <Dashboard />
                        </RequireAuth>
                      }
                    />
                    <Route path="/email-link" element={<EmailLink />} />
                    <Route path="/monerium-integration" element={<MoneriumIntegration />} />
                    <Route path="/email-login" element={<EMailLogin />} />
                    <Route path="/guided-tour/:isMobile?" element={<GuidedTour />} />
                    <Route path="/help" element={<Help />} />
                    <Route path="/guided-tour-support" element={<GuidedTourSupport />} />
                    <Route
                      path="/portfolio"
                      element={
                        <RequireAuth>
                          <Portfolio />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/profile"
                      element={
                        <RequireAuth>
                          <Profile />
                        </RequireAuth>
                      }
                    />
                    <Route
                      path="/mobile-checkout"
                      element={
                        <RequireAuth>
                          <DashboardMobileCheckout />
                        </RequireAuth>
                      }
                    />
                    <Route path="/select-profile" element={<SelectInvestorProfile />} />
                    <Route path="/kyc" element={<KYCForm />} />
                    <Route path="/welcome-video" element={<WelcomeVideo />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                  {/* } */}
                  <ToastContainer
                    position="bottom-left"
                    autoClose={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable={false}
                    theme="colored"
                  />
                </ShepherdTour>
              </Web3AuthProvider>
            </ApolloProvider>
          </MoneriumProvider>
        </PriceDataProvider>
      </DashboardContext.Provider>
    </>
  );
}

export default App;
