import { auth } from 'config/firebaseConfig';
import {
  RecaptchaVerifier,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  getMultiFactorResolver,
  type MultiFactorSession,
  type MultiFactorInfo,
  type MultiFactorResolver,
  type PhoneInfoOptions,
} from 'firebase/auth';

// Used in multi-factor enrollment.
let verificationId: string | null = null;
let multiFactorResolver: MultiFactorResolver | null = null;

export const getMfaResolver = (error: any): MultiFactorResolver => {
  multiFactorResolver = getMultiFactorResolver(auth, error);
  return multiFactorResolver;
};

// Starts MFA enrollment and request the verification code.
export const startEnrollMultiFactor = async (phoneNumber: string | null): Promise<void> => {
  /**
   * Pass the button id if setting invisible recaptcha,
   * otherwise pass the container id where recaptcha will
   * be launch.
   */
  const recaptchaVerifier = new RecaptchaVerifier('recaptcha', { size: 'invisible' }, auth);

  if (auth.currentUser != null) {
    verificationId = await multiFactor(auth.currentUser)
      .getSession()
      .then(async function (multiFactorSession) {
        // Specify the phone number and pass the MFA session.
        const phoneInfoOptions = {
          phoneNumber,
          session: multiFactorSession,
        };

        const phoneAuthProvider = new PhoneAuthProvider(auth);

        // Send SMS verification code.
        return await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
      })
      .catch(function (error) {
        if (error.code === 'auth/invalid-phone-number') {
          alert(
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `Error with phone number formatting. Phone numbers must start with +. ${error}`,
          );
        } else {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          alert(`Error enrolling second factor. ${error}`);
        }
        throw error;
      });
  } else {
    // The user is not verified.
    console.log('no user');
  }
};

export const finishEnrollMultiFactor = async (verificationCode: string): Promise<void> => {
  if (verificationId != null && auth.currentUser != null) {
    // Ask user for the verification code. Then:
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

    // Complete enrollment.
    await multiFactor(auth.currentUser)
      .enroll(multiFactorAssertion, 'My cellphone number')
      .catch(function (error) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        alert(`Error finishing second factor enrollment. ${error}`);
        throw error;
      });
    verificationId = null;
  }
};

export const startMfaSignin = async (
  multiFactorHint: MultiFactorInfo,
  session: MultiFactorSession,
): Promise<void> => {
  const recaptchaVerifier = new RecaptchaVerifier('recaptcha', { size: 'invisible' }, auth);

  if (multiFactorHint.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
    const phoneInfoOptions: PhoneInfoOptions = {
      multiFactorHint,
      session,
    };
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    // Send SMS verification code
    verificationId = await phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
      .catch(function (error) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        alert(`Error verifying phone number. ${error}`);
        throw error;
      });
  } else {
    alert('Only phone number second factors are supported.');
  }
};

export const finishMfaSignIn = async (verificationCode: string): Promise<void> => {
  // Get the SMS verification code sent to the user.
  if (verificationId != null && multiFactorResolver != null) {
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

    // Complete sign-in.
    await multiFactorResolver
      .resolveSignIn(multiFactorAssertion)
      .then(function (userCredential) {
        // User successfully signed in with the second factor phone number.
      })
      .catch(function (error: any) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        alert(`Error completing sign in. ${error}`);
        throw error;
      });
  }

  multiFactorResolver = null;
  verificationId = null;
};
