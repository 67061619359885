import './BundleMobileCoins.scss';
import { type Coin } from 'types/bundleData';
import { useTranslation } from 'react-i18next';

import backIcon from 'assets/images/bundle-back-icon.svg';
import { Accordion, ProgressBar } from 'react-bootstrap';
import { BundleCoinDetails } from './BundleCoinDetails';
import { usePriceData } from 'context/priceDataContext';
import { type ICoinsArray } from 'config/coinsConfig';

interface Props {
  setExpanded: any;
  showCoinDetails: any;
  coins: Coin[];
}

export const BundleMobileCoins = (props: Props): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const { coinsArray } = usePriceData();
  const coins: Coin[] = props.coins;
  // const coinsC: ICoinsArray = getCoins();
  const coinsC: ICoinsArray = coinsArray;

  const getCoinGroups = (coins: Coin[]): JSX.Element[] => {
    const coinDetails = [];
    for (let loop = 0; loop < coins.length; loop++) {
      const dynamicKey = coins[loop].coin as keyof typeof coinsC;
      const coin = coinsC[dynamicKey];
      coinDetails.push(
        <Accordion.Item eventKey={coin.name} key={coin.name}>
          <Accordion.Header className="coin-details d-flex">
            <div className="">
              <img
                src={`/cryptoicons/${coin.icon}`}
                key={coin.icon}
                className="icon"
                width={24}
                height={24}
              />
            </div>
            <div className="flex-grow-1">
              <div className="d-flex">
                <div className="flex-grow-1">
                  {`${coin.name} (${coin.symbol})`}
                  <div className="rank d-inline-block">
                    {t('allocation.rank')} #{coin.marketData.cmc_rank}
                  </div>
                </div>
                <div className="">{coins[loop].percentage}%</div>
              </div>
              <ProgressBar now={coins[loop].percentage} className="bundle-percent" />
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <BundleCoinDetails
              activeCoin={{ coin, percentage: coins[loop].percentage }}
              handleOpen={() => {}}
            />
          </Accordion.Body>
        </Accordion.Item>,
      );
    }

    return coinDetails;
  };

  return (
    <div className="bundle-mobile-coins">
      <h5
        onClick={() => {
          props.setExpanded(false);
        }}
      >
        <img
          className="bundle-back d-none d-md-inline-block"
          src={backIcon}
          alt="Back"
          height={18}
        />
        {t('allocation.investment')}
      </h5>
      <div className="coin-groups">
        <Accordion defaultActiveKey="0" flush>
          {getCoinGroups(coins)}
        </Accordion>
      </div>
    </div>
  );
};
