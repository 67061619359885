/* eslint-disable */
import axios from 'axios';
import getBundles from 'config/bundlesConfig';
import { type BundleData } from 'types/bundleData';
import React, { type ReactNode, useContext, useEffect, useState } from 'react';
import PriceData from 'services/priceData';
import { ChainId, Token } from '@uniswap/sdk-core';
import { useTranslation } from 'react-i18next';
import { type ICoinsArray } from 'config/coinsConfig';
import { auth } from 'config/firebaseConfig';
import i18n from 'i18next';

export const PriceDataContext = React.createContext({});

export const usePriceData: any = () => {
  return useContext(PriceDataContext);
};

interface PriceDataProviderProps {
  children: ReactNode;
}

export const PriceDataProvider: React.FC<PriceDataProviderProps> = ({ children }) => {
  const [bundlePriceData, setBundlePriceData] = useState<any>({
    stabilazer: {},
    balanced: {},
    adventurous: {},
  });
  const [coinsMarketData, setCoinsMarketData] = useState<any>();
  const { t } = useTranslation('home');
  // eslint-disable-next-line
  const [coinsArray, setCoins] = useState<ICoinsArray>({
    BTC: {
      icon: 'btc.svg',
      type: 'Volatile',
      name: 'Bitcoin',
      symbol: 'BTC',
      description: `${t('dashboard:btc.content1')}  \n  \n ${t(
        'dashboard:btc.content2',
      )} \n  \n ${t('dashboard:btc.content3')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        cmc_rank: 0,
        market_cap: 0,
        market_cap_dominance: 0,
        price: 0,
      },
      address: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
      decimals: 8,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc04b0d3107736c32e19f1c62b2af67be61d63a05',
        8,
        'WBTC',
        'WBTC',
      ),
    },
    ETH: {
      icon: 'eth.svg',
      type: 'Volatile',
      name: 'Ethereum',
      symbol: 'ETH',
      description: `${t('dashboard:eth.content1')}  \n  \n ${t(
        'dashboard:eth.content2',
      )} \n  \n ${t('dashboard:eth.content3')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        cmc_rank: 0,
        market_cap: 0,
        market_cap_dominance: 0,
        price: 0,
      },
      address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
        18,
        'WETH',
        'Wrapped Ether',
      ),
    },
    LINK: {
      icon: 'link.png',
      type: 'Volatile',
      name: 'Chainlink',
      symbol: 'LINK',
      description: `${t('dashboard:link.content1')}  \n  \n ${t(
        'dashboard:link.content2',
      )} \n  \n ${t('dashboard:link.content3')} \n  \n ${t('dashboard:link.content4')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        cmc_rank: 0,
        market_cap: 0,
        market_cap_dominance: 0,
        price: 0,
      },
      address: '0x53E0bca35eC356BD5ddDFebbD1Fc0fD03FaBad39',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    LDO: {
      icon: 'ldo.png',
      type: 'Volatile',
      name: 'Lido DAO Token',
      symbol: 'LDO',
      description: `${t('dashboard:link.content1')}  \n  \n ${t(
        'dashboard:link.content2',
      )} \n  \n ${t('dashboard:link.content3')} \n  \n ${t('dashboard:link.content4')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        cmc_rank: 0,
        market_cap: 0,
        market_cap_dominance: 0,
        price: 0,
      },
      address: '0xC3C7d422809852031b44ab29EEC9F1EfF2A58756',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    RNDR: {
      icon: 'rndr.png',
      type: 'Volatile',
      name: 'Render Token',
      symbol: 'RNDR',
      description: `${t('dashboard:rndr.content1')}  \n  \n ${t(
        'dashboard:rndr.content2',
      )} \n  \n ${t('dashboard:rndr.content3')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        cmc_rank: 59,
        market_cap: 707113848,
        market_cap_dominance: 0.06,
        price: 1.91,
      },
      address: '0x61299774020dA444Af134c82fa83E3810b309991',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    AAVE: {
      icon: 'aave.png',
      type: 'Volatile',
      name: 'Aave',
      symbol: 'AAVE',
      description: `${t('dashboard:aave.content1')}  \n  \n ${t(
        'dashboard:aave.content2',
      )} \n  \n ${t('dashboard:aave.content3')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        cmc_rank: 58,
        market_cap: 713041263,
        market_cap_dominance: 0.06,
        price: 8.91,
      },
      address: '0xD6DF932A45C0f255f85145f286eA0b292B21C90B',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    GRT: {
      icon: 'grt.png',
      type: 'Volatile',
      name: 'The Graph',
      symbol: 'GRT',
      description: `${t('dashboard:grt.content1')}  \n  \n ${t(
        'dashboard:grt.content2',
      )} \n  \n ${t('dashboard:grt.content3')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        cmc_rank: 58,
        market_cap: 713041263,
        market_cap_dominance: 0.06,
        price: 8.91,
      },
      address: '0x5fe2B58c013d7601147DcdD68C143A77499f5531',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    SNX: {
      icon: 'snx.png',
      type: 'Volatile',
      name: 'Synthetix Network Token',
      symbol: 'SNX',
      description: `${t('dashboard:snx.content2')}  \n  \n ${t('dashboard:snx.content3')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        cmc_rank: 58,
        market_cap: 713041263,
        market_cap_dominance: 0.06,
        price: 8.91,
      },
      address: '0x50B728D8D964fd00C2d0AAD81718b71311feF68a',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    MANA: {
      icon: 'mana.png',
      type: 'Volatile',
      name: 'Decentraland',
      symbol: 'MANA',
      description: ` ${t('dashboard:mana.content1')}\n \n ${t('dashboard:mana.content2')}\n \n ${t(
        'dashboard:mana.content3',
      )} `,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        cmc_rank: 0,
        market_cap: 0,
        market_cap_dominance: 0,
        price: 0,
      },
      address: '0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4', // polygon
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    SAND: {
      icon: 'sand.png',
      type: 'Volatile',
      name: 'The Sandbox',
      symbol: 'SAND',
      description: `${t('dashboard:sand.content1')}\n \n ${t('dashboard:sand.content2')}\n \n ${t(
        'dashboard:sand.content3',
      )} `,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        cmc_rank: 0,
        market_cap: 0,
        market_cap_dominance: 0,
        price: 0,
      },
      address: '0xbbba073c31bf03b8acf7c28ef0738decf3695683',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    WOO: {
      icon: 'woo.png',
      type: 'Volatile',
      name: 'WOO',
      symbol: 'WOO',
      description: `${t('dashboard:woo.content1')}  \n  \n ${t(
        'dashboard:woo.content2',
      )} \n  \n ${t('dashboard:woo.content3')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        cmc_rank: 0,
        market_cap: 0,
        market_cap_dominance: 0,
        price: 0,
      },
      address: '0x1B815d120B3eF02039Ee11dC2d33DE7aA4a8C603',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    SOL: {
      icon: 'sol.png',
      type: 'Volatile',
      name: 'Solana',
      symbol: 'SOL',
      description: `${t('dashboard:sol.content1')}  \n  \n ${t(
        'dashboard:sol.content2',
      )} \n  \n ${t('dashboard:sol.content3')} \n  \n ${t('dashboard:sol.content4')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        cmc_rank: 0,
        market_cap: 0,
        market_cap_dominance: 0,
        price: 0,
      },
      address: '0x7DfF46370e9eA5f0Bad3C4E29711aD50062EA7A4',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    AVAX: {
      icon: 'avax.png',
      type: 'Volatile',
      name: 'Avalanche',
      symbol: 'AVAX',
      description: `${t('dashboard:avax.content1')}  \n  \n ${t(
        'dashboard:avax.content2',
      )} \n  \n ${t('dashboard:avax.content3')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        cmc_rank: 0,
        market_cap: 0,
        market_cap_dominance: 0,
        price: 0,
      },
      address: '0x2C89bbc92BD86F8075d1DEcc58C7F4E0107f286b',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    MKR: {
      icon: 'mkr.png',
      type: 'Volatile',
      name: 'Maker DAO',
      symbol: 'MKR',
      description: `${t('dashboard:mkr.content1')}  \n  \n ${t(
        'dashboard:mkr.content2',
      )} \n  \n ${t('dashboard:mkr.content3')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        cmc_rank: 0,
        market_cap: 0,
        market_cap_dominance: 0,
        price: 0,
      },
      address: '0x6f7C932e7684666C9fd1d44527765433e01fF61d',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    MATIC: {
      icon: 'matic.png',
      type: 'Volatile',
      name: 'Polygon',
      symbol: 'MATIC',
      description: `${t('dashboard:matic.content1')}  \n  \n ${t(
        'dashboard:matic.content2',
      )} \n  \n ${t('dashboard:matic.content3')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        cmc_rank: 0,
        market_cap: 0,
        market_cap_dominance: 0,
        price: 0,
      },
      address: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
    UNI: {
      icon: 'uni.png',
      type: 'Volatile',
      name: 'Uniswap',
      symbol: 'UNI',
      description: `${t('dashboard:uni.content1')}  \n  \n ${t(
        'dashboard:uni.content2',
      )} \n  \n ${t('dashboard:uni.content3')} \n  \n ${t('dashboard:uni.content4')}`,
      performance: {
        value: 2.6,
        priceChange: 2.5,
        yearlyReturn: 2.6,
        provider: 'AAVE',
      },
      marketData: {
        cmc_rank: 0,
        market_cap: 0,
        market_cap_dominance: 0,
        price: 0,
      },
      address: '0xb33EaAd8d922B1083446DC23f610c2567fB5180f',
      decimals: 18,
      uniswap: new Token(
        ChainId.GOERLI,
        '0xc2c527c0cacf457746bd31b2a698fe89de2b6d49',
        6,
        'USDT',
        'USD//T',
      ),
    },
  });

  useEffect(() => {
    if (auth.currentUser != null) {
      fetchData()
        .then(() => {
          // console.log(bundlePriceData);
        })
        .catch(console.error);

      getCoinsMarketData()
        .then(() => {
          // console.log(coinsMarketData);
        })
        .catch(console.error);
    }
  }, [auth.currentUser]);

  useEffect(() => {
    const descriptions: any = {
      BTC: `${t('dashboard:btc.content1')}  \n  \n ${t('dashboard:btc.content2')} \n  \n ${t(
        'dashboard:btc.content3',
      )}`,
      ETH: `${t('dashboard:eth.content1')}  \n  \n ${t('dashboard:eth.content2')} \n  \n ${t(
        'dashboard:eth.content3',
      )}`,
      LINK: `${t('dashboard:link.content1')}  \n  \n ${t('dashboard:link.content2')} \n  \n ${t(
        'dashboard:link.content3',
      )} \n  \n ${t('dashboard:link.content4')}`,
      LDO: `${t('dashboard:link.content1')}  \n  \n ${t('dashboard:link.content2')} \n  \n ${t(
        'dashboard:link.content3',
      )} \n  \n ${t('dashboard:link.content4')}`,
      RNDR: `${t('dashboard:rndr.content1')}  \n  \n ${t('dashboard:rndr.content2')} \n  \n ${t(
        'dashboard:rndr.content3',
      )}`,
      AAVE: `${t('dashboard:aave.content1')}  \n  \n ${t('dashboard:aave.content2')} \n  \n ${t(
        'dashboard:aave.content3',
      )}`,
      GRT: `${t('dashboard:grt.content1')}  \n  \n ${t('dashboard:grt.content2')} \n  \n ${t(
        'dashboard:grt.content3',
      )}`,
      SNX: `${t('dashboard:snx.content2')}  \n  \n ${t('dashboard:snx.content3')}`,
      MANA: `${t('dashboard:mana.content1')}  \n  \n ${t('dashboard:mana.content2')} \n  \n ${t(
        'dashboard:mana.content3',
      )}`,
      SAND: `${t('dashboard:sand.content1')}  \n  \n ${t('dashboard:sand.content2')} \n  \n ${t(
        'dashboard:sand.content3',
      )}`,
      WOO: `${t('dashboard:woo.content1')}  \n  \n ${t('dashboard:woo.content2')} \n  \n ${t(
        'dashboard:woo.content3',
      )}`,
      SOL: `${t('dashboard:sol.content1')}  \n  \n ${t('dashboard:sol.content2')} \n  \n ${t(
        'dashboard:sol.content3',
      )} \n  \n ${t('dashboard:sol.content4')}`,
      AVAX: `${t('dashboard:avax.content1')}  \n  \n ${t('dashboard:avax.content2')} \n  \n ${t(
        'dashboard:avax.content3',
      )}`,
      MKR: `${t('dashboard:mkr.content1')}  \n  \n ${t('dashboard:mkr.content2')} \n  \n ${t(
        'dashboard:mkr.content3',
      )}`,
      MATIC: `${t('dashboard:matic.content1')}  \n  \n ${t('dashboard:matic.content2')} \n  \n ${t(
        'dashboard:matic.content3',
      )}`,
      UNI: `${t('dashboard:uni.content1')}  \n  \n ${t('dashboard:uni.content2')} \n  \n ${t(
        'dashboard:uni.content3',
      )} \n  \n ${t('dashboard:uni.content4')}`,
    };
    if (coinsMarketData !== undefined) {
      const coins: any = coinsArray;
      for (const coinId in coinsMarketData) {
        if (coins[coinId]) {
          coins[coinId].marketData = coinsMarketData[coinId];
          coins[coinId].description = descriptions[coinId];
        }
      }
      setCoins({ ...coins });
    }
  }, [coinsMarketData, i18n.language]);

  const fetchData: () => Promise<void> = async () => {
    const bundles = getBundles();
    for (const bundle of bundles) {
      const fetched = await PriceData.getBundlePriceData(bundle);
      // const fetched: any = undefined;
      setBundlePriceData((prevState: any) => ({
        ...prevState,
        [bundle.name]: {
          data: fetched,
          currentPrice: fetched._1095[0].y,
          initialPrice: fetched._1095[fetched._1095.length - 1].y,
        },
      }));
    }
  };

  const getPerformance: (bundle: BundleData) => Promise<number> = async (bundle: BundleData) => {
    const performance = await PriceData.getBundle3YrPerformance(bundle.coins);
    // return {
    //   currentPrice: bundlePriceData[bundle.name].currentPrice,
    //   initialPrice: bundlePriceData[bundle.name].initialPrice,
    // };
    return performance;
  };

  const getBundleData: (bundle: BundleData) => any = (bundle: BundleData) => {
    return bundlePriceData[bundle.name].data;
  };

  const getCoinsMarketData: () => Promise<void> = async () => {
    const config = {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    };
    try {
      const response = await axios.get(
        // 'http://127.0.0.1:5001/fenus-mvp-production/us-central1/coinDataCapDominance',
        'https://getcoinmarketdata-qfmxwrvf7q-uc.a.run.app',
        config,
      );
      const coinsData = response.data.map((d: any) => JSON.parse(d));

      const resultObject = coinsData.reduce((acc: any, entry: any) => {
        Object.keys(entry).forEach((key) => {
          if (key !== 'date') {
            acc[key] = entry[key];
          }
        });

        return acc;
      }, {});

      setCoinsMarketData(resultObject);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PriceDataContext.Provider
      value={{
        getPerformance,
        getBundleData,
        coinsMarketData,
        coinsArray,
      }}
    >
      {children}
    </PriceDataContext.Provider>
  );
};
