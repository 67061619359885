import { getDocs, collection } from '@firebase/firestore';
import { firestore } from 'config/firebaseConfig';
import { type Coin, type BundleData } from 'types/bundleData';

export interface IPriceData {
  count: number;
  id: string;
  x: Date;
  y: number;
}

export interface IModPriceData {
  id: string;
  x: Date;
  y: number;
}

// eslint-disable-next-line
interface IPriceDataAllCoins {
  [coin: string]: {
    coinData1095: any[];
    coinData365: any[];
    coinData90: any[];
  };
}

const PriceData = {
  getPriceDataForCoins: async function (coins: string[]) {
    const collectionRef = collection(firestore, 'CoinDataTest3');
    const collectionData: any = {};
    const coinData1095: IPriceData[] = [];
    const coinData365: IPriceData[] = [];
    const coinData90: IPriceData[] = [];

    const querySnapshot = await getDocs(collectionRef);

    querySnapshot.forEach((doc) => {
      collectionData[doc.id] = doc.data();

      // eslint-disable-next-line
      if (doc.data()?.historicalData?.['1095']) {
        doc.data().historicalData['1095'].forEach((entry: any) => {
          // console.log('1095', doc.id, ' => ', entry);
          const timestamp = entry.Date;
          const date = timestamp.seconds;

          const count = coinData1095.filter((entry) => entry.id === date).length + 1;

          const existingEntry = coinData1095.find((entry) => entry.id === date);

          // eslint-disable-next-line
          if (existingEntry) {
            // console.log('existingEntry exists');
            existingEntry.count++;
            // eslint-disable-next-line
            existingEntry.y = (existingEntry.y * (count - 1) + entry.Price) / count;
          } else {
            coinData1095.push({
              count,
              id: date,
              y: entry.Price,
              x: entry.Date.toDate(),
            });
          }
        });
      }
      // eslint-disable-next-line
      if (doc.data()?.historicalData?.['365']) {
        doc.data().historicalData['365'].forEach((entry: any) => {
          // console.log('365', doc.id, ' => ', entry);
          const timestamp = entry.Date;
          const date = timestamp.seconds;

          const count = coinData365.filter((entry) => entry.id === date).length + 1;

          const existingEntry = coinData365.find((entry) => entry.id === date);

          // eslint-disable-next-line
          if (existingEntry) {
            // console.log('existingEntry exists');
            existingEntry.count++;
            // eslint-disable-next-line
            existingEntry.y = (existingEntry.y * (count - 1) + entry.Price) / count;
          } else {
            coinData365.push({
              count,
              id: date,
              y: entry.Price,
              x: entry.Date.toDate(),
            });
          }
        });
      }
      // eslint-disable-next-line
      if (doc.data()?.historicalData?.['90']) {
        doc.data().historicalData['90'].forEach((entry: any) => {
          // console.log('90', doc.id, ' => ', entry);
          const timestamp = entry.Date;
          const date = timestamp.seconds;

          const count = coinData90.filter((entry) => entry.id === date).length + 1;

          const existingEntry = coinData90.find((entry) => entry.id === date);

          // eslint-disable-next-line
          if (existingEntry) {
            existingEntry.count++;
            // eslint-disable-next-line
            existingEntry.y = (existingEntry.y * (count - 1) + entry.Price) / count;
          } else {
            coinData90.push({
              count,
              id: date,
              y: entry.Price,
              x: entry.Date.toDate(),
            });
          }
        });
      }
    });

    const coinData = {
      _90: coinData90.filter((value) => value.count === coins.length),
      _365: coinData365.filter((value) => value.count === coins.length),
      _1095: coinData1095.filter((value) => value.count === coins.length),
    };
    return coinData;
  },

  getBundle3YrPerformance: async function (coins: Array<{ coin: string; percentage: number }>) {
    const priceData = await this.getPriceDataAllCoins();
    const modifiedCoins = coins.map((coin: { coin: string; percentage: number }) => {
      const old = priceData[coin.coin].coinData1095.slice(-1)[0].Price;
      const recent = priceData[coin.coin].coinData1095[0].Price;
      const coinPerformance = (recent - old) / old;
      return coin.percentage * coinPerformance;
    });
    const bundlePerformance = modifiedCoins.reduce((a: number, b: number) => a + b);
    return bundlePerformance;
  },

  getDataForGraph: async function (coins: Array<{ coin: string; percentage: number }>) {
    const priceData = await this.getPriceDataAllCoins();
    const coinData1095: IModPriceData[] = [];
    const coinData365: IModPriceData[] = [];
    const coinData90: IModPriceData[] = [];

    coins.forEach((coin) => {
      priceData[coin.coin].coinData1095
        .sort((a, b) => a.Date.seconds - b.Date.seconds)
        .forEach((entry: any, i: number) => {
          const timestamp = entry.Date;
          const date = timestamp.seconds;

          const existingEntry = coinData1095.find((entry) => entry.id === date);

          let percentageChangePrice;
          if (i === 0) {
            percentageChangePrice = 0;
          } else {
            const oldPrice = priceData[coin.coin].coinData1095[i - 1].Price;
            percentageChangePrice = ((entry.Price - oldPrice) * 100) / oldPrice;
          }

          if (existingEntry !== undefined) {
            existingEntry.y = existingEntry.y + (percentageChangePrice * coin.percentage) / 100;
          } else {
            coinData1095.push({
              id: date,
              y: (percentageChangePrice * coin.percentage) / 100,
              x: entry.Date.toDate(),
            });
          }
        });
      priceData[coin.coin].coinData365
        .sort((a, b) => a.Date.seconds - b.Date.seconds)
        .forEach((entry: any, i: number) => {
          const timestamp = entry.Date;
          const date = timestamp.seconds;

          const existingEntry = coinData365.find((entry) => entry.id === date);

          let percentageChangePrice;
          if (i === 0) {
            percentageChangePrice = 0;
          } else {
            const oldPrice = priceData[coin.coin].coinData365[i - 1].Price;
            percentageChangePrice = ((entry.Price - oldPrice) * 100) / oldPrice;
          }

          if (existingEntry !== undefined) {
            existingEntry.y = existingEntry.y + (percentageChangePrice * coin.percentage) / 100;
          } else {
            coinData365.push({
              id: date,
              y: (percentageChangePrice * coin.percentage) / 100,
              x: entry.Date.toDate(),
            });
          }
        });
      priceData[coin.coin].coinData90
        .sort((a, b) => a.Date.seconds - b.Date.seconds)
        .forEach((entry: any, i: number) => {
          const timestamp = entry.Date;
          const date = timestamp.seconds;

          const existingEntry = coinData90.find((entry) => entry.id === date);

          let percentageChangePrice;
          if (i === 0) {
            percentageChangePrice = 0;
          } else {
            const oldPrice = priceData[coin.coin].coinData90[i - 1].Price;
            percentageChangePrice = ((entry.Price - oldPrice) * 100) / oldPrice;
          }

          if (existingEntry !== undefined) {
            existingEntry.y = existingEntry.y + (percentageChangePrice * coin.percentage) / 100;
          } else {
            coinData90.push({
              id: date,
              y: (percentageChangePrice * coin.percentage) / 100,
              x: entry.Date.toDate(),
            });
          }
        });
    });

    const coinData = {
      _90: coinData90.sort((a, b) => +a.id - +b.id),
      // coinData90
      _365: coinData365.sort((a, b) => +a.id - +b.id),
      // coinData365
      _1095: coinData1095.sort((a, b) => +a.id - +b.id),
      // coinData1095
    };
    return coinData;
  },

  getPriceDataAllCoins: async function () {
    const collectionRef = collection(firestore, 'CoinDataTest3');
    const priceDataAllCoins: IPriceDataAllCoins = {};

    const querySnapshot = await getDocs(collectionRef);

    querySnapshot.forEach((doc) => {
      priceDataAllCoins[doc.id] = {
        coinData1095: doc.data()?.historicalData?.['1095'],
        coinData365: doc.data()?.historicalData?.['365'],
        coinData90: doc.data()?.historicalData?.['90'],
      };
    });

    return priceDataAllCoins;
  },

  getBundlePriceData: async function (bundle: BundleData) {
    const initValue: string[] = [];
    // eslint-disable-next-line
    const coins = bundle.coins.reduce((prevValue: string[], currValue: Coin): string[] => {
      prevValue.push(currValue.coin);
      return prevValue;
    }, initValue);
    // eslint-disable-next-line
    // const priceData = await this.getPriceDataForCoins(coins);
    // const priceData = {};
    // const priceData = await this.getPriceDataForCoins([{coin: 'BTC', percentage: 60}, {coin: 'ETH', percentage: 40}']);
    const priceData = await this.getDataForGraph(bundle.coins);
    return priceData;
  },
};

export default PriceData;
