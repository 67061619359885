export const getCurrencySymbol = (currency: number): string => {
  switch (currency) {
    case 1:
      return '$';
    case 2:
      return '€';

    default:
      return 'Undefined';
  }
};

export async function convertCurrency(
  amount: number,
  fromCurrency: string,
  toCurrency: string,
): Promise<number | null> {
  try {
    const response = await fetch(`https://open.er-api.com/v6/latest/${fromCurrency}`);

    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }
    const data = await response.json();
    const rate = data.rates[toCurrency];
    if (rate === null) {
      throw new Error(`No exchange rate data for ${toCurrency}`);
    }
    const convertedAmount = amount * rate;
    return convertedAmount;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getExchangeRate(
  fromCurrency: string,
  toCurrency: string,
): Promise<number | null> {
  try {
    const response = await fetch(`https://open.er-api.com/v6/latest/${fromCurrency}`);

    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }
    const data = await response.json();
    const rate = data.rates[toCurrency];

    if (rate === null) {
      throw new Error(`No exchange rate data for ${toCurrency}`);
    }

    return rate; // Return the exchange rate without performing the conversion.
  } catch (error) {
    console.error(error);
    return null;
  }
}
