import React, { useContext, useEffect, useReducer, useCallback } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { DashboardContext, type IDashboardContext } from 'components/Dashboard/DashboardContext';
import { useTranslation } from 'react-i18next';
import toastWarningIcon from 'assets/images/red-warning-icon.svg';
import './PortfolioInvestments.scss';
import { type Investment, type PortfolioPerformance } from 'types/investment';
import { CoinDisplay } from 'components/Bundles/CoinDisplay';
import { NoInvestments } from './NoInvestments';
import { useIndexTokenPrice } from 'hooks/useIndexTokenPrice';
import { getPerformanceValues } from 'utils/getPerformanceValues';
import { sortInvestmentsByValue } from 'utils/sortInvestmentsByValue';
import { formatCurrencyPerformance } from 'utils/formatCurrencyPerformance';
import LoadingSpinner from '../LoadingSpinner';

interface Props {
  setActiveInvestment: (
    investment: Investment | null,
    performance: PortfolioPerformance | null,
  ) => void;
  setFailedTx: (failedTx: any) => void;
}

type IndexTokenPrices = Record<string, number>;

interface State {
  investmentItems: Investment[];
  activeInvestment: number;
  indexTokenPrices: Record<string, number>;
}

type Action =
  | { type: 'SET_INVESTMENT_ITEMS'; payload: Investment[] }
  | { type: 'SET_ACTIVE_INVESTMENT'; payload: number }
  | { type: 'SET_INDEX_TOKEN_PRICES'; payload: IndexTokenPrices };

const initialState: State = {
  investmentItems: [],
  activeInvestment: 0,
  indexTokenPrices: {},
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_INVESTMENT_ITEMS':
      return { ...state, investmentItems: action.payload };
    case 'SET_ACTIVE_INVESTMENT':
      return { ...state, activeInvestment: action.payload };
    case 'SET_INDEX_TOKEN_PRICES':
      return { ...state, indexTokenPrices: action.payload };
    default:
      return state;
  }
};

export const PortfolioInvestments: React.FC<Props> = ({ setActiveInvestment, setFailedTx }) => {
  const { t } = useTranslation('portfolio');
  const { getIndexTokenPrice } = useIndexTokenPrice();
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleInvestmentClick = useCallback(
    (investment: Investment, index: number): void => {
      dispatch({ type: 'SET_ACTIVE_INVESTMENT', payload: index });
      const existingPerformance = getPerformanceValues(investment, state.indexTokenPrices);
      const isPerformanceValid =
        existingPerformance &&
        existingPerformance.balancePerformance !== 'N/A' &&
        existingPerformance.percentagePerformance !== 'N/A';

      if (!isPerformanceValid) {
        const performance = getPerformanceValues(investment, state.indexTokenPrices);
        setActiveInvestment(investment, performance);
      } else {
        setActiveInvestment(investment, existingPerformance);
      }
    },
    [setActiveInvestment, state.indexTokenPrices],
  );

  const checkingFailedInvestments = useCallback(
    (item: Investment): boolean => {
      const failed = dashboardContext.failedInvestments.find(
        (inv) => inv.bundle === item.bundle.name,
      );
      return failed ? failed.coins.length > 0 : false;
    },
    [dashboardContext.failedInvestments],
  );

  const checkingFailedWithdrawals = useCallback(
    (item: Investment): boolean => {
      const failed = dashboardContext.failedWithdrawals.find(
        (inv) => inv.bundle === item.bundle.name,
      );
      return failed ? failed.coins.length > 0 : false;
    },
    [dashboardContext.failedWithdrawals],
  );

  useEffect(() => {
    const fetchIndexTokenPrices = async (items: Investment[]): Promise<void> => {
      const prices: IndexTokenPrices = {};
      for (const item of items) {
        try {
          prices[item.bundle.name] = await getIndexTokenPrice(item.bundle.name);
        } catch (error) {
          console.error('Error fetching index token price:', error);
        }
      }
      dispatch({ type: 'SET_INDEX_TOKEN_PRICES', payload: prices });
    };

    const sortedItems = sortInvestmentsByValue(dashboardContext.portfolio.investments);
    dispatch({ type: 'SET_INVESTMENT_ITEMS', payload: sortedItems });
    fetchIndexTokenPrices(sortedItems);
  }, [
    dashboardContext.portfolio.investments,
    dashboardContext.shoppingCartItems,
    getIndexTokenPrice,
  ]);

  return (
    <div className={state.investmentItems.length !== 0 ? 'portfolio-investment' : 'no-inv-outer'}>
      {state.investmentItems.length === 0 ? (
        <NoInvestments />
      ) : (
        <Container>
          <Row className="header">
            <Col>
              {t('investments.my')} ({state.investmentItems.length})
            </Col>
            <Col className="text-end">
              {t('investments.value')}
              <br />
              {t('investments.performance')}
            </Col>
          </Row>
          <Row>
            <Col>
              {state.investmentItems.map((item, index) => {
                const performance = dashboardContext.performances[item.bundle.name];
                const isPositive =
                  performance && performance.percentagePerformance !== 'N/A'
                    ? parseFloat(performance.percentagePerformance) >= 0
                    : false;
                const calculatedValue = performance
                  ? (item.currentInvestmentValue +
                      parseFloat(performance.balancePerformance || '0')) *
                    dashboardContext.currencyInfo.rate
                  : null;
                return (
                  <div
                    key={index}
                    className={`item-container d-flex ${
                      state.activeInvestment === index ? 'active' : ''
                    } ${index}`}
                    onClick={(e) => {
                      const element = e.target as HTMLElement;
                      if (element.tagName !== 'IMG') setFailedTx(null);
                      handleInvestmentClick(item, index);
                    }}
                  >
                    <div className="flex-grow-1">
                      <div className="d-flex item-header">
                        <div className="flex-grow-1 d-flex align-center">
                          <div className="item-name align-middle w-50">{item.bundle.name}</div>
                          <div className="d-flex w-50 align-items-center pt-0 ">
                            <CoinDisplay coins={item.bundle.coins} coinSize={24} />
                          </div>
                          {(checkingFailedInvestments(item) || checkingFailedWithdrawals(item)) && (
                            <img
                              src={toastWarningIcon}
                              alt=""
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                const failedInv = dashboardContext.failedInvestments.find(
                                  (inv) => inv.bundle === item.bundle.name,
                                );
                                const failedWd = dashboardContext.failedWithdrawals.find(
                                  (inv) => inv.bundle === item.bundle.name,
                                );
                                setFailedTx({
                                  investments: failedInv ?? null,
                                  withdrawals: failedWd ?? null,
                                });
                              }}
                            />
                          )}
                        </div>
                        <div className="value0">
                          {performance ? (
                            <>
                              {dashboardContext.currencyInfo.symbol}
                              {calculatedValue?.toFixed(2)}
                            </>
                          ) : (
                            <div className="loading-spinner-button">
                              <LoadingSpinner />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="d-flex item-performance justify-content-end">
                        <div className="value">
                          {performance &&
                            formatCurrencyPerformance(
                              dashboardContext.currencyInfo.symbol,
                              parseFloat(
                                dashboardContext.performances[item.bundle.name].balancePerformance,
                              ) * dashboardContext.currencyInfo.rate,
                            )}
                          {performance &&
                            dashboardContext.performances[item.bundle.name]
                              .percentagePerformance !== 'N/A' && (
                              <div
                                className={`percent float-end ${
                                  isPositive ? 'positive' : 'negative'
                                }`}
                              >
                                {
                                  dashboardContext.performances[item.bundle.name]
                                    .percentagePerformance
                                }
                                %
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};
