import React from 'react';
import logo from '../../assets/images/tnclogo.svg';

import './termsCondition.scss';
interface Props {
  extraClass: string;
  handleBackClick: () => void;
}
export default function TermsAndCondition({ extraClass, handleBackClick }: Props): JSX.Element {
  const texts = [
    {
      header: 'Introduction',
      paragraph:
        "This section pertains to the User Feedback functionality ('Feedback Service') on Fenus Finance - Crypto investing  (the “Website”), which is owned by Fenus B.V. ('Fenus'). By submitting feedback, you agree to these terms and conditions in addition to the general terms applicable to the Website.",
    },
    {
      header: 'Purpose of the Feedback Service',
      paragraph:
        "The Feedback Service is provided to enable users to share their thoughts, experiences, and suggestions about Fenus's services and products. This service is intended to improve Fenus and to cater to user needs more effectively",
    },
    {
      header: 'Submitting Feedback',
      paragraph:
        'When submitting feedback, you agree to provide honest, constructive, and respectful comments. Fenus reserves the right to review and moderate feedback to ensure compliance with these standards.',
    },
    {
      header: 'Use of Feedback',
      paragraph:
        'Fenus may use the information provided through the Feedback Service for various purposes, including but not limited to, improving services, developing new features, or addressing user concerns. By submitting your feedback, you grant Fenus a perpetual, worldwide, non-exclusive, royalty-free license to use your comments as deemed appropriate by Fenus.',
    },
    {
      header: 'Confidentiality and Privacy',
      paragraph:
        'Feedback submissions may be anonymous if preferred. However, if personal information is provided, Fenus will handle it in accordance with its privacy policy. Fenus respects the confidentiality of your feedback and will not publicly disclose any personal information without your consent unless required by law.',
    },
    {
      header: 'No Compensation',
      paragraph:
        'Providing feedback is voluntary, and you acknowledge that you will not receive any compensation, monetary or otherwise, for any ideas or information provided through the Feedback Service.',
    },
    {
      header: 'Feedback Disclaimer',
      paragraph:
        'While Fenus values your feedback, please note that Fenus is not obligated to act on any suggestion or resolve any specific problem mentioned in your feedback. Furthermore, Fenus is not liable for any similarities between feedback content and future Fenus services or products.',
    },
    {
      header: 'Modification of Terms',
      paragraph:
        'Fenus reserves the right to modify these Terms and Conditions for the Feedback Service at any time. Changes will be effective immediately upon posting on the Website.',
    },
    {
      header: 'Contact',
      paragraph:
        'Should you have any questions about these Terms and Conditions for the Feedback Service, please contact Fenus at info@fenus.io.',
    },
  ];

  return (
    <div className={` ${extraClass} terms-container`}>
      <div className="logo-container">
        <img src={logo} alt="logo" />
      </div>
      <div className="header">Terms & Conditions</div>
      <div className="terms-text">
        <ol>
          {texts.map((text, index) => (
            <li key={index}>
              <span className="list-header">{text.header} </span>
              <span>{text.paragraph}</span>
            </li>
          ))}
        </ol>
      </div>
      <button
        onClick={() => {
          handleBackClick();
        }}
        className="terms-button"
      >
        Back
      </button>
    </div>
  );
}
