import { useContext, useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { DashboardContext, type IDashboardContext } from './Dashboard/DashboardContext';
import { useAuth } from 'hooks/useAuth';
import { toast } from 'react-toastify';

import toastCheckedIcon from 'assets/images/checked-toast.svg';

import './SelectProfilePopup.scss';
import { UserServices } from 'services/UserServices';
import { useTranslation } from 'react-i18next';

export const SelectProfilePopup = (): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const { isSignedIn } = useAuth();
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const [selectedProfile, setSelectedProfile] = useState(0);
  const [isDirty, setIsDirty] = useState(false);
  const [prevSelectedProfile, setPrevSelectedProfile] = useState('');

  const getProfileString = (profile: number): string => {
    switch (profile) {
      case 1:
        return 'Careful';
      case 2:
        return 'Balanced';
      case 3:
        return 'Adventurous';
      default:
        return 'Undefined';
    }
  };

  const handleProfileChange = (profile: number): void => {
    if (selectedProfile !== 0) {
      setSelectedProfile(profile);
      setPrevSelectedProfile(getProfileString(selectedProfile));
      setIsDirty(true);
    }
  };

  const handleClose = (): void => {
    dashboardContext.setShowSelectProfilePopup(false);
    setIsDirty(false);
  };

  const handleShow = async (): Promise<void> => {
    const profile = await UserServices.getSelectedProfile();
    setSelectedProfile(profile);
    setIsDirty(false);
  };

  const handleConfirm = async (): Promise<void> => {
    await UserServices.updateSelectedProfile(selectedProfile);
    const profile = await UserServices.getSelectedProfile();
    dashboardContext.setSelectedProfile(profile);

    toast.dismiss();
    toast.info(
      <div>
        {t('profile.changed1')} <strong> ‘{prevSelectedProfile}’</strong> {t('profile.changed2')}{' '}
        <strong>‘{getProfileString(selectedProfile)}’</strong>.
      </div>,
      {
        autoClose: 5000,
        icon: () => <img src={toastCheckedIcon} />,
      },
    );
    dashboardContext.setShowSelectProfilePopup(false);
    setIsDirty(false);
  };

  useEffect(() => {
    const getProfile = async (): Promise<void> => {
      const profile = await UserServices.getSelectedProfile();
      setSelectedProfile(profile);
    };

    // call the function
    getProfile().catch(console.error);
  }, [isSignedIn]);

  return (
    <>
      <Modal
        onHide={handleClose}
        show={dashboardContext.showSelectProfilePopup}
        animation={false}
        className="select-profile-modal"
        centered
        onShow={handleShow}
      >
        <Modal.Header>
          <Modal.Title>Select an Investor profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Choose a profile below for investment opportunities that match your financial tolerance
            and goals.
          </p>
          <div className="profile-btn-group">
            <Button
              className={`${selectedProfile === 1 ? 'profile-selected' : ''}`}
              onClick={() => {
                handleProfileChange(1);
              }}
            >
              Careful
            </Button>
            <Button
              className={`${selectedProfile === 2 ? 'profile-selected' : ''}`}
              onClick={() => {
                handleProfileChange(2);
              }}
            >
              Balanced
            </Button>
            <Button
              className={`${selectedProfile === 3 ? 'profile-selected' : ''}`}
              onClick={() => {
                handleProfileChange(3);
              }}
            >
              Adventurous
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer hidden={!isDirty}>
          <div className="row w-100 g-1 m-0">
            <div className="col-xs-12 col-md-6 order-1 order-md-2 mb-2">
              <Button className="w-100 confirm-button" onClick={handleConfirm}>
                Confirm
              </Button>
            </div>
            <div className="col-xs-12 col-md-6 order-2 order-md-1">
              <Button className="w-100 cancel-btn" onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
