import { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import toastWarningIcon from 'assets/images/toast-warning-icon.svg';
import { DashboardContext, type IDashboardContext } from '../components/Dashboard/DashboardContext';
import { ethers } from 'ethers';
import { type CartItem, type Web3AuthProviders } from 'types';
import { setAllowance } from './useSetAllowance';

export const useSetAllowanceSubmit = (web3AuthProviders: Web3AuthProviders) => {
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const [txStatus, setTxStatus] = useState<string | null>(null);

  const setAllowanceSubmit = async function name(bundle: CartItem | undefined) {
    if (!web3AuthProviders.wb3athProvider) {
      toast.warn('Web3 authentication provider is not initialized', {
        icon: () => <img src={toastWarningIcon} alt="Warning" />,
        autoClose: 15000,
      });
      return { success: false, error: 'Web3 authentication provider is not initialized' };
    }
    if (bundle?.investmentValue && bundle?.investmentValue > dashboardContext.available) {
      toast.warn('Insufficient balance', {
        icon: () => <img src={toastWarningIcon} alt="Warning" />,
        autoClose: 15000,
      });
      return { success: false, error: 'Insufficient balance' };
    }

    if (!web3AuthProviders) {
      console.error('Blockchain provider not available.');
      return { success: false, error: 'Blockchain provider not available' };
    }

    if (!bundle) {
      console.error('No bundle selected.');
      return { success: false, error: 'No bundle selected' };
    }
    const ethersProvider = new ethers.providers.Web3Provider(web3AuthProviders.wb3athProvider);
    const address = await web3AuthProviders?.provider?.getAddress();
    if (!address) {
      console.error('Address is undefined');
      return { success: false, error: 'Address is undefined' };
    }
    const signer = ethersProvider.getSigner(address);
    if (!signer) {
      console.error('No signer provided');
      return { success: false, error: 'Signer not available' };
    }

    const allowanceSet = await setAllowance(signer, bundle.investmentValue, 'eure');
    if (!allowanceSet.approved) {
      toast.warn('Unable to set allowance', { autoClose: 15000 });

      return { success: false, error: 'Unable to set allowance' };
    }
    setTxStatus(allowanceSet.approved ? 'Success' : 'Failed');
  };

  return { setAllowanceSubmit, txStatus };
};
