/* eslint-disable */
import { CHAIN_NAMESPACES, type CustomChainConfig } from '@web3auth/base';

export const CHAIN_CONFIG = {
  etherium: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x1',
    displayName: 'Ethereum Mainnet',
    blockExplorer: 'https://etherscan.io',
    ticker: 'ETH',
    tickerName: 'Ethereum',
    rpcTarget: 'https://rpc.ankr.com/eth', // This is the mainnet RPC we have added, please pass on your own endpoint while creating an app
  },
  mumbai: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x13881', // hex of 80001, polygon testnet
    rpcTarget: 'https://polygon-mumbai.g.alchemy.com/v2/46kvxCpnEx-2IKOC6j8ijNGtCZUHBSYf',
    // Avoid using public rpcTarget in production.
    // Use services like Infura, Quicknode etc.
    displayName: 'Polygon Mumbai Testnet',
    blockExplorer: 'https://mumbai.polygonscan.com/',
    ticker: 'MATIC',
    tickerName: 'Matic',
  },
  bnbtestnet: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x61',
    rpcTarget:
      'https://rpc.ankr.com/bsc_testnet_chapel/d20fbb120ea9b0bf700a9807db01853a16304207e3b2a025e2cb8eafd039fb6b',
    displayName: 'Binance SmartChain Testnet',
    blockExplorer: 'https://testnet.bscscan.com/',
    ticker: 'BNB',
    tickerName: 'BNB',
  },
  goerli: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x5',
    rpcTarget: 'https://eth-goerli.g.alchemy.com/v2/A4JpisGwmpkIh8la1Zkd-wmx7dDGsOVW',
    displayName: 'Ethereum Mainnet',
    blockExplorer: 'https://goerli.etherscan.io',
    ticker: 'ETH',
    tickerName: 'Ethereum',
  },
  ganache: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x1691',
    rpcTarget: 'HTTP://127.0.0.1:8545',
    displayName: 'Ganache Eth',
    blockExplorer: 'https://goerli.etherscan.io',
    ticker: 'ETH',
    tickerName: 'Ethereum',
  },
};

export type CHAIN_CONFIG_TYPE = keyof typeof CHAIN_CONFIG;

export const chain: {
  [key: string]: CustomChainConfig;
} = {
  'Goerli Testnet': {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x5',
    displayName: 'Goerli',
    tickerName: 'Goerli',
    ticker: 'ETH',
    rpcTarget: 'https://rpc.ankr.com/eth_goerli',
    blockExplorer: 'https://goerli.etherscan.io',
  },
  Ethereum: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x1',
    displayName: 'Ethereum Mainnet',
    rpcTarget: 'https://rpc.ankr.com/eth',
    blockExplorer: 'https://etherscan.io',
    ticker: 'ETH',
    tickerName: 'Ethereum',
  },
  Polygon: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x89', // hex of 137, polygon mainnet
    rpcTarget: 'https://rpc.ankr.com/polygon',
    // Avoid using public rpcTarget in production.
    // Use services like Infura, Quicknode etc
    displayName: 'Polygon Mainnet',
    blockExplorer: 'https://polygonscan.com',
    ticker: 'MATIC',
    tickerName: 'Matic',
  },
  'Polygon Mumbai Testnet': {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x13881', // hex of 80001, polygon testnet
    rpcTarget: 'https://rpc.ankr.com/polygon_mumbai',
    // Avoid using public rpcTarget in production.
    // Use services like Infura, Quicknode etc
    displayName: 'Polygon Mumbai Testnet',
    blockExplorer: 'https://mumbai.polygonscan.com/',
    ticker: 'MATIC',
    tickerName: 'Matic',
  },
  'Base Chain (Coinbase)': {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x14A33', // hex of 56
    rpcTarget: 'https://goerli.base.org',
    // Avoid using public rpcTarget in production.
    // Use services like Infura, Quicknode etc
    displayName: 'Base Goerli',
    blockExplorer: 'https://goerli.basescan.org/',
    ticker: 'ETH',
    tickerName: 'ETH',
  },
  'BNB Chain': {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x38', // hex of 56
    rpcTarget: 'https://rpc.ankr.com/bsc',
    // Avoid using public rpcTarget in production.
    // Use services like Infura, Quicknode etc
    displayName: 'Binance SmartChain Mainnet',
    blockExplorer: 'https://bscscan.com/',
    ticker: 'BNB',
    tickerName: 'BNB',
  },
  Avalanche: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0xA86A', // hex of 43114
    rpcTarget: 'https://rpc.ankr.com/avalanche-c',
    // Avoid using public rpcTarget in production.
    // Use services like Infura, Quicknode etc
    displayName: 'Avalanche C-Chain Mainnet',
    blockExplorer: 'https://subnets.avax.network/c-chain',
    ticker: 'AVAX',
    tickerName: 'AVAX',
  },
  Arbitrum: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0xA4B1', // hex of 42161
    rpcTarget: 'https://rpc.ankr.com/arbitrum',
    // Avoid using public rpcTarget in production.
    // Use services like Infura, Quicknode etc
    displayName: 'Arbitrum Mainnet',
    blockExplorer: 'https://arbiscan.io',
    ticker: 'AETH',
    tickerName: 'AETH',
  },
  Optimism: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0xA', // hex of 10
    rpcTarget: 'https://rpc.ankr.com/optimism',
    // Avoid using public rpcTarget in production.
    // Use services like Infura, Quicknode etc
    displayName: 'Optimism Mainnet',
    blockExplorer: 'https://optimistic.etherscan.io',
    ticker: 'OP',
    tickerName: 'OP',
  },
  Cronos: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x19', // hex of 25, cronos mainnet
    rpcTarget: 'https://rpc.cronos.org',
    // Avoid using public rpcTarget in production.
    // Use services like Infura, Quicknode etc
    displayName: 'Cronos Mainnet',
    blockExplorer: 'https://cronoscan.com/',
    ticker: 'CRO',
    tickerName: 'CRO',
  },
  Harmony: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x63564c40', // hex of 1666600000, Harmony mainnet
    rpcTarget: 'https://rpc.ankr.com/harmony',
    // Avoid using public rpcTarget in production.
    // Use services like Infura, Quicknode etc
    displayName: 'Harmony Mainnet',
    blockExplorer: 'https://explorer.harmony.one',
    ticker: 'ONE',
    tickerName: 'ONE',
  },
  Celo: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0xa4ec', // hex of 42220, Celo mainnet
    rpcTarget: 'https://rpc.ankr.com/celo',
    // Avoid using public rpcTarget in production.
    // Use services like Infura, Quicknode etc
    displayName: 'Celo Mainnet',
    blockExplorer: 'https://explorer.celo.org',
    ticker: 'CELO',
    tickerName: 'CELO',
  },
  Moonbeam: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x504', // hex of 1284, moonbeam mainnet
    rpcTarget: 'https://rpc.ankr.com/moonbeam',
    // Avoid using public rpcTarget in production.
    // Use services like Infura, Quicknode etc
    displayName: 'Moonbeam Mainnet',
    blockExplorer: 'https://moonbeam.moonscan.io',
    ticker: 'GLMR',
    tickerName: 'GLMR',
  },
  Moonriver: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x505', // hex of 1285, moonriver mainnet
    rpcTarget: 'https://rpc.api.moonriver.moonbeam.network',
    // Avoid using public rpcTarget in production.
    // Use services like Infura, Quicknode etc
    displayName: 'Moonriver Mainnet',
    blockExplorer: 'https://moonriver.moonscan.io',
    ticker: 'MOVR',
    tickerName: 'MOVR',
  },
  Klaytn: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x2019', // hex of 8217, Klaytn mainnet
    rpcTarget: 'https://public-node-api.klaytnapi.com/v1/cypress',
    // Avoid using public rpcTarget in production.
    // Use services like Infura, Quicknode etc
    displayName: 'Klaytn Mainnet',
    blockExplorer: 'https://scope.klaytn.com',
    ticker: 'KLAY',
    tickerName: 'KLAY',
  },
  Flare: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0xE', // hex of 14
    rpcTarget: 'https://flare-api.flare.network/ext/C/rpc',
    // Avoid using public rpcTarget in production.
    // Use services provided by Flare or other node providers
    displayName: 'Flare Mainnet',
    blockExplorer: 'https://flare-explorer.flare.network/',
    ticker: 'FLR',
    tickerName: 'FLR',
  },
  Songbird: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x13', // hex of 19
    rpcTarget: 'https://songbird-api.flare.network/ext/C/rpc',
    // Avoid using public rpcTarget in production.
    // Use services provided by Flare or other node providers
    displayName: 'Songbird canary network',
    blockExplorer: 'https://songbird-explorer.flare.network',
    ticker: 'SGB',
    tickerName: 'SGB',
  },
  zKatana: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x133E40', // hex of 1261120
    rpcTarget: 'https://rpc.zkatana.gelato.digital',
    // Avoid using public rpcTarget in production.
    // Use services like Infura, Quicknode etc
    displayName: 'zKatana Testnet',
    blockExplorer: 'https://zkatana.blockscout.com',
    ticker: 'ETH',
    tickerName: 'ETH',
  },
  // SKALE: {
  //   chainNamespace: CHAIN_NAMESPACES.EIP155,
  //   chainId: "0x79f99296",
  //   rpcTarget: "https://mainnet.skalenodes.com/v1/elated-tan-skat",
  //   // Avoid using public rpcTarget in production.
  //   // Use services like Infura, Quicknode etc
  //   displayName: "SKALE Europa Hub Mainnet",
  //   blockExplorer: "https://elated-tan-skat.explorer.mainnet.skalenodes.com/",
  //   ticker: "sFUEL",
  //   tickerName: "sFUEL",
  // },
};
