import type { BundleData } from 'types/bundleData';
import i18n from 'i18next';
import { POLYGON_ADDRESSES } from '../blockchain/addresses/Polygon';

// This should be loaded from somewhere in the future

// Please, do not delete!
const getBundles = (): BundleData[] => {
  return [
    {
      key: 1,
      name: i18n.t('dashboard:bundles.header1'),
      address: POLYGON_ADDRESSES.Stabilizer,
      image: '/images/bundles/stabilizer_bundle.png',
      coins: [
        {
          coin: 'BTC',
          percentage: 60,
        },
        {
          coin: 'ETH',
          percentage: 40,
        },
      ],
      investor: 1,
      performance: 0,
      shortDescription: i18n.t('dashboard:bundles.content1'),
      faqText: i18n.t('dashboard:faq.answer1-1'),
    },
    {
      key: 2,
      name: i18n.t('dashboard:bundles.header2'),
      address: POLYGON_ADDRESSES.Dynamic,
      image: '/images/bundles/dynamic_bundle.png',
      coins: [
        { coin: 'BTC', percentage: 20 },
        { coin: 'ETH', percentage: 20 },
        { coin: 'SOL', percentage: 9 },
        { coin: 'AVAX', percentage: 3.5 },
        { coin: 'LINK', percentage: 14 },
        { coin: 'MATIC', percentage: 3.5 },
        { coin: 'UNI', percentage: 3.5 },
        { coin: 'LDO', percentage: 3.5 },
        { coin: 'RNDR', percentage: 3.5 },
        { coin: 'GRT', percentage: 3 },
        { coin: 'MKR', percentage: 3 },
        { coin: 'AAVE', percentage: 2.5 },
        { coin: 'SAND', percentage: 2.5 },
        { coin: 'SNX', percentage: 3.5 },
        { coin: 'MANA', percentage: 2.5 },
        { coin: 'WOO', percentage: 2.5 },
      ],
      investor: 2,
      performance: 0,
      shortDescription: i18n.t('dashboard:bundles.content2'),
      faqText: i18n.t('dashboard:faq.answer1-2'),
    },
    {
      key: 3,
      name: i18n.t('dashboard:bundles.header3'),
      address: '',
      image: '/images/bundles/growth_bundle.png',
      coins: [
        { coin: 'BTC', percentage: 10 },
        { coin: 'ETH', percentage: 10 },
        { coin: 'SOL', percentage: 11 },
        { coin: 'AVAX', percentage: 6 },
        { coin: 'LINK', percentage: 9.5 },
        { coin: 'MATIC', percentage: 6.5 },
        { coin: 'UNI', percentage: 6.5 },
        { coin: 'LDO', percentage: 6 },
        { coin: 'RNDR', percentage: 6.5 },
        { coin: 'GRT', percentage: 3.5 },
        { coin: 'MKR', percentage: 4.5 },
        { coin: 'AAVE', percentage: 3.5 },
        { coin: 'SAND', percentage: 3.5 },
        { coin: 'SNX', percentage: 6 },
        { coin: 'MANA', percentage: 3.5 },
        { coin: 'WOO', percentage: 3.5 },
      ],
      investor: 3,
      performance: 0,
      shortDescription: i18n.t('dashboard:bundles.content3'),
      faqText: i18n.t('dashboard:faq.answer1-3'),
    },
  ];
};

export default getBundles;
