import React from 'react';
import './Loading.scss';
import { useTranslation } from 'react-i18next';

export const Loading = (): JSX.Element => {
  const { t } = useTranslation('home');
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ height: 425 }}
    >
      <div className="spinner-border  d-block" role="status">
        <span className="visually-hidden">{t('loading')}...</span>
      </div>
      <br />
      <div className="loading d-block">{t('loading')}...</div>
    </div>
  );
};
