import { type Coin } from 'types/bundleData';
import coinsFromConfig from 'config/coinsConfig';

interface Props {
  coins: Coin[];
  coinSize: number;
}

const getCoins = (coins: Coin[], coinSize: number): JSX.Element[] => {
  const coinsC = coinsFromConfig();

  const coinsDivs = [];
  for (let loop = 0; loop < coins.length && loop < 4; loop++) {
    const dynamicKey = coins[loop].coin as keyof typeof coinsC;
    const myDynamicPropValue = coinsC[dynamicKey];
    const coinDetails = myDynamicPropValue;

    coinsDivs.push(
      <img
        src={`/cryptoicons/${coinDetails.icon}`}
        key={loop}
        className="coin-icon"
        width={coinSize}
        height={coinSize}
        style={{ zIndex: 200 - loop }}
      />,
    );
  }
  if (coinsDivs.length > 3) {
    coinsDivs.push(
      <img
        key={5}
        src={`/cryptoicons/more-coins.png`}
        className=" coin-icon-more"
        width={coinSize}
        height={coinSize}
        style={{
          zIndex: 200 - coinsDivs.length,
        }}
      />,
    );
  }
  return coinsDivs;
};

export const CoinDisplay = (props: Props): JSX.Element => {
  const coins: Coin[] = props.coins;
  return <div className="text-center coin-symbols">{getCoins(coins, props.coinSize)}</div>;
};
