import Layout from 'components/Layout/Layout';
import React from 'react';

export const Help = (): JSX.Element => {
  return (
    <Layout>
      <div>Help Center</div>
    </Layout>
  );
};
