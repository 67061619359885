import { createClient } from '@supabase/supabase-js';

import { type NotionalAmountsTable } from 'types';

const projectUrl = process.env.REACT_APP_SUPABASE_URL as string;
const anonKey = process.env.REACT_APP_SUPABASE_ANON_KEY as string;
const supabase = createClient(projectUrl, anonKey);

export async function getNotionalAmounts(bundleName: string): Promise<NotionalAmountsTable[]> {
  if (!bundleName) {
    return [];
  }

  const { data, error } = await supabase.from(bundleName.toLowerCase()).select();
  if (error) {
    console.error('Failed to fetch notional amounts:', error.message);
    return [];
  }

  return data;
}
