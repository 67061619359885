import { Header } from 'components/Layout/Header';
import { Footer } from './Footer';

import './Layout.scss';

interface Props {
  children?: JSX.Element | JSX.Element[];
}

const Layout: React.FC<Props> = (props: Props) => {
  return (
    <>
      <div id="layout-wrapper">
        <Header />
        {props.children}
      </div>
      <Footer />
    </>
  );
};

Layout.propTypes = {};

export default Layout;
