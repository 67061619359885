/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useEffect, useState, useContext, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import cartItemDelete from 'assets/images/cart-item-delete.svg';
import cartItemEdit from 'assets/images/cart-item-edit.svg';
import cartEditApprove from 'assets/images/cart-edit-approve.svg';
import { type CartItem } from 'types/cart';
import { DashboardContext, type IDashboardContext } from '../DashboardContext';
import { useTranslation } from 'react-i18next';
import toastWarningIcon from 'assets/images/toast-warning-icon.svg';

import './DashboardShoppingCart.scss';
import CurrencyInput from 'react-currency-input-field';
import { toast } from 'react-toastify';

export const DashboardShoppingCart = (): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const [totalCartItems, setTotalCartItems] = useState(0);
  const [subTotal, setSubTotal] = useState(110);
  const [cartItems, setCartItems] = useState<JSX.Element[]>([]);
  const inputRef = useRef<any>(null);
  // eslint-disable-next-line

  const dashboardContext = useContext<IDashboardContext>(DashboardContext);

  const handleClose = (): void => {
    dashboardContext.setShowShoppingCart(false);
  };

  const processInvestment: () => Promise<void> = async () => {
    if (subTotal >= dashboardContext.available) {
      toast.warn(t('invest.failed1'), {
        icon: () => <img src={toastWarningIcon} />,
      });
      return;
    }
    if (subTotal > 5000) {
      toast.warn(t('invest.failed4'), {
        icon: () => <img src={toastWarningIcon} />,
      });
      return;
    }

    toast.dismiss();
    dashboardContext.setShowShoppingCart(false);
    dashboardContext.setShowCheckout(true);
  };

  const deleteItem = (index: number): void => {
    const items = dashboardContext.shoppingCartItems;

    if (items.length > 1) {
      items.splice(index, 1);
      dashboardContext.setShoppingCartItems(items.slice());
    } else {
      dashboardContext.setShoppingCartItems([]);
    }
  };

  // const getPerformanceFigures = (item: ICartItem): string => {
  //   const prefix = item.bundle.performance >= 0 ? '+' : '';
  //   return formatValue({
  //     value: item.bundle.performance.toString(),
  //     decimalScale: 2,
  //     prefix,
  //   });
  // };

  useEffect(() => {
    if (dashboardContext.showShoppingCart && dashboardContext.startedGuidedTour) {
      document.body.style.overflow = 'auto';
    }
  }, [dashboardContext.showShoppingCart]);

  useEffect(() => {
    const getItems = (items: CartItem[]): JSX.Element[] => {
      const itemDivs = [];
      setTotalCartItems(items.length);
      let runningTotal = 0;

      const newItems = [...items];

      for (let loop = 0; loop < newItems.length; loop++) {
        const item = newItems[loop];
        runningTotal += item.investmentValue;
        itemDivs.push(
          <div key={loop} className="item-container">
            <div className="d-flex item-header">
              <div className="flex-grow-1">{item.bundle.name}</div>
              <div className="">
                {dashboardContext.currencyInfo.symbol}
                {item.editing ? (
                  <CurrencyInput
                    ref={inputRef}
                    className="item-input"
                    step={0.01}
                    decimalsLimit={2}
                    decimalScale={2}
                    defaultValue={item.investmentValue * dashboardContext.currencyInfo.rate}
                    onValueChange={(value, name, values) => {
                      if (values?.float !== undefined && values?.float !== null) {
                        item.investmentValue = values?.float / dashboardContext.currencyInfo.rate;
                      } else {
                        item.investmentValue = 0;
                      }
                    }}
                  />
                ) : (
                  // formatValue({ value: item.investmentValue.toString(), decimalScale: 2 })
                  (item.investmentValue * dashboardContext.currencyInfo.rate).toFixed(2)
                  // item.investmentValue.toFixed(2)
                )}
              </div>
            </div>
            <div className="d-flex item-performance">
              <div className="flex-grow-1">
                {/* 3 year performance
                {item.bundle.performance === 0 ? getPerformanceFigures(item) : '--'}% */}
              </div>
              <div
                className="item-delete"
                onClick={() => {
                  deleteItem(loop);
                }}
              >
                <img src={cartItemDelete} />
              </div>
              <div className="item-edit">
                {item.editing ? (
                  <img
                    src={cartEditApprove}
                    onClick={() => {
                      newItems[loop].editing = false;
                      dashboardContext.setShoppingCartItems(newItems);
                    }}
                  />
                ) : (
                  <img
                    src={cartItemEdit}
                    onClick={() => {
                      newItems[loop].editing = true;
                      dashboardContext.setShoppingCartItems(newItems);
                      setTimeout(() => {
                        inputRef.current.focus();
                        inputRef.current.select();
                      }, 100);
                    }}
                  />
                )}
              </div>
            </div>
          </div>,
        );
      }
      setSubTotal(runningTotal);
      return itemDivs;
    };

    setCartItems(getItems(dashboardContext.shoppingCartItems));
  }, [dashboardContext.shoppingCartItems, dashboardContext.currencyInfo.symbol]);

  return (
    <div className="shopping-cart ">
      <Modal
        show={dashboardContext.showShoppingCart}
        onHide={handleClose}
        animation={false}
        className={`shopping-cart-modal d-none d-md-block ${
          dashboardContext.showGuidedTour ? 'dashboard-layout-tour' : ''
        }`}
        backdropClassName="shopping-cart-backdrop"
      >
        <Modal.Header>
          <Modal.Title>
            {t('cart.my')} ({totalCartItems})
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="cart-items">{cartItems}</div>
          <div className="d-flex">
            <div className="flex-grow-1 subtotal-label">{t('cart.subtotal')}</div>
            <div className="subtotal-value">
              {/* {getCurrencySymbol(dashboardContext.currency)}
              {formatValue({ value: subTotalDisplay.toString(), decimalScale: 2 })} */}
              {dashboardContext.currencyInfo.symbol}
              {(subTotal * dashboardContext.currencyInfo.rate).toFixed(2)}
              {/* {subTotal.toFixed(2)} */}
            </div>
          </div>
          <p className="notice">*{t('cart.statement')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="w-100 process-button"
            onClick={processInvestment}
            disabled={dashboardContext.shoppingCartItems?.length < 1}
          >
            {t('cart.process')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
