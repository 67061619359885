import FAQ from 'components/FAQ';
import { InvestorFAQ } from 'components/InvestorFAQ';
import Layout from 'components/Layout/Layout';
import { SelectProfile } from 'components/SelectProfile';
import React from 'react';

export const SelectInvestorProfile = (): JSX.Element => {
  return (
    <Layout>
      <SelectProfile />

      <FAQ>
        <InvestorFAQ />
      </FAQ>
    </Layout>
  );
};
