import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { StartView } from './StartView';
import { ProfileSelectCard } from './ProfileSelectCard';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line
import { UserServices } from 'services/UserServices';
import { Navbar } from 'react-bootstrap';
import { InvestorFAQ } from './InvestorFAQ';
import { useTranslation } from 'react-i18next';

import './SelectProfile.scss';

export const SelectProfile = (): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const [selectedProfile, setSelectedProfile] = useState(0);
  // eslint-disable-next-line
  const navigate = useNavigate();

  const handleProfileConfirm = async (): Promise<void> => {
    if (selectedProfile !== 0) {
      await UserServices.updateSelectedProfile(selectedProfile);
      navigate('/welcome-video');
    }
  };

  return (
    <Container className="select-profile ">
      <Row>
        <Col sm={12} md={6}>
          <StartView step={1}>
            <h1>
              {t('profile.header1')}
              <br />
              {t('profile.header2')}
            </h1>
            <p className="d-md-none tagline">{t('profile.content')}</p>
            <div className="d-none d-md-block">
              <InvestorFAQ />
            </div>
          </StartView>
        </Col>
        <Col sm={12} md={6}>
          <div className="profile-options">
            <ProfileSelectCard
              header={t('profile.careful')}
              description={t('profile.carefulDesc')}
              selectedProfile={selectedProfile}
              setSelectedProfile={setSelectedProfile}
              index={1}
            />
            <ProfileSelectCard
              header={t('profile.balanced')}
              description={t('profile.balancedDesc')}
              selectedProfile={selectedProfile}
              setSelectedProfile={setSelectedProfile}
              index={2}
            />
            <ProfileSelectCard
              header={t('profile.adventures')}
              description={t('profile.adventuresDesc')}
              selectedProfile={selectedProfile}
              setSelectedProfile={setSelectedProfile}
              index={3}
            />

            <button
              disabled={selectedProfile === 0}
              className="btn btn-primary btn-confirm w-100 d-none d-md-block"
              onClick={handleProfileConfirm}
            >
              {t('profile.btn')}
            </button>
          </div>
        </Col>
      </Row>
      <Navbar fixed="bottom" className="confirm-button-nav d-md-none">
        <div>
          <button
            disabled={selectedProfile === 0}
            className="btn btn-primary btn-confirm w-100"
            onClick={handleProfileConfirm}
          >
            {t('profile.btn')}
          </button>
        </div>
      </Navbar>
    </Container>
  );
};
