import { type ReactNode } from 'react';
import './StartView.scss';

interface Props {
  children: ReactNode;
  step: number;
}

export const StartView: React.FC<Props> = (props: Props) => {
  return (
    <div className="start-view">
      <div className="progressgroup">
        {props.step === 0 && <div className="greenprogress"></div>}
        <div className="greyprogess"></div>

        {props.step === 1 && <div className="greenprogress"></div>}
      </div>
      {props.children}
    </div>
  );
};
