import React, { useContext, useEffect, useState } from 'react';
import './DashboardBundles.scss';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { Bundle } from '../Bundles/Bundle';
import getBundles from 'config/bundlesConfig';
import { useTranslation } from 'react-i18next';
import { LeftArrow, RightArrow } from 'components/Arrows';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import infoIcon from 'assets/images/info-icon.svg';
import bundleIcon from 'assets/images/bundles-icon.png';
import { useAuth } from 'hooks/useAuth';
import { UserServices } from 'services/UserServices';
import { DashboardContext, type IDashboardContext } from './DashboardContext';
import { type BundleData } from 'types';

export const DashboardBundles = (): JSX.Element => {
  const { t } = useTranslation('dashboard');
  const [activeBundle, setActiveBundle] = useState<BundleData>();
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const { isSignedIn } = useAuth();
  const bundles = getBundles();

  const fetchSelectedProfile = async (): Promise<void> => {
    const profile = await UserServices.getSelectedProfile();
    dashboardContext.setSelectedProfile(profile);
  };

  useEffect(() => {
    fetchSelectedProfile().catch(console.error);
  }, [isSignedIn, dashboardContext.selectedProfile]);

  // useEffect(() => {
  //   dashboardContext.setSelectedProfile(profile);
  // }, [dashboardContext.setSelectedProfile]);

  const getProfileString = (profile: number): string => {
    switch (profile) {
      case 1:
        return 'Careful';
      case 2:
        return 'Balanced';
      case 3:
        return 'Adventurous';
      default:
        return 'Undefined';
    }
  };

  const Header = (): JSX.Element => (
    <div className="d-flex justify-content-between">
      <h3 className="dashboard-bundles-header">
        {t('bundles.investing')}{' '}
        <OverlayTrigger
          overlay={
            <Popover id="dashboard-bundles-popover">
              <Popover.Header as="h3" className="d-flex align-items-center">
                <img src={bundleIcon} alt="Bundles" />
                <div className="mt-1">{t('bundles.bundles')}</div>
              </Popover.Header>
              <Popover.Body>{t('bundles.content')}</Popover.Body>
            </Popover>
          }
          placement="bottom-start"
        >
          <img className="infoIcon" src={infoIcon} alt="Info" width={14} height={14} />
        </OverlayTrigger>
      </h3>
      <div className="pt-1 ml-1 d-flex">
        <LeftArrow /> <RightArrow />
      </div>
    </div>
  );

  return (
    <div className="dashboard-bundles">
      <div className="dashboard-bundles-content d-flex flex-column">
        <ScrollMenu Header={Header}>
          {bundles.map((bundle) => (
            <Bundle
              badgeName={getProfileString(bundle.investor)}
              badgeColor={
                getProfileString(bundle.investor) ===
                getProfileString(dashboardContext.selectedProfile)
                  ? '#defdf2'
                  : '#ececee'
              }
              activeBundle={activeBundle}
              setActiveBundle={setActiveBundle}
              key={bundle.key}
              bundle={bundle}
            />
          ))}
        </ScrollMenu>
      </div>
    </div>
  );
};
