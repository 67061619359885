import backIcon from 'assets/images/bundle-back-icon.svg';
import { Accordion } from 'react-bootstrap';
import './BundleFAQ.scss';
import { type BundleData } from 'types';
import { useTranslation } from 'react-i18next';

interface Props {
  setExpanded: any;
  bundle: BundleData;
}

export const BundleFAQ = (props: Props): JSX.Element => {
  const { t } = useTranslation('dashboard');
  return (
    <div className="bundle-faq">
      <h5
        onClick={() => {
          props.setExpanded(false);
        }}
      >
        <img
          className="bundle-back d-none d-md-inline-block"
          src={backIcon}
          alt="Back"
          height={18}
        />
        FAQ
      </h5>
      <div className="bundle-faq-content">
        <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>{t('faq.question1')}</Accordion.Header>
            <Accordion.Body>{props.bundle.faqText}</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>{t('faq.question2')}</Accordion.Header>
            <Accordion.Body>{t('faq.answer2')}</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>{t('faq.question3')}</Accordion.Header>
            <Accordion.Body>{t('faq.answer3')}</Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>{t('faq.question4')}</Accordion.Header>
            <Accordion.Body>{t('faq.answer4')}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};
