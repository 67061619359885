import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import infoIcon from 'assets/images/info-icon.svg';

interface Props {
  tooltip: string;
}
export const InfoTooltip = (props: Props): JSX.Element => {
  return (
    <OverlayTrigger
      trigger={['hover', 'focus', 'click']}
      overlay={
        <Tooltip style={{ textAlign: 'left' }} className="fenus-tooltip">
          {props.tooltip}
        </Tooltip>
      }
      placement="top"
    >
      <img src={infoIcon} alt="Info" width={14} height={14} />
    </OverlayTrigger>
  );
};
