/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { DashboardContext, type IDashboardContext } from '../DashboardContext';
// import feedbackIcon from 'assets/images/feedback-icon.svg';
import helpLinkIcon from 'assets/images/external-link-icon.svg';
import guidedTourIcon from 'assets/images/guided-tour-icon.svg';

import './DashboardQuestMarkPopup.scss';
import { Link } from 'react-router-dom';
// import { useAuth } from 'hooks/useAuth';
// import { UserServices } from 'services/UserServices';
import { useTranslation } from 'react-i18next';
// import i18n from 'i18next';

export const DashboardQuestMarkPopup = (): JSX.Element => {
  const { t } = useTranslation('dashboard');
  //   const { isSignedIn } = useAuth();
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const handleClose = (): void => {
    dashboardContext.setShowQuestionMarkPopup(false);
  };
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    if (dashboardContext.startedGuidedTour) {
      event.preventDefault();
      dashboardContext.setShowQuestionMarkPopup(false);
    }
  };

  return (
    <div className="question-mark">
      <Modal
        id="question-mark-popup"
        show={dashboardContext.showQuestionMarkPopup}
        onHide={handleClose}
        animation={false}
        className={`question-mark-popup-modal d-none d-md-block ${
          dashboardContext.showGuidedTour ? 'dashboard-layout-tour' : ''
        }`}
        backdropClassName="question-mark-popup-backdrop"
      >
        <Modal.Header>
          {/* <Modal.Title>{t('account.useraccount')}</Modal.Title> */}
          <Modal.Title>Help</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Link
            onClick={handleClick}
            className="menu-item"
            to={`${
              process.env.REACT_APP_website_domain ? process.env.REACT_APP_website_domain : ''
            }/help/`}
            target="_blank"
          >
            <div className="content-item">
              <div className="content-item-button" role="button">
                <div className="title">{t('help.helpcenter')}</div>
                <div className="d-flex">
                  <img src={helpLinkIcon} alt="Help external link" />
                </div>
              </div>
            </div>
          </Link>
          <Link className="menu-item" to="/guided-tour">
            <div className="content-item">
              <div
                className="content-item-button"
                role="button"
                onClick={() => {
                  dashboardContext.setShowQuestionMarkPopup(false);
                }}
              >
                <div className="title">{t('help.guidedtour')}</div>
                <div className="d-flex">
                  <img src={guidedTourIcon} alt="Guided Tour Icon" />
                </div>
              </div>
            </div>
          </Link>
          <div
            role="button"
            className="menu-item"
            onClick={async () => {
              if (!dashboardContext.startedGuidedTour) {
                dashboardContext.setShowQuestionMarkPopup(false);
                // dashboardContext.setShowSelectProfilePopup(true);
                dashboardContext.setShowShareFeedbackPopup(true);
              }
            }}
          >
            {/* <div className="content-item">
              <div className="content-item-button">
                <div className="title">{t('help.feedback')}</div>
                <div className="d-flex">
                  <p className="flex-grow-1">
                  {i18n.language === 'en' ? t('account.en') : t('account.nl')}
                </p>
                  <img src={feedbackIcon} alt="Share Feedback Icon" />
                </div>
              </div>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
