// utils.ts
import { BigNumber, ethers } from 'ethers';

/**
 * Calculate the percentage difference between a number and a BigNumber.
 * @param currentPrice - The current price as a number.
 * @param averagePrice - The average price as a BigNumber.
 * @returns The percentage difference as a string.
 */
export function calculatePercentageChange(currentPrice: number, averagePrice: BigNumber): string {
  const currentPriceBN = ethers.utils.parseUnits(currentPrice.toString(), 18);
  const change = currentPriceBN.sub(averagePrice);
  const percentageChangeBN = change
    .mul(BigNumber.from(100))
    .mul(BigNumber.from(10).pow(18))
    .div(averagePrice);
  const percentageChangeNumber = parseFloat(ethers.utils.formatUnits(percentageChangeBN, 18));
  return percentageChangeNumber.toFixed(2);
}
