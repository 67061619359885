import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// images
import profile_selected from 'assets/images/profile_selected.svg';
import profile_empty from 'assets/images/profile_empty.svg';

interface Props {
  header: string;
  description: string;
  setSelectedProfile: any;
  selectedProfile: number;
  index: number;
}

export const ProfileSelectCard = (props: Props): JSX.Element => {
  return (
    <div
      className={`profile-option ${
        props.selectedProfile === props.index ? 'profile-selected' : ''
      }`}
      onClick={() => props.setSelectedProfile(props.index)}
    >
      <Container>
        <Row>
          <Col xs={0} md={2} xl={1} className="d-none d-block">
            <img
              src={props.selectedProfile === props.index ? profile_selected : profile_empty}
              alt="selected"
            />
          </Col>
          <Col xs={12} md={10} xl={11} className="">
            <h2>
              {props.header}
              <img
                src={props.selectedProfile === props.index ? profile_selected : profile_empty}
                width={20}
                height={20}
                alt="selected"
                className="float-end"
              />
            </h2>
            <p>{props.description}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
