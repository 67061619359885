import { Accordion, Col, Container, Row } from 'react-bootstrap';
import { type IDashboardContext, DashboardContext } from 'components/Dashboard/DashboardContext';
import { useContext, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './PortfolioInvestmentsMobile.scss';
import { type Investment } from 'types/investment';
// import { formatValue } from 'react-currency-input-field';
import { CoinDisplay } from 'components/Bundles/CoinDisplay';
import { PortfolioBundlePerformance } from './PortfolioBundlePerformance';
import { NoInvestments } from './NoInvestments';
import { sortInvestmentsByValue } from 'utils/sortInvestmentsByValue';
import { useIndexTokenPrice } from 'hooks/useIndexTokenPrice';
import { formatCurrencyPerformance } from 'utils/formatCurrencyPerformance';

type IndexTokenPrices = Record<string, number>;

interface State {
  investmentItems: Investment[];
  activeInvestment: number;
  indexTokenPrices: Record<string, number>;
}

type Action =
  | { type: 'SET_INVESTMENT_ITEMS'; payload: Investment[] }
  | { type: 'SET_ACTIVE_INVESTMENT'; payload: number }
  | { type: 'SET_INDEX_TOKEN_PRICES'; payload: IndexTokenPrices };

const initialState: State = {
  investmentItems: [],
  activeInvestment: 0,
  indexTokenPrices: {},
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_INVESTMENT_ITEMS':
      return { ...state, investmentItems: action.payload };
    case 'SET_ACTIVE_INVESTMENT':
      return { ...state, activeInvestment: action.payload };
    case 'SET_INDEX_TOKEN_PRICES':
      return { ...state, indexTokenPrices: action.payload };
    default:
      return state;
  }
};

export const PortfolioInvestmentsMobile = (): JSX.Element => {
  const { t } = useTranslation('portfolio');
  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const [investmentItems, setInvestmentItems] = useState<JSX.Element[]>([]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { getIndexTokenPrice } = useIndexTokenPrice();

  useEffect(() => {
    const fetchIndexTokenPrices = async (items: Investment[]): Promise<void> => {
      const prices: IndexTokenPrices = {};
      for (const item of items) {
        try {
          prices[item.bundle.name] = await getIndexTokenPrice(item.bundle.name);
        } catch (error) {
          console.error('Error fetching index token price:', error);
        }
      }
      dispatch({ type: 'SET_INDEX_TOKEN_PRICES', payload: prices });
    };

    const sortedItems = sortInvestmentsByValue(dashboardContext.portfolio.investments);
    dispatch({ type: 'SET_INVESTMENT_ITEMS', payload: sortedItems });
    fetchIndexTokenPrices(sortedItems);
  }, [
    dashboardContext.portfolio.investments,
    dashboardContext.shoppingCartItems,
    getIndexTokenPrice,
  ]);

  useEffect(() => {
    const getItems = (items: Investment[]): JSX.Element[] => {
      const itemDivs = [];
      for (let loop = 0; loop < items.length; loop++) {
        const item = items[loop];
        const isPositive =
          parseFloat(dashboardContext.performances[item.bundle.name]?.percentagePerformance) >= 0;
        if (Math.round(item.currentInvestmentValue * 10) / 10 !== 0) {
          itemDivs.push(
            <Accordion.Item eventKey={loop.toString()} key={loop}>
              <Accordion.Header>
                <div key={loop} className="item-container d-flex">
                  <div className="flex-grow-1">
                    <div className="d-flex item-header">
                      <div className="flex-grow-1 d-flex align-center">
                        <div className="item-name align-middle">{item.bundle.name}</div>
                        <CoinDisplay coins={item.bundle.coins} coinSize={24} />
                      </div>
                      <div className="">
                        {/* $
                        {formatValue({
                          value: item.currentInvestmentValue.toString(),
                          decimalScale: 2,
                        })} */}
                        {dashboardContext.currencyInfo.symbol}
                        {(item.currentInvestmentValue * dashboardContext.currencyInfo.rate).toFixed(
                          2,
                        )}
                      </div>
                    </div>
                    <div className="d-flex item-performance justify-content-end">
                      <div className="value">
                        {formatCurrencyPerformance(
                          dashboardContext.currencyInfo.symbol,
                          parseFloat(
                            dashboardContext.performances[item.bundle.name]?.balancePerformance,
                          ) * dashboardContext.currencyInfo.rate,
                        )}
                        {dashboardContext.performances[item.bundle.name]?.percentagePerformance !==
                          'N/A' && (
                          <div
                            className={`percent float-end ${isPositive ? 'positive' : 'negative'}`}
                          >
                            {dashboardContext.performances[item.bundle.name]?.percentagePerformance}
                            %
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <PortfolioBundlePerformance activeInvestment={item} />
              </Accordion.Body>
            </Accordion.Item>,
          );
        }
      }

      return itemDivs;
    };

    setInvestmentItems(getItems(state.investmentItems));
  }, [dashboardContext.shoppingCartItems, state.investmentItems]);

  return (
    <div
      className={
        // dashboardContext.portfolio.numberOfInvestments !== 0
        investmentItems.length !== 0 ? 'portfolio-investment-mobile' : 'no-inv-outer'
      }
    >
      {/* {dashboardContext.portfolio.numberOfInvestments === 0 ? ( */}
      {investmentItems.length === 0 ? (
        // <div className="no-investment">It seems like you haven’t invested yet.</div>
        <NoInvestments />
      ) : (
        <Container className="p-0">
          <Row className="header">
            <Col>
              {t('investments.my')}
              {/* ({dashboardContext.portfolio.numberOfInvestments}) */}({investmentItems.length})
            </Col>
            <Col className="text-end">
              {t('investments.value')}
              <br />
              {t('investments.performance')}
            </Col>
          </Row>
          <Row>
            <Col>
              <Accordion defaultActiveKey="-1" flush>
                {investmentItems}
              </Accordion>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};
