/* eslint-disable */
import {
  ADAPTER_EVENTS,
  type SafeEventEmitterProvider,
  WALLET_ADAPTERS,
  type WALLET_ADAPTER_TYPE,
} from '@web3auth/base';
import { Web3AuthNoModal } from '@web3auth/no-modal';
import {
  type LOGIN_PROVIDER_TYPE,
  OpenloginAdapter,
  OPENLOGIN_NETWORK,
} from '@web3auth/openlogin-adapter';
import React, {
  createContext,
  type FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { CHAIN_CONFIG, type CHAIN_CONFIG_TYPE } from '../config/chainConfig';
import { type WEB3AUTH_NETWORK_TYPE } from '../config/web3AuthNetwork';
import { getWalletProvider, type WalletProvider } from 'types/wallet';
import { type TransactionResponse } from 'types/transaction';
import { MetamaskAdapter } from '@web3auth/metamask-adapter';
import { onAuthStateChanged, type User } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from 'config/firebaseConfig';
import { UserServices } from './UserServices';
import { type ethers } from 'ethers';
import { TransactionState } from 'constants/TransactionState';
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider';
import { chain as chainC } from '../config/chainConfig';
import * as process from 'process';

// const moneriumClient = new MoneriumClient('sandbox');

// const moneriumAuthorizationResponse = await fetch(
//   'https://monerium.dev/auth',
//   {
//     method: 'POST',
//     body: JSON.stringify({
//       ethereumWalletAddress: userWalletAddress,
//       signedMessage: signedMessage,
//     }),
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   }
// );

export interface IWeb3AuthContext {
  web3Auth: Web3AuthNoModal | null;
  provider: WalletProvider | null;
  wb3athProvider: any;
  isLoading: boolean;

  firebaseUser: User | null;
  setFirebaseUser: (_user: User) => void;

  user: unknown;
  chain: string;
  loginJWT: (idToken: string) => Promise<void>;
  login: (
    adapter: WALLET_ADAPTER_TYPE,
    provider: LOGIN_PROVIDER_TYPE,
    redirectUrl?: string,
    loginHint?: string,
  ) => Promise<void>;
  logout: () => Promise<void>;
  setIsLoading: (loading: boolean) => void;
  getUserInfo: () => Promise<any>;
  signMessage: (message: string) => Promise<any>;
  getAccounts: () => Promise<any>;
  getAddress: () => Promise<string>;
  getBalance: () => Promise<any>;

  signTransaction: (transaction: ethers.providers.TransactionRequest) => Promise<string>;
  signAndSendTransaction: (
    transaction: ethers.providers.TransactionRequest,
  ) => Promise<TransactionResponse>;

  setWalletProvider: (web3authProvider: SafeEventEmitterProvider) => void;
}

export const Web3AuthContext = createContext<IWeb3AuthContext>({
  web3Auth: null,
  provider: null,
  wb3athProvider: null,
  isLoading: false,

  firebaseUser: null,
  setFirebaseUser: (_user: User) => {},
  user: null,
  chain: '',
  setIsLoading: (loading: boolean) => {},
  loginJWT: async (idToken: string) => {},
  login: async (
    adapter: WALLET_ADAPTER_TYPE,
    provider: LOGIN_PROVIDER_TYPE,
    redirectUrl?: string,
    loginHint?: string,
  ) => {},
  logout: async () => {},
  getUserInfo: async () => {},
  signMessage: async (message: string) => {},
  getAccounts: async () => {},
  getAddress: async () => '',
  getBalance: async () => {},
  signTransaction: async (transaction: ethers.providers.TransactionRequest) => {
    return '';
  },
  signAndSendTransaction: async (transaction: ethers.providers.TransactionRequest) => {
    return { status: TransactionState.Failed, transactionHash: '' };
  },
  setWalletProvider: (web3authProvider: SafeEventEmitterProvider) => {},
});

export function useWeb3Auth(): IWeb3AuthContext {
  return useContext(Web3AuthContext);
}

interface IWeb3AuthState {
  web3AuthNetwork: WEB3AUTH_NETWORK_TYPE;
  chain: CHAIN_CONFIG_TYPE;
  children?: React.ReactNode;
}
interface IWeb3AuthProps {
  children?: React.ReactNode | any;
  web3AuthNetwork: WEB3AUTH_NETWORK_TYPE;
  chain: CHAIN_CONFIG_TYPE;
}

export const Web3AuthProvider: FunctionComponent<IWeb3AuthState> = ({
  children,
  web3AuthNetwork,
  chain,
}: IWeb3AuthProps) => {
  const [web3Auth, setWeb3Auth] = useState<Web3AuthNoModal | null>(null);
  const [provider, setProvider] = useState<WalletProvider | null>(null);
  const [user, setUser] = useState<unknown | null>(null);
  const [firebaseUser, setFirebaseUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [wb3athProvider, setWb3athProvider] = useState<any>(null);
  const navigate = useNavigate();

  const baseUrl = process.env.REACT_APP_domain as string;
  // const baseUrl = 'http://localhost:8888';

  const setWalletProvider = useCallback(
    (web3authProvider: SafeEventEmitterProvider) => {
      setWb3athProvider(web3authProvider);
      const walletProvider = getWalletProvider(chain, web3authProvider);
      setTimeout(function () {
        setProvider(walletProvider);
      }, 500);
    },
    [chain],
  );

  useEffect(() => {
    const subscribeAuthEvents = (web3auth: Web3AuthNoModal): void => {
      // Can subscribe to all ADAPTER_EVENTS and LOGIN_MODAL_EVENTS
      web3auth.on(ADAPTER_EVENTS.CONNECTED, async (data: unknown) => {
        console.log('Web3Auth connected');
        setUser(data);
        setWalletProvider(web3auth.provider as SafeEventEmitterProvider);

        // check to see where to redirect logged in user
        const redirectUrl = await UserServices.getUserLoginRedirect();
        if (redirectUrl !== null) {
          navigate(redirectUrl);
          return;
        }
        navigate('/');
      });

      web3auth.on(ADAPTER_EVENTS.CONNECTING, () => {
        console.log('Web3Auth connecting');
      });

      web3auth.on(ADAPTER_EVENTS.READY, () => {
        console.log('Web3Auth ready');
      });

      web3auth.on(ADAPTER_EVENTS.NOT_READY, () => {
        console.log('Web3Auth not ready');
      });

      web3auth.on(ADAPTER_EVENTS.ADAPTER_DATA_UPDATED, () => {
        console.log('Web3Auth data updated');
      });

      web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
        console.log('Web3Auth disconnected');
        setUser(null);
      });

      web3auth.on(ADAPTER_EVENTS.ERRORED, (error) => {
        console.error('some error or user has cancelled login request', error);
      });
    };

    // Handle user state changes
    const onFirebaseAuthStateChanged = (user: any): void => {
      setFirebaseUser(user);
    };

    async function init(): Promise<void> {
      try {
        setIsLoading(true);
        const clientId = process.env.REACT_APP_web3Auth_clientId as string;

        const currentChainConfig = chainC.Polygon;

        const web3AuthInstance = new Web3AuthNoModal({
          clientId,
          chainConfig: chainC.Polygon,
          web3AuthNetwork: process.env.REACT_APP_MODE === 'dev' ? OPENLOGIN_NETWORK.SAPPHIRE_DEVNET : OPENLOGIN_NETWORK.SAPPHIRE_MAINNET,
        });
        subscribeAuthEvents(web3AuthInstance);

        const metamaskAdapter = new MetamaskAdapter({
          clientId,
          sessionTime: 3600,
        });
        metamaskAdapter.setAdapterSettings({
          sessionTime: 86400,
        });

        // it will add/update  the metamask adapter in to web3auth class
        web3AuthInstance.configureAdapter(metamaskAdapter);

        const privateKeyProvider = new EthereumPrivateKeyProvider({
          config: { chainConfig: currentChainConfig },
        });

        const jwtOpenloginAdapter = new OpenloginAdapter({
          privateKeyProvider,
          adapterSettings: {
            // clientId,
            network: process.env.REACT_APP_MODE === 'dev' ? 'sapphire_devnet' : 'sapphire_mainnet',
            // network: 'testnet',
            uxMode: 'redirect',
            redirectUrl: baseUrl + '/dashboard',
            whiteLabel: {
              appName: 'Fenus',
              appUrl: 'https://fenus.io',
              // appUrl: baseUrl,
              logoLight: baseUrl + '/icon-192x192.png',
              logoDark: baseUrl + '/icon-192x192.png',
              mode: 'dark',
              defaultLanguage: 'en',
              theme: { primary: '#71FECF' },
            },
            loginConfig: {
              jwt: {
                verifier: 'jwt-firebase',
                typeOfLogin: 'jwt',
                clientId,
              },
            },
          },
        });
        web3AuthInstance.configureAdapter(jwtOpenloginAdapter);

        await web3AuthInstance.init();

        setWeb3Auth(web3AuthInstance);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
      onAuthStateChanged(auth, onFirebaseAuthStateChanged);
    }
    init().catch(() => {});
  }, [chain, web3AuthNetwork, setWalletProvider, baseUrl]);
  // }, [chain, web3AuthNetwork, setWalletProvider, url]);

  const loginJWT = async (idToken: string): Promise<void> => {
    try {
      if (web3Auth == null) {
        return;
      }

      setIsLoading(true);

      console.log('web3Auth.connected:', web3Auth.connected);
      if (!web3Auth.connected) {
        const web3authProvider = await web3Auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
          mfaLevel: 'mandatory', // Pass on the mfa level of your choice: default, optional, mandatory, none
          loginProvider: 'jwt',
          extraLoginOptions: {
            id_token: idToken,
            verifierIdField: 'sub',
            domain: baseUrl,
            // domain: url,
          },
        });
        setWalletProvider(web3authProvider as SafeEventEmitterProvider);
      } else {
        navigate('/dashboard');
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const login = async (
    adapter: WALLET_ADAPTER_TYPE,
    loginProvider: LOGIN_PROVIDER_TYPE,
    redirectUrl?: string,
    loginHint?: string,
  ): Promise<void> => {
    try {
      if (web3Auth == null) {
        console.log('web3auth not initialized yet');
        return;
      }

      const localProvider = await web3Auth.connectTo(adapter, {
        mfaLevel: 'mandatory', // Pass on the mfa level of your choice: default, optional, mandatory, none
        loginProvider, // Pass on the login provider of your choice: google, facebook, discord, twitch, twitter, github, linkedin, apple, etc.
        ...(redirectUrl != null && { banned: true }),
        ...(loginHint != null && {
          extraLoginOptions: {
            loginHint,
          },
        }),
      });
      setWalletProvider(localProvider as SafeEventEmitterProvider);
    } catch (error) {
      console.log('error', error);
    } finally {
      /* empty */
    }
  };

  const logout = async (): Promise<void> => {
    if (web3Auth == null) {
      console.log('web3auth not initialized yet');
      return;
    }
    setProvider(null);
    await web3Auth.logout();
  };

  const getUserInfo = async (): Promise<any> => {
    if (web3Auth == null) {
      console.log('web3auth not initialized yet');
      return;
    }
    return await web3Auth.getUserInfo();
  };

  const getAccounts = async (): Promise<void> => {
    if (provider == null) {
      console.log('provider not initialized yet');
      return;
    }
    return await provider.getAccounts();
  };

  const getAddress: any = async (): Promise<string> => {
    if (provider === null) {
      console.log('provider not initialized yet');
      return '';
    }
    return await provider.getAddress();
  };

  const getBalance = async (): Promise<any> => {
    if (provider == null) {
      console.log('provider not initialized yet');
      return;
    }
    return await provider.getBalance();
  };

  const signMessage = async (message: string): Promise<void> => {
    if (provider == null) {
      console.log('provider not initialized yet');
      return;
    }
    await provider.signMessage(message);
  };

  const signTransaction = async (
    transaction: ethers.providers.TransactionRequest,
  ): Promise<string> => {
    if (provider == null) {
      console.log('provider not initialized yet');
      return '';
    }
    return await provider.signTransaction(transaction);
  };

  const signAndSendTransaction = async (
    transaction: ethers.providers.TransactionRequest,
  ): Promise<TransactionResponse> => {
    if (provider == null) {
      console.log('provider not initialized yet');
      return {
        status: TransactionState.Failed,
        transactionHash: '',
      };
    }
    return await provider.signAndSendTransaction(transaction);
  };

  const contextProvider = {
    web3Auth,
    chain,
    provider,
    firebaseUser,
    setFirebaseUser,
    user,
    isLoading,
    setIsLoading,
    loginJWT,
    login,
    logout,
    getUserInfo,
    getAccounts,
    getAddress,
    getBalance,
    signMessage,
    signTransaction,
    signAndSendTransaction,
    setWalletProvider,
    wb3athProvider,
  };
  return <Web3AuthContext.Provider value={contextProvider}>{children}</Web3AuthContext.Provider>;
};
