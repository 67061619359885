import type { CartItem, Investment, WithdrawalData, TokenState, PortfolioPerformance } from 'types';
import { createContext } from 'react';

export interface IPortfolio {
  numberOfInvestments: number;
  totalInvestedValue: number;
  totalCurrentValue: number;
  performance: number;
  performancePercentage: number;
  averageRiskProfile: number;
  investments: Investment[];
}

export interface IDashboardContext {
  performances: Record<string, PortfolioPerformance>;
  setPerformances: (performances: Record<string, PortfolioPerformance>) => void;
  totalPerformance: PortfolioPerformance;
  setTotalPerformance: (totalPerformance: PortfolioPerformance) => void;
  shoppingCartItems: CartItem[];
  setShoppingCartItems: (shoppingCartItems: CartItem[]) => void;
  withdrawInvestment: WithdrawalData;
  setWithdrawInvestment: (investment: WithdrawalData) => void;
  showShoppingCart: boolean;
  setShowShoppingCart: (show: boolean) => void;
  showQuestionMarkPopup: boolean;
  setShowQuestionMarkPopup: (show: boolean) => void;
  showProfilePopup: boolean;
  setShowProfilePopup: (show: boolean) => void;
  showSelectProfilePopup: boolean;
  setShowSelectProfilePopup: (show: boolean) => void;
  showFundWalletPopup: boolean;
  setShowFundWalletPopup: (show: boolean) => void;
  showMoneriumNotAuthedPopup: boolean;
  setShowMoneriumNotAuthedPopup: (show: boolean) => void;
  showRetrieveFromWalletPopup: boolean;
  setShowRetrieveFromWalletPopup: (show: boolean) => void;
  showSelectLanguage: boolean;
  setShowSelectLanguage: (show: boolean) => void;
  showCheckout: boolean;
  setShowCheckout: (show: boolean) => void;
  showWithdraw: boolean;
  setShowWithdraw: (show: boolean) => void;
  expandShoppingCart: boolean;
  setExpandShoppingCart: (show: boolean) => void;
  portfolio: IPortfolio;
  setPortfolio: (portfolio: IPortfolio) => void;
  addToPortfolio: (shoppingCartItems: CartItem[]) => void;
  tokenState: TokenState;
  setTokenState: (tokenState: TokenState) => void;
  balance: number;
  setBalance: (balance: number) => void;
  account: string;
  setAccount: (account: string) => void;
  showGuidedTour: boolean;
  setShowGuidedTour: (show: boolean) => void;
  tourSteps: any[];
  setTourSteps: (steps: any[]) => void;
  showLogout: boolean;
  setShowLogout: (show: boolean) => void;
  startedGuidedTour: boolean;
  setStartedGuidedTour: (show: boolean) => void;
  selectedProfile: number;
  setSelectedProfile: (selectedProfile: number) => void;
  showShareFeedbackPopup: boolean;
  setShowShareFeedbackPopup: (show: boolean) => void;
  showCurrencySwitchPopup: boolean;
  showShareFeedbackForm: boolean;
  setShowShareFeedbackForm: (show: boolean) => void;
  setShowCurrencySwitchPopup: (show: boolean) => void;
  currency: number;
  setCurrency: (currency: number) => void;
  displayBalance: number;
  setDisplayBalance: (displayBalance: number) => void;
  displayInvestedValue: number;
  setDisplayInvestedValue: (displayInvestedValue: number) => void;
  displayPerformance: number;
  setDisplayPerformance: (displayPerformance: number) => void;
  showSuccessTransaction: boolean;
  setShowSuccessTransaction: (show: boolean) => void;
  currencyInfo: { symbol: string; rate: number };
  setCurrecyInfo: ({ symbol, rate }: any) => void;
  available: number;
  setAvailable: (available: number) => void;
  availableBN: number;
  setAvailableBN: (available: number) => void;
  txFees: number;
  setTxFees: (fees: number) => void;
  txRequests: any[];
  setTxRequests: (requests: any[]) => void;
  failedInvestments: Array<{ bundle: any; coins: any[] }>;
  setFailedInvestments: (failedInvestments: Array<{ bundle: any; coins: any[] }>) => void;
  failedWithdrawals: Array<{ bundle: any; coins: any[] }>;
  setFailedWithdrawals: (failedWithdrawals: Array<{ bundle: any; coins: any[] }>) => void;
}

export const DashboardContext = createContext<IDashboardContext>({
  performances: {},
  setPerformances: async (performances: Record<string, PortfolioPerformance>) => {},
  totalPerformance: {
    percentagePerformance: 'N/A',
    balancePerformance: 'N/A',
  },
  setTotalPerformance: async (totalPerformance: PortfolioPerformance) => {},
  shoppingCartItems: [],
  setShoppingCartItems: async (shoppingCartItems: CartItem[]) => {},
  withdrawInvestment: {
    investment: undefined,
    withdrawAmount: 0,
  },
  setWithdrawInvestment: async (investment: WithdrawalData) => {},
  showShoppingCart: false,
  setShowShoppingCart: async (show: boolean) => {},
  showQuestionMarkPopup: false,
  setShowQuestionMarkPopup: (show: boolean) => {},
  showProfilePopup: false,
  setShowProfilePopup: (show: boolean) => {},
  showSelectProfilePopup: false,
  setShowSelectProfilePopup: (show: boolean) => {},
  showFundWalletPopup: false,
  setShowFundWalletPopup: (show: boolean) => {},
  showMoneriumNotAuthedPopup: false,
  setShowMoneriumNotAuthedPopup: (show: boolean) => {},
  showRetrieveFromWalletPopup: false,
  setShowRetrieveFromWalletPopup: (show: boolean) => {},
  showCurrencySwitchPopup: false,
  setShowCurrencySwitchPopup: (show: boolean) => {},
  showSelectLanguage: false,
  setShowSelectLanguage: (show: boolean) => {},
  showCheckout: false,
  setShowCheckout: async (show: boolean) => {},
  showWithdraw: false,
  setShowWithdraw: async (show: boolean) => {},
  expandShoppingCart: false,
  setExpandShoppingCart: (show: boolean) => {},
  portfolio: {
    numberOfInvestments: 0,
    totalInvestedValue: 0,
    totalCurrentValue: 0,
    performance: 0,
    performancePercentage: 0,
    averageRiskProfile: 0,
    investments: [],
  },
  setPortfolio: (portfolio: IPortfolio) => {},
  addToPortfolio: async (shoppingCartItems: CartItem[]) => {},

  tokenState: {},
  setTokenState: (tokenState: TokenState) => {},
  balance: 0,
  setBalance: (balance: number) => {},
  account: '',
  setAccount: (account: string) => {},
  showGuidedTour: false,
  setShowGuidedTour: (show: boolean) => {},
  tourSteps: [],
  setTourSteps: (steps: any[]) => {},
  showLogout: false,
  setShowLogout: (show: boolean) => {},
  startedGuidedTour: false,
  setStartedGuidedTour: (show: boolean) => {},
  selectedProfile: 0,
  setSelectedProfile: (selectedProfile: number) => {},
  showShareFeedbackPopup: false,
  setShowShareFeedbackPopup: (show: boolean) => {},
  showShareFeedbackForm: false,
  setShowShareFeedbackForm: (show: boolean) => {},
  currency: 0,
  setCurrency: (currency: number) => {},
  displayBalance: 0,
  setDisplayBalance(displayBalance: number) {},
  displayInvestedValue: 0,
  setDisplayInvestedValue: (displayBalance: number) => {},
  displayPerformance: 0,
  setDisplayPerformance: (displayPerformance: number) => {},
  showSuccessTransaction: false,
  setShowSuccessTransaction: (show: boolean) => {},
  currencyInfo: { symbol: '€', rate: 1 },
  setCurrecyInfo: ({ symbol, rate }) => {},
  available: 0,
  setAvailable: (available: number) => {},
  availableBN: 0,
  setAvailableBN: (available: number) => {},
  txFees: 0,
  setTxFees: (fees: number) => {},
  txRequests: [],
  setTxRequests: (requests: any[]) => {},
  failedInvestments: [],
  setFailedInvestments: (failedInvestments: Array<{ bundle: any; coins: any[] }>) => {},
  failedWithdrawals: [],
  setFailedWithdrawals: (failedInvestments: Array<{ bundle: any; coins: any[] }>) => {},
});
