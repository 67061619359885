import React, { useContext, useEffect, useState } from 'react';
import { type IDashboardContext, DashboardContext } from './DashboardContext';
// import { formatValue } from 'react-currency-input-field';
import { useTranslation } from 'react-i18next';

import './PortfolioOverview.scss';

import investmentIcon from 'assets/images/investment-icon.svg';
import averageRiskProfileIcon from 'assets/images/average-risk-profle-icon.svg';
import totalInvestValueIcon from 'assets/images/total-value-icon.svg';
import performanceIcon from 'assets/images/performance-icon.svg';
import { type Investment } from 'types/investment';
import { formatCurrencyPerformance } from '../../utils/formatCurrencyPerformance';
import { useGetPortfolioStatsOverview } from '../../hooks/useGetPortfolioStatsOverview';
import LoadingSpinner from '../LoadingSpinner';

interface Props {
  showHeader: boolean;
}

export const PortfolioOverview = (props: Props): JSX.Element => {
  const { t } = useTranslation('dashboard');

  const dashboardContext = useContext<IDashboardContext>(DashboardContext);
  const [avgRiskProfile, setAvgRiskProfile] = useState('');
  const [activeInvestments, setActiveInvestments] = useState(0);
  useEffect(() => {
    setActiveInvestments(
      dashboardContext.portfolio.investments.filter(
        (investment: Investment) => Math.round(investment.currentInvestmentValue * 10) / 10 !== 0,
      ).length,
    );
  }, [dashboardContext.portfolio.investments]);

  useGetPortfolioStatsOverview();

  useEffect(() => {
    if (dashboardContext.portfolio.averageRiskProfile > 66) {
      setAvgRiskProfile('Adventurous');
    } else if (dashboardContext.portfolio.averageRiskProfile > 33) {
      setAvgRiskProfile('Balanced');
    } else {
      setAvgRiskProfile('Careful');
    }
  }, [dashboardContext.portfolio.averageRiskProfile]);

  const riskProfile = (averageRiskProfile: number): JSX.Element => {
    return (
      <div className="risk-profile-group">
        {averageRiskProfile > 0 ? (
          <div className="greenprogress"></div>
        ) : (
          <div className="greyprogess"></div>
        )}
        {averageRiskProfile > 33 ? (
          <div className="greenprogress"></div>
        ) : (
          <div className="greyprogess"></div>
        )}
        {averageRiskProfile > 66 ? (
          <div className="greenprogress"></div>
        ) : (
          <div className="greyprogess"></div>
        )}
      </div>
    );
  };

  const isPositive = parseFloat(dashboardContext.totalPerformance.percentagePerformance) >= 0;

  return (
    <div className="portfolio-overview p-0">
      {props.showHeader && <h3>{t('overview.overview')}</h3>}
      <div className="portfolio-overview-content d-flex">
        <div className="content-column flex-fill d-flex">
          <div className="portfolio-icon d-none d-md-flex align-items-center justify-content-center">
            <img src={investmentIcon} alt="Investment" />
          </div>
          <div>
            <div className="title">{t('overview.investments')}</div>
            <div className="value">
              {/* {dashboardContext.portfolio.numberOfInvestments !== 0 */}
              {activeInvestments !== 0
                ? // ? dashboardContext.portfolio.numberOfInvestments
                  activeInvestments
                : '-'}
            </div>
          </div>
        </div>
        <div className="content-column flex-fill d-none d-md-flex">
          <div className="portfolio-icon d-none d-md-flex align-items-center justify-content-center">
            <img src={totalInvestValueIcon} alt="Total invested value" />
          </div>
          <div>
            <div className="title">{t('overview.value')}</div>
            <div className="value">
              {/* {getCurrencySymbol(dashboardContext.currency)}
              {formatValue({
                // value: dashboardContext.displayInvestedValue.toString(),
                value: dashboardContext.portfolio.totalCurrentValue.toString(),
                decimalScale: 2,
              })} */}
              {dashboardContext.currencyInfo.symbol}
              {(
                dashboardContext.portfolio.totalCurrentValue * dashboardContext.currencyInfo.rate
              ).toFixed(2)}
            </div>

            <div></div>
          </div>
        </div>
        <div className="content-column flex-fill d-flex">
          <div className="portfolio-icon d-none d-md-flex align-items-center justify-content-center">
            <img src={performanceIcon} alt="Performance" />
          </div>
          <div>
            <div className="title">{t('overview.performance')}</div>

            <div className="value">
              {dashboardContext.totalPerformance.percentagePerformance !== 'N/A' ? (
                <>
                  {formatCurrencyPerformance(
                    dashboardContext.currencyInfo.symbol,
                    parseFloat(dashboardContext.totalPerformance.balancePerformance) *
                      dashboardContext.currencyInfo.rate,
                  )}
                  {dashboardContext.totalPerformance.percentagePerformance !== 'N/A' && (
                    <div className={`percent float-end ${isPositive ? 'positive' : 'negative'}`}>
                      {dashboardContext.totalPerformance.percentagePerformance}%
                    </div>
                  )}
                </>
              ) : (
                <div>
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="content-column flex-fill d-flex">
          <div className="portfolio-icon d-none d-md-flex align-items-center justify-content-center">
            <img src={averageRiskProfileIcon} alt="Average Rick Profile" />
          </div>
          <div>
            <div className="title">{t('overview.risk')}</div>
            <div className="d-flex">
              <div className="flex-grow-1">
                {riskProfile(dashboardContext.portfolio.averageRiskProfile)}
              </div>
              <div className="d-none d-md-flex">
                <div className="average-risk-profile-label">{avgRiskProfile}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
