import { Loading } from 'components/Layout/Loading';
import { useMonerium } from 'context/moneriumContext';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const MoneriumIntegration: any = () => {
  const { completeAuth } = useMonerium();
  const navigate = useNavigate();

  const complete: any = async (code: string, retrievedCodeVerifier: string) => {
    await completeAuth(code, retrievedCodeVerifier);
    navigate('/dashboard');
  };

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get('code');
    if (code !== null) {
      window.localStorage.setItem('moneriumCode', code);

      const retrievedCodeVerifier = window.localStorage.getItem('myCodeVerifier');
      complete(code, retrievedCodeVerifier);
      // completeAuth(code, retrievedCodeVerifier);
    }
  }, []);

  return (
    <div>
      <Loading />
    </div>
  );
};
